import { useEffect, useState } from 'react'
import { Container, Table, Row, Col, Form, Button } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import Detail from './os-detail'
import axios from 'axios'
import url from '../url'
import 'react-datepicker/dist/react-datepicker.css'

const App = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user
	const [data, setData] = useState([])
	const [dataProvince, setDataprovince] = useState([])
	const [dataSales, setDatasales] = useState([])
	const [show, setShow] = useState(false)

	const [os, setOS] = useState('')
	const [code, setCode] = useState('')
	const [customer, setCustomer] = useState('')
	const [province, setProvince] = useState('0')
	const [sales, setSales] = useState('0')
	const [status, setStatus] = useState('0')
	const [dateFrom, setDatefrom] = useState(new Date())
	const [dateTo, setDateto] = useState(new Date())

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		} else {
			getSales()
			getProvince()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getReport = () => {
		let monthf = dateFrom.getMonth() + 1
		let montht = dateTo.getMonth() + 1
		let dayf = dateFrom.getDate()
		let dayt = dateTo.getDate()
		if (monthf < 10) {
			monthf = '0' + monthf
		}
		if (montht < 10) {
			montht = '0' + montht
		}
		if (dayf < 10) {
			dayf = '0' + dayf
		}
		if (dayt < 10) {
			dayt = '0' + dayt
		}
		axios
			.get(url + '/report/os.php', {
				params: {
					code: code,
					customer: customer,
					status: status,
					sales: sales,
					datefrom: dateFrom.getFullYear() + '-' + monthf + '-' + dayf,
					dateto: dateTo.getFullYear() + '-' + montht + '-' + dayt,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							num: row.id,
							so: row.code,
							date: row.date,
							customer: row.customer,
							shipment: row.shipment,
							sales: row.sales,
							status: row.status,
						}
					})
				}
				setData(result)
			})
			.catch(function (error) {})
	}

	const getProvince = () => {
		axios
			.get(url + '/province.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataprovince(result)
			})
			.catch(function (error) {})
	}

	const getSales = (province) => {
		axios
			.get(url + '/city.php', {
				params: {
					province: province,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatasales(result)
			})
			.catch(function (error) {})
	}

	const detailOS = (id) => {
		setShow(true)
		setOS(id)
	}

	const handleClose = () => {
		setShow(false)
	}

	const selectProvince = (id) => {
		setProvince(id)
		getSales(id)
	}

	return (
		<>
			<h3 className='text-center my-3'>REPORT OUTSTANDING SALES</h3>
			<Container
				className='border rounded border-3 p-3'
				style={{ height: 'auto' }}
			>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>CODE</Col>:
					<Col>
						<Form.Control
							placeholder='EX.CS-2023031'
							value={code}
							onChange={(e) => setCode(e.target.value)}
						/>
					</Col>
					<Col>CUSTOMER</Col>:
					<Col>
						<Form.Control
							placeholder='EX.PT.JAYA'
							value={customer}
							onChange={(e) => setCustomer(e.target.value)}
						/>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>PROVINCE</Col>:
					<Col>
						<Form.Select
							value={province}
							onChange={(e) => selectProvince(e.target.value)}
						>
							<option value={'0'}>SELECT PROVINCE</option>
							{dataProvince.map !== undefined &&
								dataProvince.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col>CITY</Col>:
					<Col>
						<Form.Select
							value={sales}
							onChange={(e) => setSales(e.target.value)}
						>
							<option value={'0'}>SELECT CITY</option>
							{dataSales.map !== undefined &&
								dataSales.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>DATE FROM</Col>:
					<Col>
						{/* <Form.Control placeholder="2023-03-10" value={dateFrom} onChange={(e) => setDatefrom(e.target.value)} required /> */}
						<ReactDatePicker
							selected={dateFrom}
							onChange={(e) => setDatefrom(e)}
						/>
					</Col>
					<Col>DATE TO</Col>:
					<Col>
						{/* <Form.Control placeholder="2023-03-10" value={dateTo} onChange={(e) => setDateto(e.target.value)} required /> */}
						<ReactDatePicker selected={dateTo} onChange={(e) => setDateto(e)} />
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>STATUS</Col>:
					<Col>
						<Form.Select
							value={status}
							onChange={(e) => setStatus(e.target.value)}
						>
							<option value={'0'}>OPEN</option>
							<option value={'1'}>CLOSED</option>
						</Form.Select>
					</Col>
					<Col></Col>
					<Col></Col>
				</Row>
				<div className='d-flex justify-content-end'>
					<Button variant='danger' onClick={() => getReport()}>
						SEARCH
					</Button>
				</div>
			</Container>
			<Table className='table-hover table-striped'>
				<thead className='border-bottom border-3'>
					<tr>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							#
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							SO
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							DATE
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							CUSTOMER
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							SHIPMENT
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							SALES
						</th>
						<th
							style={{ flex: 1, alignContent: 'center', textAlign: 'center' }}
						>
							STATUS
						</th>
					</tr>
				</thead>
				<tbody>
					{data.map !== undefined &&
						data.map((row, i) => (
							<tr key={i} onClick={() => detailOS(row.id)}>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									{i + 1}
								</td>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									{row.so}
								</td>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									{row.date}
								</td>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
									}}
								>
									{row.customer}
								</td>
								<td style={{ flex: 1, alignContent: 'center' }}>
									{row.shipment}
								</td>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									{row.sales}
								</td>
								<td
									style={{
										flex: 1,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									{row.status === '0' ? 'OPEN' : 'CLOSED'}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
			{data.map === undefined && (
				<div className='d-flex justify-content-center'>NO DATA</div>
			)}
			{show === true && (
				<Detail
					show={show}
					onHide={() => setShow(false)}
					close={handleClose}
					data={{ id: os }}
				/>
			)}
		</>
	)
}

export default App
