import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";

export default function Tables(props) {
  // PICKLIST
  const datado = props.data.datado;
  const delivery = props.data.do;
  // MENU
  const menu = props.data.menu;
  // HANDLE SELECT
  const handleSelect = (delivery) => {
    props.set("do", delivery);
  };
  // TABLE COLUMN
  const columns = [
    { id: 1, name: "CODE", font: "bold" },
    { id: 2, name: "CUSTOMER", font: "bold" },
    { id: 3, name: "CITY", font: "bold" },
    { id: 4, name: "PRICE LIST", font: "bold" },
    { id: 5, name: "STATUS", font: "bold" },
  ];
  // TABLE PAGE
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // HANDLE PAGE
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    menu === 1 &&
    delivery === null && (
      <Box padding={2}>
        <Paper elevation={2} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ fontWeight: column.font }}
                      align="center"
                    >
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {datado.length > 0 &&
                  datado
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell
                          onClick={() => handleSelect(row)}
                          align="center"
                        >
                          {row.code}
                        </TableCell>
                        <TableCell
                          onClick={() => handleSelect(row)}
                          align="center"
                        >
                          {row.customer}
                        </TableCell>
                        <TableCell
                          onClick={() => handleSelect(row)}
                          align="center"
                        >
                          {row.city}
                        </TableCell>
                        <TableCell
                          onClick={() => handleSelect(row)}
                          align="center"
                        >
                          {row.pricelist}
                        </TableCell>
                        <TableCell
                          onClick={() => handleSelect(row)}
                          align="center"
                        >
                          {row.status === "0" && "OPEN"}
                          {row.status === "1" && "COMPLETE"}
                          {row.status === "2" && "CANCEL"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={datado.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    )
  );
}
