import {
	Box,
	Button,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import url from '../url'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { useLocation, useNavigate } from 'react-router-dom'

export default function SalesActivity() {
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user
	const [tableColumn, setTableColumn] = useState([
		{ id: 1, label: 'customer.code', name: 'Customer Code', order: false },
		{ id: 2, label: 'customer.name', name: 'Customer Name', order: false },
		{
			id: 3,
			label: 'customer.address',
			name: 'Customer Address',
			order: false,
		},
		{ id: 4, label: 'user.name', name: 'Scan By', order: false },
		{
			id: 5,
			label: 'scan_qr_customer_log.created_date',
			name: 'Scan Date',
			order: false,
		},
	])
	const [tableRow, setTableRow] = useState([])
	const [filterSearch, setFilterSearch] = useState({
		customerName: '',
		scanBy: '',
	})

	function onSearch(columnId) {
		axios
			.get(`${url}/report/salesActivity.php`, {
				params: {
					customerName: filterSearch.customerName,
					scanBy: filterSearch.scanBy,
					orderName: tableColumn[columnId - 1].label,
					orderValue: tableColumn[columnId - 1].order ? 'DESC' : 'ASC',
				},
			})
			.then((response) => {
				setTableRow(response.data)
			})
			.catch((error) => {})
	}

	function onOrder(columnId) {
		let result = tableColumn.map((column) => {
			if (column.id === columnId) {
				return {
					...column,
					order: !column.order,
				}
			} else {
				return {
					...column,
					order: false,
				}
			}
		})

		onSearch(columnId)
		setTableColumn(result)
	}

	useEffect(() => {
		console.log(user)
		if (user === undefined) {
			navigate('/')
		}
		// eslint-disable-next-line
	}, [])

	// *Render
	return (
		<Box display={'flex'} flexDirection={'column'} flex={1} padding={2} gap={2}>
			<Typography variant='h4' fontWeight={'bold'} textAlign={'center'}>
				Report Sales Activity
			</Typography>

			<Divider sx={{ borderWidth: 2, borderColor: '#ff0000' }} />

			<Box
				display={'flex'}
				flex={1}
				flexDirection={'row'}
				gap={2}
				alignItems={'center'}
			>
				<Typography flex={1} textAlign={'center'}>
					Customer Name
				</Typography>

				<Typography flex={0.5} textAlign={'center'}>
					:
				</Typography>

				<TextField
					variant='outlined'
					color='error'
					sx={{ flex: 1 }}
					value={filterSearch.customerName}
					onChange={(e) =>
						setFilterSearch({ ...filterSearch, customerName: e.target.value })
					}
				/>

				<Typography flex={1} textAlign={'center'}>
					Scan By
				</Typography>

				<Typography flex={0.5} textAlign={'center'}>
					:
				</Typography>

				<TextField
					variant='outlined'
					color='error'
					sx={{ flex: 1 }}
					value={filterSearch.scanBy}
					onChange={(e) =>
						setFilterSearch({ ...filterSearch, scanBy: e.target.value })
					}
				/>
			</Box>

			<Box display={'flex'} justifyContent={'flex-end'}>
				<Button variant='contained' color='error' onClick={() => onSearch(5)}>
					Search
				</Button>
			</Box>

			<Divider sx={{ borderWidth: 2, borderColor: '#ff0000' }} />

			<Box>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{tableColumn.length > 0 &&
									tableColumn.map((column) => (
										<TableCell
											key={column.id}
											onClick={() => {
												onOrder(column.id)
											}}
										>
											<Box
												display={'flex'}
												flexDirection={'row'}
												justifyContent={'center'}
												alignItems={'center'}
												gap={1}
											>
												<Typography fontWeight={'bold'}>
													{column.name}
												</Typography>
												{column.order === true ? (
													<ArrowCircleUpIcon />
												) : (
													<ArrowCircleDownIcon color='disabled' />
												)}
											</Box>
										</TableCell>
									))}
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRow.length > 0 &&
								tableRow.map((row) => (
									<TableRow key={row.id}>
										<TableCell sx={{ textAlign: 'center' }}>
											{row.customer_code}
										</TableCell>
										<TableCell>{row.customer_name}</TableCell>
										<TableCell>{row.customer_address}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{row.user_name}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{row.created_date}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	)
}
