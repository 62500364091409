import { useEffect, useState } from 'react'
import { Modal, Row, Col, Table, Button } from 'react-bootstrap'
import axios from 'axios'
import url from '../url'

const App = (props) => {
	const os = props.data.id
	const [dataOS, setDataos] = useState([])
	const [dataTotal, setDatatotal] = useState()

	useEffect(() => {
		getOS()
		getTotal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getTotal = () => {
		axios
			.get(url + '/os-total.php', {
				params: {
					so: os,
				},
			})
			.then(function (response) {
				let result = response.data
				setDatatotal(result)
			})
			.catch(function (error) {})
	}

	const getOS = () => {
		axios
			.get(url + '/os-detail.php', {
				params: {
					so: os,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							code: row.code,
							cust: row.cust,
							shipment: row.shipment,
							sales: row.sales,
							status: row.status,
							date: row.date,
							detail: row.detail_id,
							item: row.item,
							qty: row.qty,
							vol: row.vol,
						}
					})
				}
				setDataos(result)
			})
			.catch(function (error) {})
	}

	return (
		<Modal
			{...props}
			aria-labelledby='contained-modal-title-vcenter'
			fullscreen
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					OUTSTANDING SALES DETAIL
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{dataOS.length > 0 && (
					<>
						<Row className='mb-2 justify-content-center align-items-center'>
							<Col>CODE</Col>:<Col>{dataOS[0].code}</Col>
							<Col>DATE</Col>:<Col>{dataOS[0].date}</Col>
						</Row>
						<Row className='mb-2 justify-content-center align-items-center'>
							<Col>CUSTOMER</Col>:<Col>{dataOS[0].cust}</Col>
							<Col>SHIPMENT</Col>:<Col>{dataOS[0].shipment}</Col>
						</Row>
						<Row className='mb-2 justify-content-center align-items-center'>
							<Col>STATUS</Col>:
							<Col>{dataOS[0].status === '0' ? 'OPEN' : 'CLOSED'}</Col>
							<Col>SALES</Col>:<Col>{dataOS[0].sales}</Col>
						</Row>
						<Table className='mt-5'>
							<thead className='border-bottom border-3'>
								<tr>
									<th>#</th>
									<th>ITEM</th>
									<th>QTY</th>
									<th>VOLUME</th>
								</tr>
							</thead>
							<tbody>
								{dataOS.map((row, i) => (
									<tr key={row.detail}>
										<td>{i + 1}</td>
										<td>{row.item}</td>
										<td>{row.qty}</td>
										<td>{row.vol}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td>
										<b>TOTAL</b>
									</td>
									<td>
										<b>{dataTotal !== undefined && dataTotal.qty}</b>
									</td>
									<td>
										<b>{dataTotal !== undefined && dataTotal.vol}</b>
									</td>
								</tr>
							</tbody>
						</Table>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant='danger' onClick={props.close}>
					CLOSE
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default App
