import { Tabs, Tab } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import Search from './search'
import Create from './create'
import { useEffect, useState } from 'react'

const App = () => {
	const location = useLocation()
	const user = location.state?.user
	const navigate = useNavigate()
	const [tab, setTab] = useState('search')

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<h3 className='text-center my-3'>MASTER CUSTOMER</h3>
			<Tabs activeKey={tab} onSelect={(e) => setTab(e)} className='mb-3'>
				<Tab eventKey='search' title='SEARCH'>
					<Search data={{ user: user, tabMenu: tab }} />
				</Tab>
				<Tab eventKey='create' title='CREATE'>
					<Create data={{ user: user }} />
				</Tab>
			</Tabs>
		</>
	)
}

export default App
