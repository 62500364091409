import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import dayjs from "dayjs";

export default function Filter(props) {
  // USER
  const user = props.data.user;
  // MENU
  const menu = props.data.menu;
  // LOADING
  const [loading, setLoading] = useState(true);
  // REFRESH
  const refresh = props.data.refresh;
  // STATUS
  const dataStatus = [
    { id: 1, name: "OPEN", value: "0" },
    { id: 2, name: "COMPLETE", value: "1" },
    { id: 3, name: "CANCEL", value: "2" },
  ];
  const [status, setStatus] = useState({ id: 1, name: "OPEN", value: "0" });
  // DATE
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let result = `${year}-${month}-${day}`;
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState(dayjs(result));
  // SO CODE
  const [soCode, setSOCode] = useState("");
  // CUSTOMER
  const [customer, setCustomer] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);
  const getCustomer = () => {
    axios
      .get(url + "/sales-order/customer.php", {
        params: {
          user: user.id,
        },
      })
      .then(function (response) {
        let result = response.data;
        setDataCustomer(result);
      })
      .catch(function (error) {});
  };
  const handleCustomer = (customer) => {
    setCustomer(customer);
    if (customer) {
      setCustomerName(customer.name);
      searchSO(soCode, customer.name, status.value, dateFrom, dateTo, user.id);
    } else {
      searchSO(soCode, "", status.value, dateFrom, dateTo, user.id);
    }
  };
  // HANDLE DATE
  const handleDate = (type, date) => {
    let result;
    if (date) {
      result = date.format("YYYY-MM-DD");
    } else {
      result = "";
    }
    if (type === "datefrom") {
      setDateFrom(result);
      searchSO(soCode, customerName, status.value, result, dateTo, user.id);
    }
    if (type === "dateto") {
      setDateTo(result);
      searchSO(soCode, customerName, status.value, dateFrom, result, user.id);
    }
  };
  // HANDLE CODE
  const handleCode = (code) => {
    setSOCode(code);
    searchSO(code, customerName, status.value, dateFrom, dateTo, user.id);
  };
  // HANDLE STATUS
  const handleStatus = (status) => {
    if (status !== null) {
      setStatus(status);
      searchSO(soCode, customerName, status.value, dateFrom, dateTo, user.id);
    }
  };
  // SEARCH
  const searchSO = (code, customer, status, datefrom, dateto, user) => {
    axios
      .get(url + "/sales-order/search.php", {
        params: {
          code: code,
          customer: customer,
          status: status,
          datefrom: datefrom,
          dateto: dateto,
          user: user,
        },
      })
      .then(function (response) {
        let result = response.data;
        setTimeout(() => {
          props.set("filter", result);
        }, 1000);
      })
      .catch(function (error) {});
  };
  // FILTER
  const filter = [
    {
      id: 1,
      name: "CODE",
      value: (
        <TextField
          fullWidth
          variant="standard"
          value={soCode}
          onChange={(value) => handleCode(value.target.value)}
        />
      ),
    },
    {
      id: 2,
      name: "CUSTOMER",
      value: (
        <Autocomplete
          getOptionLabel={(dataCustomer) => dataCustomer.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={customer}
          options={dataCustomer}
          onChange={(_, value) => handleCustomer(value)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      id: 3,
      name: "STATUS",
      value: (
        <Autocomplete
          getOptionLabel={(dataStatus) => dataStatus.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={status}
          options={dataStatus}
          onChange={(_, value) => handleStatus(value)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      id: 4,
      name: "DATE FROM",
      value: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateFrom}
            onChange={(value) => handleDate("datefrom", value)}
          />
        </LocalizationProvider>
      ),
    },
    {
      id: 5,
      name: "DATE TO",
      value: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateTo}
            onChange={(value) => handleDate("dateto", value)}
          />
        </LocalizationProvider>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (refresh) {
      getCustomer();
      searchSO(soCode, customerName, status.value, dateFrom, dateTo, user.id);
      setTimeout(() => {
        props.set("refresh");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Grid
      container
      padding={2}
      spacing={2}
      alignItems="center"
      borderBottom={2}
    >
      {menu === 1 &&
        filter.map((row) => (
          <Grid item xs={12} md={6} key={row.id}>
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Stack direction="row" alignItems="center">
                <Typography flex={1}>{row.name}</Typography>
                <Typography flex={1} textAlign="center">
                  :
                </Typography>
                <Box flex={1}>{row.value}</Box>
              </Stack>
            )}
          </Grid>
        ))}
    </Grid>
  );
}
