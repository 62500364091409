import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import url from '../url'

export default function CreateExpedition(props) {
	const [openConfirm, setOpenConfirm] = useState(false)
	const [snackbarData, setSnackbarData] = useState({
		open: false,
		type: 'Success',
		message: '',
	})
	const [dataExpeditionType, setDataExpeditionType] = useState([])
	const [expeditionForm, setExpeditionForm] = useState({
		code: '',
		type: null,
		volume: 0,
		name: '',
		plateNumber: '',
		driver: '',
		driverPhone: '',
	})

	function getExpeditionType() {
		axios
			.get(url + '/picklist/getExpeditionType.php')
			.then(function (response) {
				let result = response.data
				setDataExpeditionType(result)
			})
			.catch(function (error) {
				setSnackbarData({
					open: true,
					type: 'Error',
					message: error.message,
				})
			})
	}

	function generateExpeditionCode() {
		axios
			.get(url + '/picklist/getLastExpeditionCode.php')
			.then(function (response) {
				let result = response.data
				const today = new Date()
				const number = Number('000' + result.substring(9, 13).slice(-4))

				if (today.getMonth() + 1 === Number(result.substring(7, 9))) {
					console.log(
						`${String(today.getFullYear())}${(
							'0' +
							(today.getMonth() + 1)
						).slice(-2)}${('000' + number).slice(-4)}`
					)
				} else {
					console.log(
						`${String(today.getFullYear())}${('0' + today.getMonth() + 1).slice(
							-2
						)}0001`
					)
				}
			})
			.catch(function (error) {
				setSnackbarData({
					open: true,
					type: 'Error',
					message: error.message,
				})
			})
	}

	function onChangeExpeditionType(value) {
		setExpeditionForm({ ...expeditionForm, type: value, volume: value.volume })
	}

	useEffect(() => {
		getExpeditionType()
		generateExpeditionCode()
		setExpeditionForm({
			type: null,
			volume: 0,
			name: '',
			plateNumber: '',
			driver: '',
			driverPhone: '',
		})
	}, [props.open])

	return (
		<Box>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				fullWidth
				maxWidth='sm'
				scroll='paper'
			>
				<DialogTitle>Create Expedition</DialogTitle>
				<DialogContent>
					{/* //*Expedition Form */}
					<Box
						display={'flex'}
						flexDirection={'column'}
						gap={1}
						justifyContent={'center'}
					>
						<Typography>Expedition Type</Typography>
						<Autocomplete
							getOptionLabel={(dataExpeditionType) =>
								`${dataExpeditionType.code} - ${
									dataExpeditionType.name
								} (${Number(dataExpeditionType.volume).toFixed(3)} m³)`
							}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={expeditionForm.type}
							options={dataExpeditionType}
							onChange={(_, value) => onChangeExpeditionType(value)}
							renderInput={(params) => (
								<TextField {...params} variant='outlined' />
							)}
						/>
						<Typography>Expedition Volume</Typography>
						<TextField
							value={Number(expeditionForm.volume).toFixed(3) + ' m³'}
							disabled
						/>
						<Typography>Expedition Name</Typography>
						<TextField />
						<Typography>Plate Number</Typography>
						<TextField />
						<Typography>Driver</Typography>
						<TextField />
						<Typography>Driver Phone Number</Typography>
						<TextField type='number' />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						color='error'
						variant='contained'
						onClick={() => setOpenConfirm(true)}
					>
						Create
					</Button>
					<Button color='error' onClick={props.onClose}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{/* //*CONFIRM */}
			<Dialog
				open={openConfirm}
				onClose={() => setOpenConfirm(false)}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle>CONFIRMATION</DialogTitle>
				<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
					<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' color='error' onClick={() => {}}>
						YES
					</Button>
					<Button color='error' onClick={() => setOpenConfirm(false)}>
						NO
					</Button>
				</DialogActions>
			</Dialog>

			{/* //*Alert */}
			<Snackbar
				open={snackbarData.open}
				autoHideDuration={6000}
				onClose={() => setSnackbarData({ ...snackbarData, open: false })}
			>
				<Alert
					onClose={() => setSnackbarData({ ...snackbarData, open: false })}
					severity={snackbarData.type === 'Success' ? 'success' : 'error'}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</Box>
	)
}
