import { useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './dashboard'
import { useEffect } from 'react'

export default function Production() {
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		}
		// eslint-disable-next-line
	}, [])

	return <Dashboard />
}
