import url from '../url'
import axios from 'axios'
import Edit from './edit'
import Qrcode from './qrcode'
import { useEffect, useState } from 'react'
import { Row, Col, Form, Table, Button, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import PendingIcon from '@mui/icons-material/Pending'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Box, Tooltip } from '@mui/material'

const App = (props) => {
	const [dataPL, setDatapl] = useState([])
	const [dataProvince, setDataprovince] = useState([])
	const [dataCity, setDatacity] = useState([])
	const [dataCustomer, setDatacustomer] = useState([])
	const [show, setShow] = useState(false)
	const [show1, setShow1] = useState(false)
	const user = props.data.user

	const [code, setCode] = useState('')
	const [name, setName] = useState('')
	const [province, setProvince] = useState('0')
	const [city, setCity] = useState('0')
	const [pl, setPL] = useState('0')
	const [status, setStatus] = useState('0')
	const [confirm, setConfirm] = useState('0')
	const [customer, setCustomer] = useState('0')
	const confirmStatus = [
		{ id: '0', name: 'Pending' },
		{ id: '1', name: 'Approved' },
		{ id: '2', name: 'Rejected' },
	]

	useEffect(() => {
		getProvince()
		getPricelist()
		getCustomer()
	}, [props.data.tabMenu])

	const getPricelist = () => {
		axios
			.get(url + '/customer/getPricelist.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatapl(result)
			})
			.catch(function (error) {})
	}

	const getProvince = () => {
		axios
			.get(url + '/customer/getProvince.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataprovince(result)
			})
			.catch(function (error) {})
	}

	const getCity = (province) => {
		axios
			.get(url + '/customer/getCity.php', {
				params: {
					province: province,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatacity(result)
			})
			.catch(function (error) {})
	}

	const getCustomer = () => {
		axios
			.get(url + '/customer/search.php', {
				params: {
					user: user.id,
					code: code,
					name: name,
					pl: pl,
					province: province,
					city: city,
					status: status,
					confirmation: confirm,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							code: row.code,
							name: row.name,
							pl: row.pl,
							province: row.province,
							city: row.city,
							status: row.status,
							confirmation: row.confirmation,
						}
					})
				}
				setDatacustomer(result)
			})
			.catch(function (error) {})
	}

	const selectProvince = (id) => {
		setProvince(id)
		getCity(id)
	}

	const search = () => {
		getCustomer()
	}

	const detail = (customer) => {
		setCustomer(customer)
		setShow(true)
	}

	const qr = (cust) => {
		setCustomer(cust)
		setShow1(true)
	}

	return (
		<>
			<Container
				className='border rounded border-3 p-3'
				style={{ height: 'auto' }}
			>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>CODE</Col>:
					<Col>
						<Form.Control
							placeholder='EX.CS-2023031'
							onChange={(e) => setCode(e.target.value)}
						/>
					</Col>
					<Col>PRICE LIST</Col>:
					<Col>
						<Form.Select
							defaultValue={pl}
							onChange={(e) => setPL(e.target.value)}
						>
							<option value={'0'}>SELECT PL</option>
							{dataPL.map !== undefined &&
								dataPL.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>NAME</Col>:
					<Col>
						<Form.Control
							placeholder='EX.PT.JAYA'
							onChange={(e) => setName(e.target.value)}
						/>
					</Col>
					<Col>STATUS</Col>:
					<Col>
						<Form.Select
							defaultValue={status}
							onChange={(e) => setStatus(e.target.value)}
						>
							<option value={'1'}>ACTIVE</option>
							<option value={'0'}>INACTIVE</option>
						</Form.Select>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>PROVINCE</Col>:
					<Col>
						<Form.Select
							defaultValue={province}
							onChange={(e) => selectProvince(e.target.value)}
						>
							<option value={'0'}>SELECT PROVINCE</option>
							{dataProvince.map !== undefined &&
								dataProvince.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col>CITY</Col>:
					<Col>
						<Form.Select
							defaultValue={city}
							onChange={(e) => setCity(e.target.value)}
						>
							<option value={'0'}>SELECT CITY</option>
							{dataCity.map !== undefined &&
								dataCity.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>CONFIRMATION</Col>:
					<Col>
						<Form.Select
							defaultValue={confirm}
							onChange={(e) => setConfirm(e.target.value)}
						>
							{confirmStatus.map !== undefined &&
								confirmStatus.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col></Col>
					<Col></Col>
				</Row>
				<div className='d-flex justify-content-end'>
					<Button variant='danger' onClick={search}>
						SEARCH
					</Button>
				</div>
			</Container>
			<Table className='table-hover table-striped'>
				<thead className='border-bottom border-3'>
					<tr>
						<th>CODE</th>
						<th>CUSTOMER</th>
						<th>PRICE LIST</th>
						<th>PROVINCE</th>
						<th>CITY</th>
						<th style={{ textAlign: 'center' }}>STATUS</th>
						<th style={{ textAlign: 'center' }}>CONFIRMATION</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{dataCustomer.map !== undefined &&
						dataCustomer.map((row) => (
							<tr key={row.id}>
								<td>{row.code}</td>
								<td>{row.name}</td>
								<td>{row.pl}</td>
								<td>{row.province}</td>
								<td>{row.city}</td>
								<td style={{ textAlign: 'center' }}>
									{row.status === '1' ? 'ACTIVE' : 'INACTIVE'}
								</td>
								<td
									style={{
										color:
											row.confirmation === '0'
												? '#ffc905'
												: row.confirmation === '1'
												? '#00ff0d'
												: '#ff0000',
										textAlign: 'center',
									}}
								>
									{row.confirmation === '0' ? (
										<Tooltip title='Pending'>
											<PendingIcon />
										</Tooltip>
									) : row.confirmation === '1' ? (
										<Tooltip title='Approved'>
											<CheckCircleIcon />
										</Tooltip>
									) : (
										<Tooltip title='Rejected'>
											<RemoveCircleIcon />
										</Tooltip>
									)}
								</td>
								<td style={{ textAlign: 'center' }}>
									<Box display={'flex'} gap={1}>
										<Button variant='danger' onClick={() => detail(row.id)}>
											{row.confirmation === '1' ? 'EDIT' : 'DETAIL'}
										</Button>
										{row.confirmation === '1' && (
											<Button variant='danger' onClick={() => qr(row.id)}>
												QR
											</Button>
										)}
									</Box>
								</td>
							</tr>
						))}
				</tbody>
			</Table>
			{dataCustomer.map === undefined && (
				<div className='d-flex justify-content-center'>NO DATA</div>
			)}
			{show === true && (
				<Edit
					show={show}
					onHide={() => setShow(false)}
					getCustomer={search}
					data={{ customer: customer, user: user }}
				/>
			)}
			{show1 === true && (
				<Qrcode
					show={show1}
					onHide={() => setShow1(false)}
					data={{ customer: customer }}
				/>
			)}
		</>
	)
}

export default App
