import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import url from "../url";
import { useEffect, useState } from "react";

export default function Submit(props) {
  // MENU
  const menu = props.data.menu;
  // USER
  const user = props.data.user;
  // CODE
  const code = props.data.code;
  // EXPEDITION
  const expeditiontype = props.data.expeditiontype;
  const expedition = props.data.expedition;
  // PICKLIST
  const picklist = props.data.picklist;
  // CONFIRM
  const create = props.data.create;
  const update = props.data.update;
  const cancel = props.data.cancel;
  const option = props.data.option;
  // CANCEL REASON
  const [cancelReasons, setCancelReasons] = useState([]);
  const [cancelReason, setCancelReason] = useState(null);
  const getCancelReason = () => {
    axios
      .get(url + "/expedition/getCancelReason.php")
      .then(function (response) {
        let result = response.data;
        setCancelReasons(result);
      })
      .catch(function (error) {});
  };
  // CREATE FORM
  const postCreate = (form) => {
    axios
      .post(url + "/expedition/create.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          props.set("reset", true);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const createForm = () => {
    props.set("confirm", false);
    props.set("create", false);
    props.set("option", false);
    let form = new FormData();
    form.append("user", user.id);
    form.append("code", code);
    form.append("expeditiontypeid", expeditiontype.type.id);
    form.append("expeditiontype", expeditiontype.type.name);
    form.append("expeditiontypevolume", expeditiontype.type.volume_tolerance);
    form.append("name", expeditiontype.name);
    form.append("platenumber", expeditiontype.platenumber);
    form.append("driver", expeditiontype.driver);
    form.append("driverphone", expeditiontype.driverphone);
    postCreate(form);
  };
  // BACK
  const backSearch = () => {
    setCancelReason(null);
    props.set("back");
  };
  // CANCEL FORM
  const postCancel = (form) => {
    axios
      .post(url + "/expedition/cancel.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          setCancelReasons([]);
          setCancelReason(null);
          props.set("expedition", null);
          props.set("expeditions");
          props.set("reset", true);
        } else {
          props.set("alert", {
            type: "error",
            message: "DELIVERY MUST BE CANCEL OR THERE'S NO PICKLIST",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const cancelForm = () => {
    props.set("confirm", false);
    props.set("cancel", false);
    props.set("option", false);
    let form = new FormData();
    form.append("user", user.id);
    form.append("expeditionid", expedition.id);
    form.append("cancelreasonid", cancelReason.id);
    form.append("cancelreason", cancelReason.name);
    postCancel(form);
  };
  // UPDATE FORM
  const postUpdate = (form) => {
    axios
      .post(url + "/expedition/update.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          props.set("expedition", null);
          props.set("expeditions");
          props.set("reset", true);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const updateForm = () => {
    props.set("confirm", false);
    props.set("update", false);
    props.set("option", false);
    let form = new FormData();
    form.append("user", user.id);
    form.append("expeditionid", expedition.id);
    form.append("expeditiontypeid", expeditiontype.type.id);
    form.append("expeditiontype", expeditiontype.type.name);
    form.append("expeditiontypevolume", expeditiontype.type.volume_tolerance);
    form.append("name", expeditiontype.name);
    form.append("platenumber", expeditiontype.platenumber);
    form.append("driver", expeditiontype.driver);
    form.append("driverphone", expeditiontype.driverphone);
    postUpdate(form);
  };
  const handleConfirm = (type) => {
    props.set("confirm", true);
    if (type === "create") {
      props.set("create", true);
    }
    if (type === "update") {
      props.set("update", true);
    }
    if (type === "cancel") {
      props.set("cancel", true);
    }
  };

  useEffect(() => {
    if (menu === 1) {
      getCancelReason();
      if (option && update) {
        updateForm();
      }
      if (option && cancel) {
        cancelForm();
      }
    } else {
      if (option && create) {
        createForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, expeditiontype, option, expedition]);

  return (
    (menu === 2 || expedition) && (
      <>
        <Stack
          direction="row"
          padding={2}
          spacing={1}
          justifyContent={menu === 2 ? "right" : "space-between"}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="error"
            disabled={
              menu === 1
                ? false
                : expeditiontype &&
                  expeditiontype.type &&
                  expeditiontype.name &&
                  expeditiontype.platenumber &&
                  expeditiontype.driver &&
                  expeditiontype.driverphone
                ? false
                : true
            }
            onClick={() =>
              menu === 1 ? backSearch() : handleConfirm("create")
            }
          >
            {menu === 1 ? "BACK" : "CREATE"}
          </Button>
          {menu === 1 && expedition && expedition.status === "0" && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Autocomplete
                sx={{ width: 200 }}
                getOptionLabel={(cancelReasons) => cancelReasons.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={cancelReason}
                options={cancelReasons}
                onChange={(_, value) => setCancelReason(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="CANCEL REASON"
                    variant="standard"
                  />
                )}
              />
              <Button
                variant="contained"
                color="error"
                onClick={() => handleConfirm("cancel")}
                disabled={
                  expeditiontype &&
                  expeditiontype.type &&
                  expeditiontype.name &&
                  expeditiontype.platenumber &&
                  expeditiontype.driver &&
                  expeditiontype.driverphone &&
                  cancelReason
                    ? false
                    : true
                }
              >
                CANCEL
              </Button>
              {expedition && picklist.length === 0 && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleConfirm("update")}
                  disabled={
                    expeditiontype &&
                    expeditiontype.type &&
                    expeditiontype.name &&
                    expeditiontype.platenumber &&
                    expeditiontype.driver &&
                    expeditiontype.driverphone
                      ? false
                      : true
                  }
                >
                  UPDATE
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </>
    )
  );
}
