import logo from './logo1.png'
import axios from 'axios'
import url from '../url'
import {
	Navbar,
	Container,
	Offcanvas,
	Nav,
	NavDropdown,
	Stack,
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

const App = (props) => {
	const [menu, setMenu] = useState([])
	const user = props.data.user
	const navigate = useNavigate()

	useEffect(() => {
		if (!user.id) {
			navigate('/')
		} else {
			getMenu(user.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getMenu = (user) => {
		axios
			.get(url + '/menu/menu.php', {
				params: {
					user: user,
				},
			})
			.then(function (response) {
				let result = response.data
				result = result.map((row) => {
					return {
						id: row.id,
						level0: row.level0,
						level1: row.level1,
						name: row.name,
						path: row.path,
					}
				})
				setMenu(result)
			})
			.catch(function (error) {})
	}

	const logout = (user) => {
		axios
			.get(url + '/logout.php', {
				params: {
					user: user,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result) {
					navigate('/')
				}
			})
			.catch(function (error) {})
	}

	const selectMenu = (link) => {
		navigate('/' + link, { state: { user: user } })
	}

	return (
		<Navbar bg='danger' expand='false' sticky='top'>
			<Container style={{ height: 'auto' }}>
				<Navbar.Brand>
					<button style={{ background: 'none', border: 'none' }}>
						<img
							src={logo}
							width='auto'
							height='50'
							alt='Martini Food System'
							onClick={() => selectMenu('home')}
						/>
					</button>
				</Navbar.Brand>
				{user !== null && (
					<Stack direction='horizontal'>
						<Nav className='ms-auto mr-3'>
							<Navbar.Text>
								SIGN IN AS :{' '}
								<button
									style={{
										background: 'none',
										border: 'none',
										textDecorationLine: 'underline',
									}}
									onClick={() => logout(user.id)}
								>
									{user.name}
								</button>
							</Navbar.Text>
						</Nav>
						<div className='vr mx-3' />
						<Navbar.Toggle
							aria-controls='offcanvasNavbar-expand-false'
							className='bg-white justify-content-end'
						/>
					</Stack>
				)}
				<Navbar.Offcanvas
					id='offcanvasNavbar-expand-false'
					aria-labelledby='offcanvasNavbarLabel-expand-false'
					placement='end'
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id='offcanvasNavbarLabel-expand-false'>
							MENU
						</Offcanvas.Title>
					</Offcanvas.Header>

					{user !== null && menu.map !== undefined && (
						<Offcanvas.Body>
							{/* <p><b>DATA : TESTING</b></p> */}
							<Nav className='justify-content-end flex-grow-1 pe-3'>
								{menu.map(
									(row) =>
										row.level1 === '0' &&
										row.level0 !== '0' && (
											<NavDropdown
												key={row.id}
												title={row.name}
												id='offcanvasNavbarDropdown-expand-false'
											>
												{menu.map(
													(row1) =>
														row1.level0 === row.level0 &&
														row1.level1 !== '0' && (
															<NavDropdown.Item
																key={row1.id}
																onClick={() => selectMenu(row1.path)}
															>
																{row1.name}
															</NavDropdown.Item>
														)
												)}
											</NavDropdown>
										)
								)}
								{/* <NavDropdown title={"SALES"}>
                  <NavDropdown.Item onClick={() => selectMenu("so")}>Sales Order</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="">TESTING</Nav.Link> */}
							</Nav>
						</Offcanvas.Body>
					)}
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	)
}

export default App
