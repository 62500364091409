import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Paper,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material'
import axios from 'axios'
import url from '../url'
import { useState } from 'react'

export default function SearchItem(props) {
	const [openConfirm, setOpenConfirm] = useState(false)
	const [tempUpdateItemId, setTempUpdateItemId] = useState(null)
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarData, setSnackbarData] = useState({
		type: 'Success',
		message: '',
	})

	function onChangeText(value) {
		props.onSetHandling('searchItem', { type: 'Name', value: value })
		onSearchItem(value, props.data.searchItem.status ? 1 : 0)
	}

	function onchangeSwitch() {
		props.onSetHandling('searchItem', {
			type: 'Status',
			value: !props.data.searchItem.status,
		})
		onSearchItem(
			props.data.searchItem.name,
			!props.data.searchItem.status ? 1 : 0
		)
	}

	function onSearchItem(item, status) {
		axios
			.get(url + '/item/getItem.php', {
				params: { item: item, status: status },
			})
			.then(function (response) {
				let result = response.data
				props.onSetHandling('dataItem', result)
			})
			.catch(function (error) {})
	}

	function updateSwitch(id) {
		setOpenConfirm(false)

		let result = props.data.dataItem.map((row) => {
			if (row.id === id) {
				onUpdateItem(id, row.status === '1' ? '0' : '1')
				return {
					id: row.id,
					name: row.name,
					status: row.status === '1' ? '0' : '1',
				}
			} else {
				return {
					id: row.id,
					name: row.name,
					status: row.status,
				}
			}
		})

		props.onSetHandling('dataItem', result)
	}

	function onUpdateItem(itemId, statusItem) {
		let form = new FormData()
		form.append('itemId', itemId)
		form.append('statusItem', statusItem)
		form.append('userId', props.data.user.id)

		axios
			.post(url + '/item/updateItem.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				setOpenSnackbar(true)
				setSnackbarData({ type: result.type, message: result.message })
				onSearchItem(
					props.data.searchItem.name,
					props.data.searchItem.status ? 1 : 0
				)
			})
	}

	return (
		<Box marginTop={2}>
			<Divider sx={{ marginY: 2, borderColor: 'red', borderWidth: 2 }} />

			<Typography variant='h6' fontWeight={'bold'}>
				Filter
			</Typography>

			<Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
				{/* //*Filter Item Name */}
				<Typography flex={1} textAlign={'center'}>
					Item Name
				</Typography>
				<Box flex={1}>
					<TextField
						variant='standard'
						value={props.data.searchItem.name}
						onChange={(e) => onChangeText(e.target.value)}
						fullWidth
					/>
				</Box>

				{/* //*Filter Status */}
				<Typography flex={1} textAlign={'center'}>
					Status
				</Typography>
				<Box flex={1} display={'flex'} justifyContent={'center'}>
					<Switch
						color='error'
						checked={props.data.searchItem.status}
						onChange={() => onchangeSwitch()}
					/>
				</Box>
			</Box>

			<Divider sx={{ marginY: 2, borderColor: 'red', borderWidth: 2 }} />

			{/* //*Table */}
			<TableContainer component={Paper} sx={{ maxHeight: 450 }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Item
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Status
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{/* //*If there is data */}
						{props.data.dataItem.length > 0 ? (
							props.data.dataItem.map((item) => (
								<TableRow key={item.id}>
									<TableCell>{item.name}</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										<Switch
											color='error'
											checked={item.status === '1' ? true : false}
											onChange={() => {
												setTempUpdateItemId(item.id)
												setOpenConfirm(true)
											}}
										/>
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										<Button
											variant='text'
											color='error'
											onClick={() => props.onSetHandling('logItem', item.id)}
										>
											Log
										</Button>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={3} sx={{ textAlign: 'center' }}>
									There is no data
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* //*Confirm */}
			<Dialog
				open={openConfirm}
				onClose={() => setOpenConfirm(false)}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle>CONFIRMATION</DialogTitle>
				<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
					<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						color='error'
						onClick={() => updateSwitch(tempUpdateItemId)}
					>
						YES
					</Button>
					<Button color='error' onClick={() => setOpenConfirm(false)}>
						NO
					</Button>
				</DialogActions>
			</Dialog>

			{/* //*Alert */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={snackbarData?.type === 'Success' ? 'success' : 'error'}
				>
					{snackbarData?.message}
				</Alert>
			</Snackbar>
		</Box>
	)
}
