import {
	Button,
	Skeleton,
	Stack,
	Typography,
	Alert,
	Snackbar,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Filter from './filter'
import Tables from './tables'
import Form from './form'
import Item from './item'
import Submit from './submit'

export default function Invoice() {
	// NAVIGATION
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user
	// MENU
	const [menu, setMenu] = useState(2)
	// LOADING
	const [loading, setLoading] = useState(true)
	// INVOICE
	const [dataInvoice, setDataInvoice] = useState([])
	const [invoice, setInvoice] = useState(null)
	// CODE
	const [code, setCode] = useState(null)
	// DELIVERY
	const [delivery, setDelivery] = useState(null)
	// ITEM
	const [item, setItem] = useState([])
	// INVOICE TYPE
	const [type, setType] = useState(null)
	// ERROR
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('-')
	const [errorType, setErrorType] = useState('error')
	const errorClose = () => {
		setError(false)
	}
	const handleError = (data) => {
		setErrorType(data.type)
		setErrorMsg(data.message)
		setError(data.error)
	}
	// RESET
	const [reset, setReset] = useState(false)
	// HANDLE SET
	const handleSet = (type, data) => {
		if (type === 'datainvoice') {
			if (data) {
				setDataInvoice(data)
			} else {
				setDataInvoice([])
			}
		}
		if (type === 'invoice') {
			setInvoice(data)
		}
		if (type === 'type') {
			setType(data)
		}
		if (type === 'do') {
			setDelivery(data)
		}
		if (type === 'alert') {
			handleError(data)
		}
		if (type === 'item') {
			if (data) {
				setItem(data)
			} else {
				setItem([])
			}
		}
		if (type === 'code') {
			setCode(data)
		}
		if (type === 'reset') {
			setReset(data)
		}
		if (type === 'back') {
			setInvoice(null)
		}
	}

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		} else {
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{/* TITLE */}
			<Typography paddingY={2} variant='h5'>
				<Stack justifyContent='center' alignItems='center'>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'INVOICE'}
				</Stack>
			</Typography>
			{/* MENU */}
			<Stack direction='row' alignItems='center'>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 1 ? 'error' : 'inherit'}
					onClick={() => setMenu(1)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'SEARCH'}
				</Button>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 2 ? 'error' : 'inherit'}
					onClick={() => setMenu(2)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'CREATE'}
				</Button>
			</Stack>
			{/* FILTER */}
			<Filter set={handleSet} data={{ user: user, menu: menu, reset: reset }} />
			{/* TABLE */}
			<Tables
				set={handleSet}
				data={{ menu: menu, datainvoice: dataInvoice, invoice: invoice }}
			/>
			{/* FORM */}
			<Form
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					do: delivery,
					invoice: invoice,
					reset: reset,
				}}
			/>
			{/* ITEM */}
			<Item
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					do: delivery,
					reset: reset,
					invoice: invoice,
				}}
			/>
			{/* SUBMIT */}
			<Submit
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					code: code,
					do: delivery,
					item: item,
					invoice: invoice,
					type: type,
				}}
			/>
			{/* ERROR */}
			<Snackbar
				open={error}
				autoHideDuration={5000}
				onClose={errorClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={errorClose} severity={errorType}>
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	)
}
