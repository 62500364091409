import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import url from '../url'

const App = (props) => {
	const user = props.data.user
	const [dataPL, setDatapl] = useState([])
	const [dataProvince, setDataprovince] = useState([])
	const [dataCity, setDatacity] = useState([])
	const [dataCusttype, setDatacusttype] = useState([])
	const [alertInfo, setAlertinfo] = useState('')
	const [success, setSuccess] = useState(false)
	const [failed, setFailed] = useState(false)

	const [name, setName] = useState('')
	const [status, setStatus] = useState('0')
	const [pl, setPL] = useState('0')
	const [province, setProvince] = useState('0')
	const [city, setCity] = useState('0')
	const [address, setAddress] = useState('')
	const [shipment, setShipment] = useState('')
	const [top, setTop] = useState('')
	const [phone, setPhone] = useState('')
	const [disc, setDisc] = useState('')
	const [cp, setCP] = useState('')
	const [custType, setCusttype] = useState('0')

	useEffect(() => {
		getPricelist()
		getProvince()
		getCustType()
		getCode()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// CODE
	const [custCode, setCustCode] = useState('')
	const getCode = () => {
		axios
			.get(url + '/customer/code.php')
			.then(function (response) {
				let result = response.data
				setCode(result)
			})
			.catch(function (error) {})
	}
	const setCode = (code) => {
		let date = new Date()
		let dyear = date.getFullYear()
		let dmonth = date.getMonth() + 1
		let countsplit = code.substring(9, 13)
		let monthsplit = code.substring(7, 9)
		let count = Number(('000' + countsplit).slice(-4))
		let month = Number(('0' + monthsplit).slice(-2))
		if (dmonth === month) {
			count++
			count =
				'CS-' +
				String(dyear) +
				('0' + dmonth).slice(-2) +
				('000' + count).slice(-4)
		} else {
			count =
				'CS-' + String(dyear) + ('0' + dmonth).slice(-2) + '0001'.slice(-4)
		}
		setCustCode(count)
	}

	const getPricelist = () => {
		axios
			.get(url + '/customer/getPricelist.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatapl(result)
			})
			.catch(function (error) {})
	}

	const getCustType = () => {
		axios
			.get(url + '/customer/getCustType.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatacusttype(result)
			})
			.catch(function (error) {})
	}

	const getProvince = () => {
		axios
			.get(url + '/customer/getProvince.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataprovince(result)
			})
			.catch(function (error) {})
	}

	const getCity = (province) => {
		axios
			.get(url + '/customer/getCity.php', {
				params: {
					province: province,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatacity(result)
			})
			.catch(function (error) {})
	}

	const selectProvince = (id) => {
		setProvince(id)
		getCity(id)
	}

	const postCreate = (data) => {
		axios
			.post(url + '/customer/create.php', data, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'Ok') {
					setAlertinfo('Insert Success')
					setFailed(false)
					setSuccess(true)
					setTimeout(() => {
						setSuccess(false)
					}, 5000)
					getCode()
					setName('')
					setStatus('0')
					setPL('0')
					setProvince('0')
					setCity('0')
					setAddress('')
					setShipment('')
					setTop('')
					setPhone('')
					setDisc('')
					setCP('')
					setCusttype('0')
				} else {
					setAlertinfo(result)
					setSuccess(false)
					setFailed(true)
					setTimeout(() => {
						setFailed(false)
					}, 5000)
				}
			})
			.catch(function (error) {})
	}

	const formatNum = (type, data) => {
		let format = '0123456789'
		let number = ''
		for (let i = 0; i < data.length; i++) {
			if (format.indexOf(data[i]) > -1) {
				number = number + data[i]
			} else {
				setAlertinfo('Input Only Number')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'top') {
			setTop(number)
		}
		if (type === 'phone') {
			setPhone(number)
		}
	}

	const formatDisc = (data) => {
		let format = '0123456789.'
		let number = ''
		for (let i = 0; i < data.length; i++) {
			if (format.indexOf(data[i]) > -1) {
				number = number + data[i]
			} else {
				setAlertinfo('Input Only Number')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		setDisc(number)
	}

	const create = () => {
		let formdata = new FormData()
		formdata.append('user', user.id)
		formdata.append('code', custCode)
		formdata.append('name', name)
		formdata.append('status', status)
		formdata.append('pl', pl)
		formdata.append('province', province)
		formdata.append('city', city)
		formdata.append('address', address)
		formdata.append('shipment', shipment)
		formdata.append('phone', phone)
		formdata.append('top', top)
		formdata.append('disc', disc)
		formdata.append('cp', cp)
		formdata.append('custtype', custType)
		postCreate(formdata)
	}

	return (
		<Container>
			{success === true && <Alert variant='success'>{alertInfo}</Alert>}
			{failed === true && <Alert variant='danger'>{alertInfo}</Alert>}
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>NAME</Col>:
				<Col>
					<Form.Control
						placeholder='EX.PT.JAYA'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Col>
				{/* <Col>STATUS</Col>:
				<Col>
					<Form.Select
						value={status}
						onChange={(e) => setStatus(e.target.value)}
					>
						<option value={'1'}>ACTIVE</option>
						<option value={'0'}>INACTIVE</option>
					</Form.Select>
				</Col> */}
			</Row>
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>PRICE LIST</Col>:
				<Col>
					<Form.Select value={pl} onChange={(e) => setPL(e.target.value)}>
						<option value={'0'}>SELECT PL</option>
						{dataPL.map !== undefined &&
							dataPL.map((row) => (
								<option key={row.id} value={row.id}>
									{row.name}
								</option>
							))}
					</Form.Select>
				</Col>
				<Col>TERM OF PAY</Col>:
				<Col>
					<InputGroup>
						<Form.Control
							placeholder='EX.10'
							value={top}
							onChange={(e) => formatNum('top', e.target.value)}
						/>
						<InputGroup.Text>DAY</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>ADDRESS</Col>:
				<Col>
					<Form.Control
						placeholder='EX.JL.SUKO'
						value={address}
						onChange={(e) => setAddress(e.target.value)}
					/>
				</Col>
				<Col>CUSTOMER TYPE</Col>:
				<Col>
					<Form.Select
						value={custType}
						onChange={(e) => setCusttype(e.target.value)}
					>
						<option value={'0'}>SELECT CUST TYPE</option>
						{dataCusttype.map !== undefined &&
							dataCusttype.map((row) => (
								<option key={row.id} value={row.id}>
									{row.name}
								</option>
							))}
					</Form.Select>
				</Col>
			</Row>
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>PHONE</Col>:
				<Col>
					<Form.Control
						placeholder='EX.08126337898'
						value={phone}
						onChange={(e) => formatNum('phone', e.target.value)}
					/>
				</Col>
				<Col>CONTACT PERSON</Col>:
				<Col>
					<Form.Control
						placeholder='EX.JOHN'
						value={cp}
						onChange={(e) => setCP(e.target.value)}
					/>
				</Col>
			</Row>
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>PROVINCE</Col>:
				<Col>
					<Form.Select
						value={province}
						onChange={(e) => selectProvince(e.target.value)}
					>
						<option value={'0'}>SELECT PROVINCE</option>
						{dataProvince.map !== undefined &&
							dataProvince.map((row) => (
								<option key={row.id} value={row.id}>
									{row.name}
								</option>
							))}
					</Form.Select>
				</Col>
				<Col>CITY</Col>:
				<Col>
					<Form.Select value={city} onChange={(e) => setCity(e.target.value)}>
						<option value={'0'}>SELECT CITY</option>
						{dataCity.map !== undefined &&
							dataCity.map((row) => (
								<option key={row.id} value={row.id}>
									{row.name}
								</option>
							))}
					</Form.Select>
				</Col>
			</Row>
			<Row className='mb-2 justify-content-center align-items-center'>
				<Col>DISCOUNT REGULAR</Col>:
				<Col>
					<InputGroup>
						<Form.Control
							placeholder='EX.1.5'
							value={disc}
							onChange={(e) => formatDisc(e.target.value)}
						/>
						<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
				<Col></Col>
				<Col></Col>
			</Row>
			<Button
				variant='danger'
				disabled={
					name === '' ||
					pl === '0' ||
					city === '0' ||
					address === '' ||
					phone === '' ||
					top === '' ||
					disc === '' ||
					province === '0'
						? true
						: false
				}
				onClick={() => create()}
			>
				CREATE
			</Button>
		</Container>
	)
}

export default App
