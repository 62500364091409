import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import axios from 'axios'
import url from '../url'
import { useEffect, useState } from 'react'

export default function Submit(props) {
	// MENU
	const menu = props.data.menu
	// USER
	const user = props.data.user
	// ITEM
	const item = props.data.item
	// CODE
	const code = props.data.code
	// PL
	const pl = props.data.pl
	// SO
	const so = props.data.so
	// EXPEDITION
	const expedition = props.data.expedition
	// CONFIRM
	const create = props.data.create
	const update = props.data.update
	const cancel = props.data.cancel
	const option = props.data.option
	// CANCEL REASON
	const [dataReason, setDataReason] = useState([])
	const [reason, setReason] = useState(null)
	const getReason = () => {
		axios
			.get(url + '/picklist/getReason.php')
			.then(function (response) {
				let result = response.data
				setDataReason(result)
			})
			.catch(function (error) {})
	}
	// CREATE
	const postCreatePL = (form) => {
		axios
			.post(url + '/picklist/create.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					props.set('alert', {
						type: 'success',
						message: 'SUCCESS',
						error: true,
					})
					props.set('reset', true)
				} else {
					props.set('alert', {
						type: 'error',
						message: 'FAILED',
						error: true,
					})
				}
			})
			.catch(function (error) {})
	}
	const createPL = () => {
		props.set('confirm', false)
		props.set('create', false)
		props.set('option', false)
		let count = 0
		let sum_vol = 0
		for (let i = 0; i < item.length; i++) {
			sum_vol = sum_vol + item[i].volume * Number(item[i].qty)
			if (
				item[i].qty === 0 ||
				item[i].expired === '' ||
				item[i].orderqty === 0
			) {
				count++
			}
		}
		if (count > 0) {
			props.set('alert', {
				type: 'error',
				message: 'ORDER QTY, QTY, EXPIRED MUST BE FILLED',
				error: true,
			})
		} else if (
			sum_vol >
			expedition.expedition_type_volume - expedition.total_volume
		) {
			props.set('alert', {
				type: 'error',
				message: 'ITEM VOLUME IS MORE THAN EXP. VOLUME',
				error: true,
			})
		} else {
			let form = new FormData()
			form.append('user', user.id)
			form.append('code', code)
			form.append('custid', so.customer_id)
			form.append('custcode', so.customer_code)
			form.append('customer', so.customer)
			form.append('cityid', so.city_id)
			form.append('city', so.city)
			form.append('pricelistid', so.pricelist_id)
			form.append('pricelist', so.pricelist)
			form.append('custtypeid', so.customer_type_id)
			form.append('custtype', so.customer_type)
			form.append('itmtypeid', so.item_type_id)
			form.append('itmtype', so.item_type)
			form.append('phone', so.phone)
			form.append('shipment', so.shipment)
			form.append('soid', so.id)
			form.append('socode', so.code)
			form.append('expid', expedition.id)
			form.append('expcode', expedition.code)
			form.append('salesid', so.sales_id)
			form.append('sales', so.sales)
			item.map((row) => {
				form.append('itmid[]', row.id)
				form.append('itmname[]', row.name)
				form.append('itmcat[]', row.category)
				form.append('itmvol[]', row.volume)
				form.append('itmorderqty[]', row.orderqty)
				form.append('itmqty[]', row.qty)
				form.append('itmexp[]', row.expired)
				return {}
			})
			postCreatePL(form)
		}
	}
	// SEARCH BACK
	const backSearch = () => {
		setReason(null)
		props.set('back')
	}
	// CANCEL PL
	const postCancelPL = (form) => {
		axios
			.post(url + '/picklist/cancel.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					props.set('alert', {
						type: 'success',
						message: 'SUCCESS',
						error: true,
					})
					setDataReason([])
					setReason(null)
					props.set('pl', null)
					props.set('datapl')
					props.set('reset', true)
				} else {
					props.set('alert', {
						type: 'error',
						message: 'FAILED',
						error: true,
					})
				}
			})
			.catch(function (error) {})
	}
	const cancelPL = () => {
		props.set('confirm', false)
		props.set('cancel', false)
		props.set('option', false)
		let form = new FormData()
		form.append('user', user.id)
		form.append('plid', pl.id)
		form.append('reasonid', reason.id)
		form.append('reason', reason.name)
		postCancelPL(form)
	}
	// UPDATE PL
	const postUpdatePL = (form) => {
		axios
			.post(url + '/picklist/update.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					props.set('alert', {
						type: 'success',
						message: 'SUCCESS',
						error: true,
					})
					props.set('pl', null)
					props.set('datapl')
					props.set('reset', true)
				} else {
					props.set('alert', {
						type: 'error',
						message: 'FAILED',
						error: true,
					})
				}
			})
			.catch(function (error) {})
	}
	const updatePL = () => {
		props.set('confirm', false)
		props.set('update', false)
		props.set('option', false)
		if (expedition && expedition.status === '0') {
			let count = 0
			let sum_vol = 0
			for (let i = 0; i < item.length; i++) {
				sum_vol = sum_vol + item[i].volume * Number(item[i].qty)
				if (item[i].qty === 0 || item[i].expired === '') {
					count++
				}
			}
			if (
				sum_vol >
				Number(expedition.total_volume) +
					Number(expedition.expedition_type_volume - expedition.total_volume)
			) {
				props.set('alert', {
					type: 'error',
					message: 'ITEM VOLUME IS MORE THAN EXP. VOLUME',
					error: true,
				})
			} else if (count > 0) {
				props.set('alert', {
					type: 'error',
					message: 'QTY, EXPIRED MUST BE FILLED',
					error: true,
				})
			} else {
				let form = new FormData()
				form.append('user', user.id)
				form.append('plid', pl.id)
				form.append('expid', expedition.id)
				form.append('expcode', expedition.code)
				item.map((row) => {
					form.append('itmid[]', row.id)
					form.append('itmname[]', row.name)
					form.append('itmcat[]', row.category)
					form.append('itmvol[]', row.volume)
					form.append('itmorderqty[]', row.orderqty)
					form.append('itmqty[]', row.qty)
					form.append('itmexp[]', row.expired)
					return {}
				})
				postUpdatePL(form)
			}
		} else if (expedition && expedition.status === '2') {
			props.set('alert', {
				type: 'error',
				message: 'EXPEDITION CANCELED',
				error: true,
			})
		} else {
			props.set('alert', {
				type: 'error',
				message: 'EXPEDITION COMPLETE',
				error: true,
			})
		}
	}
	const handleConfirm = (type) => {
		props.set('confirm', true)
		if (type === 'create') {
			props.set('create', true)
		}
		if (type === 'update') {
			props.set('update', true)
		}
		if (type === 'cancel') {
			props.set('cancel', true)
		}
	}

	useEffect(() => {
		if (menu === 1) {
			getReason()
			if (option && update) {
				updatePL()
			}
			if (option && cancel) {
				cancelPL()
			}
		} else {
			if (option && create) {
				createPL()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu, so, pl, expedition, option])

	return (
		(menu === 2 || pl) && (
			<>
				<Stack
					direction='row'
					padding={2}
					spacing={1}
					justifyContent={menu === 2 ? 'right' : 'space-between'}
					alignItems='center'
				>
					<Button
						variant='contained'
						color='error'
						disabled={
							menu === 1
								? false
								: so && expedition && item && item.length > 0
								? false
								: true
						}
						onClick={() =>
							menu === 1 ? backSearch() : handleConfirm('create')
						}
					>
						{menu === 1 ? 'BACK' : 'CREATE'}
					</Button>
					{menu === 1 && pl && pl.status === '0' && (
						<Stack direction='row' alignItems='center' spacing={1}>
							<Autocomplete
								sx={{ width: 200 }}
								getOptionLabel={(dataReason) => dataReason.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								value={reason}
								options={dataReason}
								onChange={(_, value) => setReason(value)}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder='CANCEL REASON'
										variant='standard'
									/>
								)}
							/>
							<Button
								variant='contained'
								color='error'
								onClick={() => handleConfirm('cancel')}
								disabled={
									expedition && item && item.length > 0 && reason ? false : true
								}
							>
								CANCEL
							</Button>
							{pl && pl.status === '0' && (
								<Button
									variant='contained'
									color='error'
									onClick={() => handleConfirm('update')}
									disabled={
										expedition && item && item.length > 0 ? false : true
									}
								>
									UPDATE
								</Button>
							)}
						</Stack>
					)}
				</Stack>
			</>
		)
	)
}
