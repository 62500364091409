import { Modal, Button } from "react-bootstrap";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import url from "../url";

const App = (props) => {
  const componentRef = useRef();
  const cust = props.data.customer;
  const [dataCust, setDatacust] = useState();

  useEffect(() => {
    getCust();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCust = () => {
    axios
      .get(url + "/customer/getCustDetail.php", {
        params: {
          cust: cust,
        },
      })
      .then(function (response) {
        let result = response.data;
        setDatacust(result);
      })
      .catch(function (error) {});
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">QR CODE</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          ref={componentRef}
          className="d-flex flex-column justify-content-center align-items-center mt-5"
        >
          <QRCode value={cust} />
          <h2 className="mt-3">{dataCust ? dataCust.name : "-"}</h2>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ReactToPrint
          trigger={() => <Button variant="danger">PRINT</Button>}
          content={() => componentRef.current}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default App;
