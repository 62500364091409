import axios from 'axios'
import url from '../url'

export async function productionData(startDate, endDate) {
  try {
    // Get data user
    const response = await axios.get(
      `${url}/production/production-data.php?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    // Error
    return error.message
  }
}