import { Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const App = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Stack height='100vh' alignItems='center' justifyContent='center'>
				<Typography variant='h2'>COMING SOON</Typography>
			</Stack>
		</>
	)
}

export default App
