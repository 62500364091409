import { Modal, Form, Row, Col, Table, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import url from '../url'
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ImageList,
	ImageListItem,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

const App = (props) => {
	const customer = props.data.customer
	const user = props.data.user
	const [dataPL, setDatapl] = useState([])
	const [dataProvince, setDataprovince] = useState([])
	const [dataCity, setDatacity] = useState([])
	const [dataDisctype, setDatadisctype] = useState([])
	const [dataItmtype, setDataitmtype] = useState([])
	const [dataItmcat, setDataitmcat] = useState([])
	const [dataItm, setDataitm] = useState([])
	const [dataCusttype, setDatacusttype] = useState([])
	const [custImg, setCustImg] = useState([])
	const [success, setSuccess] = useState(false)
	const [failed, setFailed] = useState(false)
	const [alertInfo, setAlertinfo] = useState('')
	const [openConfirm, setOpenConfirm] = useState({ open: false, data: '' })

	const [dataCDisc, setDatacdisc] = useState([])
	const [dataDiscitmtype, setDatadiscitmtype] = useState([])
	const [dataDiscitmcat, setDatadiscitmcat] = useState([])
	const [dataDiscitm, setDatadiscitm] = useState([])
	const [code, setCode] = useState('')
	const [name, setName] = useState('')
	const [pl, setPL] = useState('0')
	const [province, setProvince] = useState('0')
	const [city, setCity] = useState('0')
	const [address, setAddress] = useState('')
	const [phone, setPhone] = useState('')
	const [top, setTop] = useState('')
	const [status, setStatus] = useState('1')
	const [confirm, setConfirm] = useState('0')
	const [discCust, setDisccust] = useState('0')
	const [discType, setDisctype] = useState('0')
	const [discCat, setDiscCat] = useState('0')
	const [discItm, setDiscitm] = useState('0')
	const [cp, setCP] = useState('')
	const [custType, setCusttype] = useState('0')

	useEffect(() => {
		getCustDetail()
		getCustImg()
		getCustType()
		getDiscCust()
		getDiscItmType()
		getDiscItmCat()
		getDiscType()
		getDiscItm()
		getPricelist()
		getProvince()
		getItmType()
		getItmCat()
		getItem()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getCustDetail = () => {
		axios
			.get(url + '/customer/getCustDetail.php', {
				params: {
					cust: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				setCode(result.code)
				setName(result.name)
				setPL(result.pricelist_id)
				setCusttype(result.customer_type_id)
				setProvince(result.province_id)
				getCity(result.province_id)
				setCity(result.city_id)
				setAddress(result.address)
				setPhone(result.phone)
				setCP(result.contact_person)
				setTop(result.term_of_pay)
				setStatus(result.status)
				setConfirm(result.confirmation)
			})
			.catch(function (error) {})
	}

	const getCustType = () => {
		axios
			.get(url + '/customer/getCustType.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatacusttype(result)
			})
			.catch(function (error) {})
	}

	const getCustImg = () => {
		axios
			.get(url + '/customer/getCustImg.php', {
				params: { customerId: customer },
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							img: row.img,
						}
					})
				}
				setCustImg(result)
			})
			.catch(function (error) {})
	}

	const getDiscCust = () => {
		axios
			.get(url + '/customer/getDiscCust.php', {
				params: {
					cust: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
							disc: row.disc,
						}
					})
				}
				setDatacdisc(result)
			})
			.catch(function (error) {})
	}

	const getDiscItmType = () => {
		axios
			.get(url + '/customer/getDiscItmType.php', {
				params: {
					cust: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
							disc: row.disc,
						}
					})
				}
				setDatadiscitmtype(result)
			})
			.catch(function (error) {})
	}

	const getDiscItmCat = () => {
		axios
			.get(url + '/customer/getDiscItmCat.php', {
				params: {
					cust: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
							disc: row.disc,
						}
					})
				}
				setDatadiscitmcat(result)
			})
			.catch(function (error) {})
	}

	const getDiscItm = () => {
		axios
			.get(url + '/customer/getDiscItm.php', {
				params: {
					cust: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
							disc: row.disc,
						}
					})
				}
				setDatadiscitm(result)
			})
			.catch(function (error) {})
	}

	const getDiscType = () => {
		axios
			.get(url + '/customer/getDiscType.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatadisctype(result)
			})
			.catch(function (error) {})
	}

	const getPricelist = () => {
		axios
			.get(url + '/customer/getPricelist.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatapl(result)
			})
			.catch(function (error) {})
	}

	const getProvince = () => {
		axios
			.get(url + '/customer/getProvince.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataprovince(result)
			})
			.catch(function (error) {})
	}

	const getCity = (province) => {
		axios
			.get(url + '/customer/getCity.php', {
				params: {
					province: province,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDatacity(result)
			})
			.catch(function (error) {})
	}

	const getItmType = () => {
		axios
			.get(url + '/customer/getItmType.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataitmtype(result)
			})
			.catch(function (error) {})
	}

	const getItmCat = () => {
		axios
			.get(url + '/customer/getItmCat.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataitmcat(result)
			})
			.catch(function (error) {})
	}

	const getItem = () => {
		axios
			.get(url + '/customer/getItem.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
						}
					})
				}
				setDataitm(result)
			})
			.catch(function (error) {})
	}

	const postUpdatecust = (data) => {
		axios
			.post(url + '/customer/postUpdateCust.php', data, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'Ok') {
					setAlertinfo('Update Success')
					setFailed(false)
					setSuccess(true)
					setTimeout(() => {
						setSuccess(false)
					}, 5000)
				} else {
					setAlertinfo('Update Failed')
					setSuccess(false)
					setFailed(true)
					setTimeout(() => {
						setFailed(false)
					}, 5000)
				}
				props.getCustomer()
			})
			.catch(function (error) {})
	}

	const postUpdatedisc = (data) => {
		axios
			.post(url + '/customer/postUpdateDisc.php', data, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'Ok') {
					setAlertinfo('Update Success')
					setFailed(false)
					setSuccess(true)
					setTimeout(() => {
						setSuccess(false)
					}, 5000)
				} else {
					setAlertinfo('Update Failed')
					setSuccess(false)
					setFailed(true)
					setTimeout(() => {
						setFailed(false)
					}, 5000)
				}
			})
			.catch(function (error) {})
	}

	const selectProvince = (id) => {
		setProvince(id)
		getCity(id)
	}

	const formatNum = (type, data) => {
		let format = '0123456789'
		let number = ''
		for (let i = 0; i < data.length; i++) {
			if (format.indexOf(data[i]) > -1) {
				number = number + data[i]
			} else {
				setAlertinfo('Input Only Number')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'phone') {
			setPhone(number)
		}
		if (type === 'top') {
			setTop(number)
		}
	}

	const setDisc = (type, id, discount) => {
		let format = '0123456789.'
		let number = ''
		for (let i = 0; i < discount.length; i++) {
			if (format.indexOf(discount[i]) > -1) {
				number = number + discount[i]
			} else {
				setAlertinfo('Input Only Number')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'disccust') {
			let result = dataCDisc.map((row) => {
				if (row.id === id) {
					return {
						id: row.id,
						name: row.name,
						disc: number,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						disc: row.disc,
					}
				}
			})
			setDatacdisc(result)
		}
		if (type === 'disctype') {
			let result = dataDiscitmtype.map((row) => {
				if (row.id === id) {
					return {
						id: row.id,
						name: row.name,
						disc: number,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						disc: row.disc,
					}
				}
			})
			setDatadiscitmtype(result)
		}
		if (type === 'disccat') {
			let result = dataDiscitmcat.map((row) => {
				if (row.id === id) {
					return {
						id: row.id,
						name: row.name,
						disc: number,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						disc: row.disc,
					}
				}
			})
			setDatadiscitmcat(result)
		}
		if (type === 'discitm') {
			let result = dataDiscitm.map((row) => {
				if (row.id === id) {
					return {
						id: row.id,
						name: row.name,
						disc: number,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						disc: row.disc,
					}
				}
			})
			setDatadiscitm(result)
		}
	}

	const addDisc = (type) => {
		if (type === 'disccust') {
			if (discCust !== '0') {
				if (dataCDisc.length > 0) {
					let counter = 0
					for (let i = 0; i < dataCDisc.length; i++) {
						if (dataCDisc[i].id === discCust) {
							counter++
						}
					}
					if (counter > 0) {
						setAlertinfo('Discount already exist')
						setSuccess(false)
						setFailed(true)
						setTimeout(() => {
							setFailed(false)
						}, 5000)
					} else {
						setDatacdisc([
							...dataCDisc,
							{ id: discCust, name: dataDisctype[discCust - 1].name, disc: 0 },
						])
					}
				} else {
					setDatacdisc([
						...dataCDisc,
						{ id: discCust, name: dataDisctype[discCust - 1].name, disc: 0 },
					])
				}
			} else {
				setAlertinfo('Please select discount type')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'disctype') {
			if (discType !== '0') {
				if (dataDiscitmtype.length > 0) {
					let counter = 0
					for (let i = 0; i < dataDiscitmtype.length; i++) {
						if (dataDiscitmtype[i].id === discType) {
							counter++
						}
					}
					if (counter > 0) {
						setAlertinfo('Discount already exist')
						setSuccess(false)
						setFailed(true)
						setTimeout(() => {
							setFailed(false)
						}, 5000)
					} else {
						setDatadiscitmtype([
							...dataDiscitmtype,
							{ id: discType, name: dataItmtype[discType - 1].name, disc: 0 },
						])
					}
				} else {
					setDatadiscitmtype([
						...dataDiscitmtype,
						{ id: discType, name: dataItmtype[discType - 1].name, disc: 0 },
					])
				}
			} else {
				setAlertinfo('Please select discount type')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'disccat') {
			if (discCat !== '0') {
				if (dataDiscitmcat.length > 0) {
					let counter = 0
					for (let i = 0; i < dataDiscitmcat.length; i++) {
						if (dataDiscitmcat[i].id === discCat) {
							counter++
						}
					}
					if (counter > 0) {
						setAlertinfo('Discount already exist')
						setSuccess(false)
						setFailed(true)
						setTimeout(() => {
							setFailed(false)
						}, 5000)
					} else {
						setDatadiscitmcat([
							...dataDiscitmcat,
							{ id: discCat, name: dataItmcat[discCat - 1].name, disc: 0 },
						])
					}
				} else {
					setDatadiscitmcat([
						...dataDiscitmcat,
						{ id: discCat, name: dataItmcat[discCat - 1].name, disc: 0 },
					])
				}
			} else {
				setAlertinfo('Please select discount type')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
		if (type === 'discitm') {
			if (discItm !== '0') {
				if (dataDiscitm.length > 0) {
					let counter = 0
					for (let i = 0; i < dataDiscitm.length; i++) {
						if (dataDiscitm[i].id === discItm) {
							counter++
						}
					}
					if (counter > 0) {
						setAlertinfo('Discount already exist')
						setSuccess(false)
						setFailed(true)
						setTimeout(() => {
							setFailed(false)
						}, 5000)
					} else {
						setDatadiscitm([
							...dataDiscitm,
							{ id: discItm, name: dataItm[discItm - 1].name, disc: 0 },
						])
					}
				} else {
					setDatadiscitm([
						...dataDiscitm,
						{ id: discItm, name: dataItm[discItm - 1].name, disc: 0 },
					])
				}
			} else {
				setAlertinfo('Please select discount type')
				setSuccess(false)
				setFailed(true)
				setTimeout(() => {
					setFailed(false)
				}, 5000)
			}
		}
	}

	const updateCust = (confirmation) => {
		setOpenConfirm({ ...openConfirm, open: false })
		setConfirm(confirmation)

		let formdata = new FormData()
		formdata.append('customer', customer)
		formdata.append('name', name)
		formdata.append('pl', pl)
		formdata.append('province', province)
		formdata.append('city', city)
		formdata.append('address', address)
		formdata.append('phone', phone)
		formdata.append('top', top)
		formdata.append('status', status)
		formdata.append('confirmation', confirmation)
		formdata.append('user', user.id)
		formdata.append('custtype', custType)
		formdata.append('cp', cp)
		postUpdatecust(formdata)
	}

	const updateDisc = () => {
		let formdata = new FormData()
		formdata.append('user', user.id)
		formdata.append('cust', customer)
		if (dataCDisc.map !== undefined) {
			// eslint-disable-next-line
			dataCDisc.map((row) => {
				formdata.append('disccust[]', row.id)
				formdata.append('disccustval[]', row.disc)
			})
		}
		if (dataDiscitmtype.map !== undefined) {
			// eslint-disable-next-line
			dataDiscitmtype.map((row) => {
				formdata.append('disctype[]', row.id)
				formdata.append('disctypeval[]', row.disc)
			})
		}
		if (dataDiscitmcat.map !== undefined) {
			// eslint-disable-next-line
			dataDiscitmcat.map((row) => {
				formdata.append('disccat[]', row.id)
				formdata.append('disccatval[]', row.disc)
			})
		}
		if (dataDiscitm.map !== undefined) {
			// eslint-disable-next-line
			dataDiscitm.map((row) => {
				formdata.append('discitm[]', row.id)
				formdata.append('discitmval[]', row.disc)
			})
		}
		postUpdatedisc(formdata)
	}

	return (
		<Modal
			{...props}
			aria-labelledby='contained-modal-title-vcenter'
			fullscreen
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					CUSTOMER EDIT
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Box marginBottom={2}>
					{confirm === '0' && (
						<Alert severity='warning'>This customer need to be confirmed</Alert>
					)}
					{confirm === '1' && status === '0' && (
						<Alert severity='warning'>This customer is inactived</Alert>
					)}
					{success === true && <Alert severity='success'>{alertInfo}</Alert>}
					{failed === true && <Alert severity='error'>{alertInfo}</Alert>}
				</Box>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>CODE</Col>:<Col>{code}</Col>
					<Col>NAME</Col>:
					<Col>
						<Form.Control
							value={name}
							onChange={(e) => setName(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						/>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>PRICE LIST</Col>:
					<Col>
						<Form.Select
							value={pl}
							onChange={(e) => setPL(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						>
							<option value={'0'}>-</option>
							{dataPL.map !== undefined &&
								dataPL.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col>STATUS</Col>:
					<Col>
						<Form.Select
							value={status}
							onChange={(e) => setStatus(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						>
							<option value={'1'}>ACTIVE</option>
							<option value={'0'}>INACTIVE</option>
						</Form.Select>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>ADDRESS</Col>:
					<Col>
						<Form.Control
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						/>
					</Col>
					<Col>CONTACT PERSON</Col>:
					<Col>
						<Form.Control
							value={cp}
							onChange={(e) => setCP(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						/>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>PHONE</Col>:
					<Col>
						<Form.Control
							value={phone}
							onChange={(e) => formatNum('phone', e.target.value)}
							disabled={confirm !== '1' ? true : false}
						/>
					</Col>
					<Col>TERM OF PAY</Col>:
					<Col>
						<InputGroup>
							<Form.Control
								value={top}
								onChange={(e) => formatNum('top', e.target.value)}
								disabled={confirm !== '1' ? true : false}
							/>
							<InputGroup.Text>DAY</InputGroup.Text>
						</InputGroup>
					</Col>
				</Row>
				<Row className='mb-2 justify-content-center align-items-center'>
					<Col>PROVINCE</Col>:
					<Col>
						<Form.Select
							value={province}
							onChange={(e) => selectProvince(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						>
							<option value={'0'}>-</option>
							{dataProvince.map !== undefined &&
								dataProvince.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col>CITY</Col>:
					<Col>
						<Form.Select
							value={city}
							onChange={(e) => setCity(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						>
							<option value={'0'}>-</option>
							{dataCity.map !== undefined &&
								dataCity.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Row>
				<Row>
					<Col>CUSTOMER TYPE</Col>:
					<Col>
						<Form.Select
							value={custType}
							onChange={(e) => setCusttype(e.target.value)}
							disabled={confirm !== '1' ? true : false}
						>
							<option value={'0'}>-</option>
							{dataCusttype.map !== undefined &&
								dataCusttype.map((row) => (
									<option key={row.id} value={row.id}>
										{row.name}
									</option>
								))}
						</Form.Select>
					</Col>
					<Col></Col>
					<Col></Col>
				</Row>
				<h5 className='mt-5'>CUSTOMER IMAGE</h5>
				<ImageList sx={{ width: '100%', maxHeight: 500 }} cols={3} gap={10}>
					{custImg.map !== undefined ? (
						custImg.map((item) => (
							<ImageListItem key={item.id}>
								<img
									src={'https://www.mfis.my.id/1994/img/customer/' + item.img}
									alt={item.id}
									loading='lazy'
								/>
							</ImageListItem>
						))
					) : (
						<h6>There is no image</h6>
					)}
				</ImageList>
				<h5 className='mt-5'>DISCOUNT CUSTOMER</h5>
				<Table>
					<thead className='border-bottom border-3'>
						<tr>
							<th>#</th>
							<th>TYPE</th>
							<th>DISCOUNT</th>
						</tr>
					</thead>
					<tbody>
						{dataCDisc.map !== undefined &&
							dataCDisc.map((row, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{row.name}</td>
									<td>
										<InputGroup>
											<Form.Control
												value={row.disc}
												onChange={(e) =>
													setDisc('disccust', row.id, e.target.value)
												}
												disabled={confirm !== '1' ? true : false}
											/>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup>
									</td>
								</tr>
							))}
						{confirm === '1' && (
							<tr>
								<td></td>
								<td className='w-50'>
									<Form.Select
										value={discCust}
										onChange={(e) => setDisccust(e.target.value)}
									>
										<option value={'0'}>SELECT DISCOUNT</option>
										{dataDisctype.map !== undefined &&
											dataDisctype.map((row) => (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											))}
									</Form.Select>
								</td>
								<td className='w-50'>
									<Button
										variant='contained'
										color='error'
										startIcon={<AddCircleIcon />}
										onClick={() => addDisc('disccust')}
										fullWidth
									>
										Add Discount Customer
									</Button>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<h5 className='mt-5'>DISCOUNT ITEM TYPE</h5>
				<Table>
					<thead className='border-bottom border-3'>
						<tr>
							<th>#</th>
							<th>TYPE</th>
							<th>DISCOUNT</th>
						</tr>
					</thead>
					<tbody>
						{dataDiscitmtype.map !== undefined &&
							dataDiscitmtype.map((row, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{row.name}</td>
									<td>
										<InputGroup>
											<Form.Control
												value={row.disc}
												onChange={(e) =>
													setDisc('disctype', row.id, e.target.value)
												}
												disabled={confirm !== '1' ? true : false}
											/>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup>
									</td>
								</tr>
							))}
						{confirm === '1' && (
							<tr>
								<td></td>
								<td className='w-50'>
									<Form.Select
										value={discType}
										onChange={(e) => setDisctype(e.target.value)}
									>
										<option value={'0'}>SELECT TYPE</option>
										{dataItmtype.map !== undefined &&
											dataItmtype.map((row) => (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											))}
									</Form.Select>
								</td>
								<td className='w-50'>
									<Button
										variant='contained'
										color='error'
										fullWidth
										startIcon={<AddCircleIcon />}
										onClick={() => addDisc('disctype')}
									>
										Add Discount Item Type
									</Button>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<h5 className='mt-5'>DISCOUNT ITEM CATEGORY</h5>
				<Table>
					<thead className='border-bottom border-3'>
						<tr>
							<th>#</th>
							<th>TYPE</th>
							<th>DISCOUNT</th>
						</tr>
					</thead>
					<tbody>
						{dataDiscitmcat.map !== undefined &&
							dataDiscitmcat.map((row, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{row.name}</td>
									<td>
										<InputGroup>
											<Form.Control
												value={row.disc}
												onChange={(e) =>
													setDisc('disccat', row.id, e.target.value)
												}
												disabled={confirm !== '1' ? true : false}
											/>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup>
									</td>
								</tr>
							))}
						{confirm === '1' && (
							<tr>
								<td></td>
								<td className='w-50'>
									<Form.Select
										value={discCat}
										onChange={(e) => setDiscCat(e.target.value)}
									>
										<option value={'0'}>SELECT CATEGORY</option>
										{dataItmcat.map !== undefined &&
											dataItmcat.map((row) => (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											))}
									</Form.Select>
								</td>
								<td className='w-50'>
									<Button
										variant='contained'
										color='error'
										fullWidth
										startIcon={<AddCircleIcon />}
										onClick={() => addDisc('disccat')}
									>
										Add Discount Item Category
									</Button>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<h5 className='mt-5'>DISCOUNT ITEM</h5>
				<Table>
					<thead className='border-bottom border-3'>
						<tr>
							<th>#</th>
							<th>TYPE</th>
							<th>DISCOUNT</th>
						</tr>
					</thead>
					<tbody>
						{dataDiscitm.map !== undefined &&
							dataDiscitm.map((row, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{row.name}</td>
									<td>
										<InputGroup>
											<Form.Control
												value={row.disc}
												onChange={(e) =>
													setDisc('discitm', row.id, e.target.value)
												}
												disabled={confirm !== '1' ? true : false}
											/>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup>
									</td>
								</tr>
							))}
						{confirm === '1' && (
							<tr>
								<td></td>
								<td className='w-50'>
									<Form.Select
										value={discItm}
										onChange={(e) => setDiscitm(e.target.value)}
									>
										<option value={'0'}>SELECT ITEM</option>
										{dataItm.map !== undefined &&
											dataItm.map((row) => (
												<option key={row.id} value={row.id}>
													{row.name}
												</option>
											))}
									</Form.Select>
								</td>
								<td className='w-50'>
									<Button
										variant='contained'
										color='error'
										fullWidth
										startIcon={<AddCircleIcon />}
										onClick={() => addDisc('discitm')}
									>
										Add Discount Item
									</Button>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Modal.Body>
			<Modal.Footer>
				{confirm !== '2' && (
					<Box display={'flex'} gap={1}>
						<Button
							disabled={
								confirm === '0'
									? false
									: name === '' ||
									  pl === '0' ||
									  city === '0' ||
									  address === '' ||
									  phone === '' ||
									  top === '' ||
									  custType === '0' ||
									  cp === ''
									? true
									: false
							}
							startIcon={confirm === '0' && <ThumbUpIcon />}
							onClick={() => {
								confirm === '0'
									? setOpenConfirm({ open: true, data: '1' })
									: updateCust('1')
							}}
							variant='contained'
							color={confirm === '0' ? 'success' : 'error'}
						>
							{confirm === '0' ? 'Approve' : 'UPDATE CUSTOMER'}
						</Button>
						<Button
							disabled={
								confirm === '0'
									? false
									: dataCDisc > '0' ||
									  dataDiscitmtype > '0' ||
									  dataDiscitmcat > '0' ||
									  dataDiscitm > '0'
									? false
									: true
							}
							onClick={() => {
								confirm === '0'
									? setOpenConfirm({ open: true, data: '2' })
									: updateDisc()
							}}
							startIcon={confirm === '0' && <ThumbDownIcon />}
							variant='contained'
							color='error'
						>
							{confirm === '0' ? 'Reject' : 'UPDATE DISCOUNT'}
						</Button>
					</Box>
				)}

				{/* //*Confirm */}
				<Dialog
					open={openConfirm.open}
					onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
					fullWidth
					maxWidth='xs'
				>
					<DialogTitle>CONFIRMATION</DialogTitle>
					<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
						<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							color='error'
							onClick={() => updateCust(openConfirm.data)}
						>
							YES
						</Button>
						<Button
							color='error'
							onClick={() => setOpenConfirm({ ...openConfirm, open: false })}
						>
							NO
						</Button>
					</DialogActions>
				</Dialog>
			</Modal.Footer>
		</Modal>
	)
}

export default App
