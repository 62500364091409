import {
	Box,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import axios from 'axios'
import url from '../url'
import { useEffect, useState } from 'react'

export default function LogItem(props) {
	const [dataItemLog, setDataItemLog] = useState([])

	function getItemLog() {
		axios
			.get(url + '/item/getItemLog.php', {
				params: { itemId: props.data.logItem },
			})
			.then(function (response) {
				let result = response.data
				setDataItemLog(result)
			})
			.catch(function (error) {})
	}

	useEffect(() => {
		getItemLog()
	}, [])

	return (
		<Box>
			<Divider sx={{ marginY: 2, borderColor: 'red', borderWidth: 2 }} />

			<TableContainer component={Paper} sx={{ maxHeight: 450 }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Item
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Type
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Category
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Length
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Width
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Height
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Volume
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Amount
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Sheet per Piece
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Piece per Pack
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Pack per Box
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Sheet per Box
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Status
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Created By
							</TableCell>
							<TableCell
								sx={{
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								Created Date
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{/* //*If there is data */}
						{dataItemLog.length > 0 ? (
							dataItemLog.map((item) => (
								<TableRow key={item.id}>
									<TableCell sx={{ width: '100%' }}>{item.name}</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.item_type}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.item_category}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.length}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.width}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.height}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.volume}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.amount}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.sheet_pcs}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.pcs_pack}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.pack_box}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.sheet_box}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.status === '1' ? 'Active' : 'Inactive'}
									</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{item.user}
									</TableCell>
									<TableCell sx={{ textAlign: 'center', textWrap: 'nowrap' }}>
										{item.created_date}
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={14} sx={{ textAlign: 'center' }}>
									There is no data
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
