import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Snackbar,
	TextField,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import url from '../url'

export default function AddItem(props) {
	const [types, setTypes] = useState([])
	const [categories, setCategories] = useState([])
	const statuses = [
		{ id: 1, name: 'Active' },
		{ id: 0, name: 'Inactive' },
	]
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarData, setSnackbarData] = useState({
		type: 'Success',
		message: '',
	})
	const [openConfirm, setOpenConfirm] = useState(false)

	function getItemType() {
		axios.get(url + '/item/getItemType.php').then(function (response) {
			let result = response.data
			setTypes(result)
		})
	}

	function getItemCategory() {
		axios.get(url + '/item/getItemCategory.php').then(function (response) {
			let result = response.data
			setCategories(result)
		})
	}

	function onChangeType(value) {
		props.onSetHandling('formItem', { type: 'Type', value: value })
	}

	function onChangeCategory(value) {
		props.onSetHandling('formItem', { type: 'Category', value: value })
	}

	function onChangeStatus(value) {
		props.onSetHandling('formItem', { type: 'Status', value: value })
	}

	function onAddItem() {
		setOpenConfirm(false)

		let form = new FormData()
		form.append('code', props.data.formItem?.code)
		form.append('name', props.data.formItem?.name)
		form.append('type', props.data.formItem?.type.id)
		form.append('category', props.data.formItem?.category.id)
		form.append('length', props.data.formItem?.length)
		form.append('width', props.data.formItem?.width)
		form.append('height', props.data.formItem?.height)
		form.append('volume', props.data.formItem?.volume)
		form.append('amount', props.data.formItem?.amount)
		form.append('sheetPiece', props.data.formItem?.sheetPiece)
		form.append('piecePack', props.data.formItem?.piecePack)
		form.append('packBox', props.data.formItem?.packBox)
		form.append('sheetBox', props.data.formItem?.sheetBox)
		form.append('status', props.data.formItem?.status.id)
		form.append('userId', props.data.user.id)

		axios
			.post(url + '/item/addItem.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data

				setOpenSnackbar(true)
				setSnackbarData({ type: result.type, message: result.message })
			})
	}

	function onCloseSnackbar() {
		setOpenSnackbar(false)
	}

	useEffect(() => {
		getItemType()
		getItemCategory()
	}, [])

	return (
		<Box display={'flex'} flexDirection={'column'} gap={2}>
			<Divider sx={{ marginY: 2, borderColor: 'red', borderWidth: 2 }} />

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Item Code */}
				<TextField
					variant='outlined'
					label='Code'
					fullWidth
					value={props.data.formItem?.code}
					onChange={(e) => {
						// *Format Letter & Number
						const format = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/

						if (e.target.value === '' || format.test(e.target.value)) {
							props.onSetHandling('formItem', {
								type: 'Code',
								value: e.target.value,
							})
						} else {
							setOpenSnackbar(true)
							setSnackbarData({
								type: 'Error',
								message: 'Please Enter Valid Code',
							})
						}
					}}
				/>

				{/* //*Item Name */}
				<TextField
					variant='outlined'
					label='Item Name'
					fullWidth
					value={props.data.formItem?.name}
					onChange={(e) => {
						// *Format Letter & Number
						const format = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/

						if (e.target.value === '' || format.test(e.target.value)) {
							props.onSetHandling('formItem', {
								type: 'Name',
								value: e.target.value,
							})
						} else {
							setOpenSnackbar(true)
							setSnackbarData({
								type: 'Error',
								message: 'Please Enter Valid Name',
							})
						}
					}}
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Item Type */}
				<Autocomplete
					getOptionLabel={(types) => types.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={props.data.formItem?.type}
					options={types}
					onChange={(_, value) => onChangeType(value)}
					renderInput={(params) => (
						<TextField {...params} variant='outlined' label='Type' />
					)}
					fullWidth
				/>

				{/* //*Item Category */}
				<Autocomplete
					getOptionLabel={(categories) => categories.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={props.data.formItem?.category}
					options={categories}
					onChange={(_, value) => onChangeCategory(value)}
					renderInput={(params) => (
						<TextField {...params} variant='outlined' label='Category' />
					)}
					fullWidth
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Item Length */}
				<TextField
					variant='outlined'
					label='Length'
					fullWidth
					type='number'
					value={props.data.formItem?.length}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'Length',
							value: e.target.value,
						})
					}
				/>

				{/* //*Item Width */}
				<TextField
					variant='outlined'
					label='Width'
					fullWidth
					type='number'
					value={props.data.formItem?.width}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'Width',
							value: e.target.value,
						})
					}
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Item Height */}
				<TextField
					variant='outlined'
					label='Height'
					fullWidth
					type='number'
					value={props.data.formItem?.height}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'Height',
							value: e.target.value,
						})
					}
				/>

				{/* //*Item Volume */}
				<TextField
					variant='outlined'
					label='Volume'
					fullWidth
					type='number'
					value={props.data.formItem?.volume}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'Volume',
							value: e.target.value,
						})
					}
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Item Amount */}
				<TextField
					variant='outlined'
					label='Amount'
					fullWidth
					type='number'
					value={props.data.formItem?.amount}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'Amount',
							value: e.target.value,
						})
					}
				/>

				{/* //*Sheet per Piece */}
				<TextField
					variant='outlined'
					label='Sheet per Piece'
					fullWidth
					type='number'
					value={props.data.formItem?.sheetPiece}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'SheetPiece',
							value: e.target.value,
						})
					}
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Piece per Pack */}
				<TextField
					variant='outlined'
					label='Piece per Pack'
					fullWidth
					type='number'
					value={props.data.formItem?.piecePack}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'PiecePack',
							value: e.target.value,
						})
					}
				/>

				{/* //*Pack per Box */}
				<TextField
					variant='outlined'
					label='Pack per Box'
					fullWidth
					type='number'
					value={props.data.formItem?.packBox}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'PackBox',
							value: e.target.value,
						})
					}
				/>
			</Box>

			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Sheet per Box */}
				<TextField
					variant='outlined'
					label='Sheet per Box'
					value={props.data.formItem?.sheetBox}
					onChange={(e) =>
						props.onSetHandling('formItem', {
							type: 'SheetBox',
							value: e.target.value,
						})
					}
					fullWidth
					type='number'
				/>

				{/* //*Item Status */}
				<Autocomplete
					getOptionLabel={(statuses) => statuses.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={props.data.formItem?.status}
					options={statuses}
					onChange={(_, value) => onChangeStatus(value)}
					renderInput={(params) => (
						<TextField {...params} variant='outlined' label='Status' />
					)}
					fullWidth
				/>
			</Box>

			{/* //*Create Button */}
			<Button
				variant='contained'
				color='error'
				disabled={
					props.data.formItem?.name === '' ||
					props.data.formItem?.code === '' ||
					props.data.formItem?.type === null ||
					props.data.formItem?.category === null ||
					props.data.formItem?.length === '' ||
					props.data.formItem?.width === '' ||
					props.data.formItem?.height === '' ||
					props.data.formItem?.volume === '' ||
					props.data.formItem?.amount === '' ||
					props.data.formItem?.sheetPiece === '' ||
					props.data.formItem?.piecePack === '' ||
					props.data.formItem?.packBox === '' ||
					props.data.formItem?.sheetBox === '' ||
					props.data.formItem?.status === null
						? true
						: false
				}
				onClick={() => setOpenConfirm(true)}
			>
				Add Item
			</Button>

			{/* //*Alert */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={onCloseSnackbar}
			>
				<Alert
					onClose={onCloseSnackbar}
					severity={snackbarData?.type === 'Success' ? 'success' : 'error'}
				>
					{snackbarData?.message}
				</Alert>
			</Snackbar>

			{/* //*Confirm */}
			<Dialog
				open={openConfirm}
				onClose={() => setOpenConfirm(false)}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle>CONFIRMATION</DialogTitle>
				<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
					<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' color='error' onClick={() => onAddItem()}>
						YES
					</Button>
					<Button color='error' onClick={() => setOpenConfirm(false)}>
						NO
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
