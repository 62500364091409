import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";

export default function Item(props) {
  // MENU
  const menu = props.data.menu;
  // DO
  const delivery = props.data.do;
  // PL
  const pl = props.data.pl;
  // LOADING
  const [loading, setLoading] = useState(true);
  // DO DETAIL
  const getDODetail = (delivery) => {
    axios
      .get(url + "/delivery-order/getDODetail.php", {
        params: {
          do: delivery,
        },
      })
      .then(function (response) {
        let result = response.data;
        result = result.map((row) => {
          return {
            id: row.item_id,
            category: row.item_category,
            name: row.item,
            volume: row.item_volume,
            qty: Number(row.qty),
          };
        });
        setItmCart(result);
        itmSum(result);
        props.set("item", result);
      })
      .catch(function (error) {});
  };
  // PL DETAIL
  const getPLDetail = (pl) => {
    axios
      .get(url + "/delivery-order/getPLDetail.php", {
        params: {
          pl: pl,
        },
      })
      .then(function (response) {
        let result = response.data;
        result = result.map((row) => {
          return {
            id: row.item_id,
            category: row.item_category,
            name: row.item,
            volume: row.item_volume,
            qty: Number(row.qty),
          };
        });
        setItmCart(result);
        itmSum(result);
        props.set("item", result);
      })
      .catch(function (error) {});
  };
  // ITEM TOTAL
  const itmSum = (item) => {
    let volume = 0;
    let qty = 0;
    if (item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        volume = volume + Number(item[i].volume * item[i].qty);
        qty = qty + Number(item[i].qty);
      }
      setItmTotal({ volume: volume, qty: qty });
    } else {
      setItmTotal(null);
    }
  };
  // ITEM
  const [itmCart, setItmCart] = useState([]);
  const [itmTotal, setItmTotal] = useState(null);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (menu === 1) {
      if (delivery) {
        getDODetail(delivery.id);
      } else {
        setItmCart([]);
        setItmTotal(null);
        props.set("item");
      }
    } else {
      if (pl) {
        getPLDetail(pl.id);
      } else {
        setItmCart([]);
        setItmTotal(null);
        props.set("item");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, delivery, pl]);

  return (
    (menu === 2 || delivery) && (
      <Stack padding={2} spacing={1}>
        {/* LIST ITEM */}
        {itmCart.length > 0 ? (
          itmCart.map((row) => (
            <Paper key={row.id} sx={{ padding: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography flex={3} variant="body2">
                  {loading ? (
                    <Skeleton animation="wave" width="50%" />
                  ) : (
                    row.category
                  )}
                </Typography>
                <Typography flex={0.5} textAlign="center" variant="body2">
                  {loading ? <Skeleton animation="wave" /> : "VOLUME"}
                </Typography>
                <Typography flex={0.5} textAlign="center" variant="body2">
                  {loading ? <Skeleton animation="wave" /> : "QTY"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography flex={3}>
                  {loading ? (
                    <Skeleton animation="wave" width="50%" />
                  ) : (
                    row.name
                  )}
                </Typography>
                <Typography flex={0.5} textAlign="center">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `${Number(row.volume * row.qty).toFixed(3)} m³`
                  )}
                </Typography>
                <Typography flex={0.5} textAlign="center">
                  {loading ? <Skeleton animation="wave" /> : row.qty}
                </Typography>
              </Stack>
            </Paper>
          ))
        ) : (
          <Paper sx={{ padding: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography flex={3} variant="body2">
                {loading ? (
                  <Skeleton animation="wave" width="50%" />
                ) : (
                  "CATEGORY"
                )}
              </Typography>
              <Typography flex={0.5} textAlign="center" variant="body2">
                {loading ? <Skeleton animation="wave" /> : "VOLUME"}
              </Typography>
              <Typography flex={0.5} textAlign="center" variant="body2">
                {loading ? <Skeleton animation="wave" /> : "QTY"}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography flex={3}>
                {loading ? (
                  <Skeleton animation="wave" width="50%" />
                ) : (
                  "ITEM NAME"
                )}
              </Typography>
              <Typography flex={0.5} textAlign="center">
                {loading ? <Skeleton animation="wave" /> : "- m³"}
              </Typography>
              <Stack
                flex={0.5}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography>
                    {loading ? <Skeleton animation="wave" /> : "-"}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          padding={1}
          borderTop={1}
        >
          <Typography flex={3} fontWeight="bold">
            TOTAL
          </Typography>
          <Typography flex={0.5} textAlign="center" fontWeight="bold">
            {loading ? (
              <Skeleton animation="wave" />
            ) : itmTotal ? (
              `${itmTotal.volume.toFixed(3)} m³`
            ) : (
              "- m³"
            )}
          </Typography>
          <Typography flex={0.5} textAlign="center" fontWeight="bold">
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : itmTotal ? (
              itmTotal.qty
            ) : (
              "-"
            )}
          </Typography>
        </Stack>
      </Stack>
    )
  );
}
