import {
	Autocomplete,
	Box,
	Button,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import url from '../url'
import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'

export default function Form(props) {
	// MENU
	const menu = props.data.menu
	// SO
	const so = props.data.so
	// PICKLIST
	const pl = props.data.pl
	// RESET
	const reset = props.data.reset
	// DATE
	const date = new Date()
	const month = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const today = `${date.getDate()} ${
		month[date.getMonth()]
	} ${date.getFullYear()}`
	// CODE
	const [code, setCode] = useState(null)
	const getCode = () => {
		axios
			.get(url + '/picklist/code.php')
			.then(function (response) {
				let result = response.data
				handleCode(result)
			})
			.catch(function (error) {})
	}
	const handleCode = (code) => {
		let date = new Date()
		let dyear = date.getFullYear()
		let dmonth = date.getMonth() + 1
		let countsplit = code.substring(9, 13)
		let monthsplit = code.substring(7, 9)
		let count = Number(('000' + countsplit).slice(-4))
		let month = Number(('0' + monthsplit).slice(-2))
		if (dmonth === month) {
			count++
			count =
				'PL-' +
				String(dyear) +
				('0' + dmonth).slice(-2) +
				('000' + count).slice(-4)
		} else {
			count =
				'PL-' + String(dyear) + ('0' + dmonth).slice(-2) + '0001'.slice(-4)
		}
		setCode(count)
		props.set('code', count)
	}
	// LOADING
	const [loading, setLoading] = useState(true)
	// EXPEDITION
	const [dataExpedition, setDataExpedition] = useState([])
	const [expedition, setExpedition] = useState(null)
	const getExpeditions = () => {
		axios
			.get(url + '/picklist/getExpeditions.php')
			.then(function (response) {
				let result = response.data
				setDataExpedition(result)
			})
			.catch(function (error) {})
	}
	const getExpedition = (expedition) => {
		axios
			.get(url + '/picklist/getExpedition.php', {
				params: { expeditionid: expedition },
			})
			.then(function (response) {
				let result = response.data
				setExpedition(result)
				props.set('expedition', result)
			})
			.catch(function (error) {})
	}
	const handleExpedition = (data) => {
		if (data) {
			getExpedition(data.id)
			if (menu === 1) {
				getTotalVolume(pl.id, data.id)
			}
		} else {
			setExpedition(data)
			props.set('expedition', data)
		}
	}
	// RESET FORM
	const resetForm = () => {
		setTotalVolume(null)
		setExpedition(null)
		props.set('expedition', null)
	}
	// Total Volume
	const [totalVolume, setTotalVolume] = useState(null)
	const getTotalVolume = (pl, exp) => {
		axios
			.get(url + '/picklist/getTotalVolume.php', {
				params: { plid: pl, expid: exp },
			})
			.then(function (response) {
				let result = response.data
				setTotalVolume(result)
			})
			.catch(function (error) {})
	}
	// FORM
	const formCreate = [
		{
			id: 1,
			name: 'CODE',
			value:
				menu === 1 ? (
					<Stack direction='row' alignItems='center' justifyContent='right'>
						<Typography>{pl ? pl.code : '-'}</Typography>
						<CopyToClipboard text={pl ? pl.code : '-'}>
							<IconButton color='error'>
								<ContentCopyIcon />
							</IconButton>
						</CopyToClipboard>
					</Stack>
				) : (
					<Typography textAlign='right'>{code}</Typography>
				),
		},
		{
			id: 2,
			name: 'DATE',
			value: (
				<Typography textAlign='right'>
					{menu === 1 ? (pl ? pl.created_date : '-') : today}
				</Typography>
			),
		},
		{
			id: 3,
			name: 'CUSTOMER',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.customer
							: '-'
						: so
						? so.customer
							? so.customer
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 4,
			name: 'CITY',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.city
							: '-'
						: so
						? so.city
							? so.city
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 5,
			name: 'PRICELIST',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.pricelist
							: '-'
						: so
						? so.pricelist
							? so.pricelist
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 6,
			name: 'CUSTOMER TYPE',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.customer_type
							: '-'
						: so
						? so.customer_type
							? so.customer_type
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 7,
			name: 'PHONE',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.phone !== ''
								? pl.phone
								: '-'
							: '-'
						: so
						? so.phone !== ''
							? so.phone
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 8,
			name: 'SHIPMENT',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.shipment
							: '-'
						: so
						? so.shipment
							? so.shipment
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 9,
			name: 'STATUS',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.status === '0'
								? 'OPEN'
								: pl.status === '1'
								? 'COMPLETE'
								: 'CANCEL'
							: '-'
						: 'OPEN'}
				</Typography>
			),
		},
		{
			id: 10,
			name: 'ITEM TYPE',
			value: (
				<Typography textAlign='right'>
					{menu === 1
						? pl
							? pl.item_type
							: '-'
						: so
						? so.item_type
							? so.item_type
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 11,
			name: 'EXPEDITION CODE',
			value:
				pl && pl.status !== '0' ? (
					<Typography textAlign='right'>{pl.expedition_code}</Typography>
				) : (
					<Stack direction={'column'} spacing={1}>
						<Autocomplete
							getOptionLabel={(dataExpedition) =>
								`${dataExpedition.code} (${dataExpedition.expedition_type})`
							}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={expedition}
							options={dataExpedition}
							onChange={(_, value) => handleExpedition(value)}
							renderInput={(params) => (
								<TextField {...params} variant='standard' />
							)}
						/>
						<Button
							variant='outlined'
							color='error'
							startIcon={<AddIcon />}
							onClick={() => props.set('createExpedition', true)}
						>
							Create Expedition
						</Button>
					</Stack>
				),
		},
		{
			id: 12,
			name: 'EXPEDITION TYPE',
			value: (
				<Typography textAlign='right'>
					{expedition
						? expedition.expedition_type
							? expedition.expedition_type
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 13,
			name: 'EXPEDITION VOLUME',
			value: (
				<Typography textAlign='right'>
					{expedition
						? expedition.expedition_type_volume
							? pl && pl.status !== '0'
								? `${Number(expedition.expedition_type_volume).toFixed(3)} m³`
								: `${Number(
										Number(totalVolume) +
											Number(
												expedition.expedition_type_volume -
													expedition.total_volume
											)
								  ).toFixed(3)} m³`
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 14,
			name: 'EXPEDITION',
			value: (
				<Typography textAlign='right'>
					{expedition ? (expedition.name ? expedition.name : '-') : '-'}
				</Typography>
			),
		},
		{
			id: 15,
			name: 'PLATE NUMBER',
			value: (
				<Typography textAlign='right'>
					{expedition
						? expedition.plate_number
							? expedition.plate_number
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 16,
			name: 'DRIVER',
			value: (
				<Typography textAlign='right'>
					{expedition ? (expedition.driver ? expedition.driver : '-') : '-'}
				</Typography>
			),
		},
		{
			id: 17,
			name: 'DRIVER PHONE',
			value: (
				<Typography textAlign='right'>
					{expedition
						? expedition.driver_phone
							? expedition.driver_phone
							: '-'
						: '-'}
				</Typography>
			),
		},
		{
			id: 18,
			name: 'SO CODE',
			value: (
				<Typography textAlign='right'>
					{pl ? pl.sales_order_code : so ? so.code : '-'}
				</Typography>
			),
		},
		{
			id: 19,
			name: 'SALES',
			value: (
				<Typography textAlign='right'>
					{pl ? pl.sales : so ? so.sales : '-'}
				</Typography>
			),
		},
	]

	useEffect(() => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 1000)
		if (menu === 1) {
			getExpeditions()
			if (pl && pl.expedition_id !== '0') {
				getTotalVolume(pl.id, pl.expedition_id)
				getExpedition(pl.expedition_id)
			} else {
				resetForm()
			}
		} else {
			resetForm()
			getCode()
			getExpeditions()
			props.set('pl', null)
			if (reset) {
				resetForm()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu, so, reset, pl])

	return (
		(menu === 2 || pl) && (
			<>
				{/* FORM */}
				<Grid container padding={2} alignItems='center' spacing={2}>
					{formCreate.map((row) => (
						<Grid item xs={12} md={6} key={row.id}>
							{loading ? (
								<Skeleton animation='wave' />
							) : (
								<Stack direction='row' alignItems='center'>
									<Typography flex={1}>{row.name}</Typography>
									<Typography flex={1} textAlign='center'>
										:
									</Typography>
									<Box flex={1}>{row.value}</Box>
								</Stack>
							)}
						</Grid>
					))}
				</Grid>
			</>
		)
	)
}
