import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function Item(props) {
  // MENU
  const menu = props.data.menu;
  // SO
  const so = props.data.so;
  // PL
  const pl = props.data.pl;
  // LOADING
  const [loading, setLoading] = useState(true);
  // SO DETAIL
  const getSODetail = (so) => {
    axios
      .get(url + "/picklist/getSODetail.php", {
        params: {
          so: so,
        },
      })
      .then(function (response) {
        let result = response.data;
        result = result.map((row) => {
          return {
            id: row.item_id,
            category: row.item_category,
            name: row.item,
            volume: row.item_volume,
            orderqty: Number(row.sum_qty),
            qty: Number(row.sum_qty),
            expired: "",
          };
        });
        if (menu === 1) {
          setCartTemp(result);
        } else {
          setItmSO(result);
          setItmCart(result);
          itmSum(result);
          props.set("item", result);
        }
      })
      .catch(function (error) {});
  };
  // PL DETAIL
  const getPLDetail = (pl) => {
    axios
      .get(url + "/picklist/getPLDetail.php", {
        params: {
          pl: pl,
        },
      })
      .then(function (response) {
        let result = response.data;
        let result1 = response.data;
        result = result.map((row) => {
          return {
            id: row.item_id,
            category: row.item_category,
            name: row.item,
            volume: row.item_volume,
            orderqty: Number(row.order_qty),
            qty: Number(row.qty),
            expired: dayjs(row.expired_date),
          };
        });
        result1 = result1.map((row) => {
          return {
            id: row.item_id,
            category: row.item_category,
            name: row.item,
            volume: row.item_volume,
            orderqty: Number(row.order_qty),
            qty: Number(row.qty),
            expired: row.expired_date,
          };
        });
        setItmPL(result);
        setItmCart(result);
        itmSum(result);
        props.set("item", result1);
      })
      .catch(function (error) {});
  };
  // ITEM TOTAL
  const itmSum = (item) => {
    let volume = 0;
    let qty = 0;
    let order = 0;
    if (item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        volume = volume + Number(item[i].volume * item[i].qty);
        order = order + Number(item[i].orderqty);
        qty = qty + Number(item[i].qty);
      }
      setItmTotal({ volume: volume, orderqty: order, qty: qty });
    } else {
      setItmTotal(null);
    }
  };
  // RELOAD ITEM
  const reloadItm = () => {
    if (menu === 2) {
      if (so) {
        getSODetail(so.id);
      }
    } else {
      if (pl) {
        getPLDetail(pl.id);
      }
    }
  };
  // ITEM
  const [itmCart, setItmCart] = useState([]);
  const [itmTotal, setItmTotal] = useState(null);
  const [itmSO, setItmSO] = useState([]);
  const [itmPL, setItmPL] = useState([]);
  const [cartTemp, setCartTemp] = useState([]);
  // REMOVE ITEM
  const removeItm = (id) => {
    setItmCart(itmCart.filter((a) => a.id !== id));
    itmSum(itmCart.filter((a) => a.id !== id));
    props.set(
      "item",
      itmCart.filter((a) => a.id !== id)
    );
  };
  // ITEM QTY
  const handleInputQty = (id, type, qty) => {
    let format = "0123456789";
    let number = "";
    // Checking Format
    for (let i = 0; i < qty.length; i++) {
      if (format.indexOf(qty[i]) > -1) {
        number = number + qty[i];
      } else {
        props.set("alert", {
          type: "error",
          message: "PLEASE INPUT ONLY NUMBER",
          error: true,
        });
      }
    }
    let qtyTemp = 0;
    if (menu === 1) {
      if (cartTemp.length > 0) {
        for (let i = 0; i < cartTemp.length; i++) {
          if (cartTemp[i].id === id) {
            qtyTemp = cartTemp[i].qty + itmPL[i].qty;
          }
        }
      } else {
        for (let i = 0; i < itmPL.length; i++) {
          if (itmPL[i].id === id) {
            qtyTemp = itmPL[i].qty;
          }
        }
      }
    } else {
      for (let i = 0; i < itmSO.length; i++) {
        if (itmSO[i].id === id) {
          qtyTemp = itmSO[i].qty;
        }
      }
    }
    let sum = itmCart.map((row) => {
      if (row.id === id) {
        if (number <= qtyTemp) {
          if (type === "qty") {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: Number(number),
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          } else {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: Number(number),
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          }
        } else {
          props.set("alert", {
            type: "error",
            message: "CAN'T MORE THAN SO QTY",
            error: true,
          });
          return {
            id: row.id,
            category: row.category,
            name: row.name,
            volume: row.volume,
            orderqty: row.orderqty,
            qty: row.qty,
            expired: row.expired ? dayjs(row.expired).format("YYYY-MM-DD") : "",
          };
        }
      } else {
        return {
          id: row.id,
          category: row.category,
          name: row.name,
          volume: row.volume,
          orderqty: row.orderqty,
          qty: row.qty,
          expired: row.expired ? dayjs(row.expired).format("YYYY-MM-DD") : "",
        };
      }
    });
    setItmCart(sum);
    itmSum(sum);
    props.set("item", sum);
  };
  const handleMinQty = (id, type) => {
    let sum = itmCart.map((row) => {
      if (row.id === id) {
        if (type === "qty") {
          if (row.qty > 0) {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: Number(row.qty - 1),
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          } else {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          }
        } else {
          if (row.orderqty > 0) {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: Number(row.orderqty - 1),
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          } else {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          }
        }
      } else {
        return {
          id: row.id,
          category: row.category,
          name: row.name,
          volume: row.volume,
          orderqty: row.orderqty,
          qty: row.qty,
          expired: row.expired ? dayjs(row.expired).format("YYYY-MM-DD") : "",
        };
      }
    });
    setItmCart(sum);
    itmSum(sum);
    props.set("item", sum);
  };
  const handleMaxQty = (id, type) => {
    let qtyTemp = 0;
    if (menu === 1) {
      if (cartTemp.length > 0) {
        for (let i = 0; i < cartTemp.length; i++) {
          if (cartTemp[i].id === id) {
            qtyTemp = cartTemp[i].qty + itmPL[i].qty;
          }
        }
      } else {
        for (let i = 0; i < itmPL.length; i++) {
          if (itmPL[i].id === id) {
            qtyTemp = itmPL[i].qty;
          }
        }
      }
    } else {
      for (let i = 0; i < itmSO.length; i++) {
        if (itmSO[i].id === id) {
          qtyTemp = itmSO[i].qty;
        }
      }
    }
    let sum = itmCart.map((row) => {
      if (row.id === id) {
        if (type === "qty") {
          if (row.qty < qtyTemp) {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: Number(row.qty + 1),
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          } else {
            props.set("alert", {
              type: "error",
              message: "CAN'T MORE THAN SO QTY",
              error: true,
            });
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          }
        } else {
          if (row.orderqty < qtyTemp) {
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: Number(row.orderqty + 1),
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          } else {
            props.set("alert", {
              type: "error",
              message: "CAN'T MORE THAN SO QTY",
              error: true,
            });
            return {
              id: row.id,
              category: row.category,
              name: row.name,
              volume: row.volume,
              orderqty: row.orderqty,
              qty: row.qty,
              expired: row.expired
                ? dayjs(row.expired).format("YYYY-MM-DD")
                : "",
            };
          }
        }
      } else {
        return {
          id: row.id,
          category: row.category,
          name: row.name,
          volume: row.volume,
          orderqty: row.orderqty,
          qty: row.qty,
          expired: row.expired ? dayjs(row.expired).format("YYYY-MM-DD") : "",
        };
      }
    });
    setItmCart(sum);
    itmSum(sum);
    props.set("item", sum);
  };
  const expiredItem = (item, date) => {
    let exp;
    if (date) {
      exp = date.format("YYYY-MM-DD");
    } else {
      exp = "";
    }
    let result = itmCart.map((row) => {
      if (row.id === item) {
        return {
          id: row.id,
          category: row.category,
          name: row.name,
          volume: row.volume,
          orderqty: row.orderqty,
          qty: row.qty,
          expired: exp,
        };
      } else {
        return {
          id: row.id,
          category: row.category,
          name: row.name,
          volume: row.volume,
          orderqty: row.orderqty,
          qty: row.qty,
          expired: row.expired ? dayjs(row.expired).format("YYYY-MM-DD") : "",
        };
      }
    });
    setItmCart(result);
    itmSum(result);
    props.set("item", result);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (menu === 2) {
      if (so) {
        getSODetail(so.id);
      } else {
        setCartTemp([]);
        setItmSO([]);
        setItmCart([]);
        setItmTotal(null);
        props.set("item");
      }
    } else {
      if (pl) {
        getSODetail(pl.sales_order_id);
        getPLDetail(pl.id);
      } else {
        setCartTemp([]);
        setItmPL([]);
        setItmCart([]);
        setItmTotal(null);
        props.set("item");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, so, pl]);

  return (
    (menu === 2 || pl) && (
      <Stack padding={2} spacing={1}>
        {/* RELOAD ITEM */}
        {menu === 2 && (
          <Box>
            <Button color="error" onClick={() => reloadItm()}>
              <RefreshIcon sx={{ height: 15 }} />
              <Typography fontSize={12}>RELOAD ITEM</Typography>
            </Button>
          </Box>
        )}
        {/* LIST ITEM */}
        {itmCart.length > 0 ? (
          itmCart.map((row) => (
            <Paper key={row.id} sx={{ padding: 1 }}>
              {/* Row 1 */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography flex={2} variant="body2">
                  {loading ? (
                    <Skeleton animation="wave" width="50%" />
                  ) : (
                    row.category
                  )}
                </Typography>
                <Typography flex={0.5} textAlign="center" variant="body2">
                  {loading ? <Skeleton animation="wave" /> : "VOLUME"}
                </Typography>
                <Typography flex={0.5} textAlign="center" variant="body2">
                  {loading ? <Skeleton animation="wave" /> : "ORDER QTY"}
                </Typography>
                <Typography flex={0.5} textAlign="center" variant="body2">
                  {loading ? <Skeleton animation="wave" /> : "QTY"}
                </Typography>
                {menu === 2 && (
                  <Typography flex={0.5} textAlign="center" variant="body2">
                    {loading ? <Skeleton animation="wave" /> : "DELETE"}
                  </Typography>
                )}
              </Stack>
              {/* Row 2 */}
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography flex={2}>
                  {loading ? (
                    <Skeleton animation="wave" width="50%" />
                  ) : (
                    row.name
                  )}
                </Typography>
                <Typography flex={0.5} textAlign="center">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `${Number(row.volume * row.qty).toFixed(3)} m³`
                  )}
                </Typography>
                {/* Order Qty */}
                {menu === 1 ? (
                  <Typography flex={0.5} textAlign="center">
                    {loading ? <Skeleton animation="wave" /> : row.orderqty}
                  </Typography>
                ) : (
                  <Stack
                    flex={0.5}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={25}
                        height={25}
                      />
                    ) : (
                      <IconButton onClick={() => handleMinQty(row.id, "order")}>
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                    {loading ? (
                      <Skeleton animation="wave" width="50%" />
                    ) : (
                      <TextField
                        sx={{ textAlignLast: "center" }}
                        variant="standard"
                        value={row.orderqty}
                        onChange={(e) =>
                          handleInputQty(row.id, "order", e.target.value)
                        }
                      />
                    )}
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={25}
                        height={25}
                      />
                    ) : (
                      <IconButton onClick={() => handleMaxQty(row.id, "order")}>
                        <AddCircleIcon />
                      </IconButton>
                    )}
                  </Stack>
                )}
                {/* Qty */}
                {pl && pl.status !== "0" ? (
                  <Typography flex={0.5} textAlign="center">
                    {loading ? <Skeleton animation="wave" /> : row.qty}
                  </Typography>
                ) : (
                  <Stack
                    flex={0.5}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={25}
                        height={25}
                      />
                    ) : (
                      <IconButton onClick={() => handleMinQty(row.id, "qty")}>
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                    {loading ? (
                      <Skeleton animation="wave" width="50%" />
                    ) : (
                      <TextField
                        sx={{ textAlignLast: "center" }}
                        variant="standard"
                        value={row.qty}
                        onChange={(e) =>
                          handleInputQty(row.id, "qty", e.target.value)
                        }
                      />
                    )}
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={25}
                        height={25}
                      />
                    ) : (
                      <IconButton onClick={() => handleMaxQty(row.id, "qty")}>
                        <AddCircleIcon />
                      </IconButton>
                    )}
                  </Stack>
                )}
                {menu === 2 && (
                  <Stack
                    flex={0.5}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={25}
                        height={25}
                      />
                    ) : (
                      <IconButton onClick={() => removeItm(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Stack>
              {/* Row 3 */}
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ marginTop: 2 }}
              >
                {menu === 2 || (pl && pl.status === "0") ? (
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Expired Date"
                        format="MMM YYYY"
                        views={["year", "month"]}
                        value={row.expired}
                        onChange={(value) => expiredItem(row.id, value)}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <Typography textAlign="center" fontWeight="bold">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : row.expired ? (
                      dayjs(row.expired).format("MMM YYYY")
                    ) : (
                      ""
                    )}
                  </Typography>
                )}
              </Stack>
            </Paper>
          ))
        ) : (
          <Paper sx={{ padding: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography flex={2} variant="body2">
                {loading ? (
                  <Skeleton animation="wave" width="50%" />
                ) : (
                  "CATEGORY"
                )}
              </Typography>
              <Typography flex={0.5} textAlign="center" variant="body2">
                {loading ? <Skeleton animation="wave" /> : "VOLUME"}
              </Typography>
              <Typography flex={0.5} textAlign="center" variant="body2">
                {loading ? <Skeleton animation="wave" /> : "ORDER QTY"}
              </Typography>
              <Typography flex={0.5} textAlign="center" variant="body2">
                {loading ? <Skeleton animation="wave" /> : "QTY"}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography flex={2}>
                {loading ? (
                  <Skeleton animation="wave" width="50%" />
                ) : (
                  "ITEM NAME"
                )}
              </Typography>
              <Typography flex={0.5} textAlign="center">
                {loading ? <Skeleton animation="wave" /> : "- m³"}
              </Typography>
              <Stack
                flex={0.5}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography>
                    {loading ? <Skeleton animation="wave" /> : "-"}
                  </Typography>
                )}
              </Stack>
              <Stack
                flex={0.5}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography>
                    {loading ? <Skeleton animation="wave" /> : "-"}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          padding={1}
          borderTop={1}
        >
          <Typography flex={2} fontWeight="bold">
            TOTAL
          </Typography>
          <Typography flex={0.5} textAlign="center" fontWeight="bold">
            {loading ? (
              <Skeleton animation="wave" />
            ) : itmTotal ? (
              `${itmTotal.volume.toFixed(3)} m³`
            ) : (
              "- m³"
            )}
          </Typography>
          <Typography flex={0.5} textAlign="center" fontWeight="bold">
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : itmTotal ? (
              itmTotal.orderqty
            ) : (
              "-"
            )}
          </Typography>
          <Typography flex={0.5} textAlign="center" fontWeight="bold">
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : itmTotal ? (
              itmTotal.qty
            ) : (
              "-"
            )}
          </Typography>
          {itmTotal && menu === 2 && <Typography flex={0.5}></Typography>}
        </Stack>
      </Stack>
    )
  );
}
