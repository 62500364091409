import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import dayjs from "dayjs";

export default function Filter(props) {
  // USER
  const user = props.data.user;
  // MENU
  const menu = props.data.menu;
  // RESET
  const reset = props.data.reset;
  // LOADING
  const [loading, setLoading] = useState(true);
  // CODE
  const [code, setCode] = useState("");
  const handleCode = (code) => {
    setCode(code);
    search(code, status.value, dateFrom, dateTo, user.id);
  };
  // STATUS
  const dataStatus = [
    { id: 1, name: "OPEN", value: "0" },
    { id: 2, name: "COMPLETE", value: "1" },
    { id: 3, name: "CANCEL", value: "2" },
  ];
  const [status, setStatus] = useState({ id: 1, name: "OPEN", value: "0" });
  const handleStatus = (status) => {
    if (status) {
      setStatus(status);
      search(code, status.value, dateFrom, dateTo, user.id);
    }
  };
  // DATE
  const date = new Date();
  const year = String(date.getFullYear());
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const today = year + "-" + month + "-" + day;
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState(dayjs(today));
  const handleDate = (type, date) => {
    let result;
    if (date) {
      result = date.format("YYYY-MM-DD");
    } else {
      result = "";
    }
    if (type === "datefrom") {
      setDateFrom(result);
      search(code, status.value, result, dateTo, user.id);
    }
    if (type === "dateto") {
      setDateTo(result);
      search(code, status.value, dateFrom, result, user.id);
    }
  };
  // SEARCH
  const search = (code, status, datefrom, dateto, user) => {
    axios
      .get(url + "/expedition/search.php", {
        params: {
          code: code,
          status: status,
          datefrom: datefrom,
          dateto: dateto,
          user: user,
        },
      })
      .then(function (response) {
        let result = response.data;
        props.set("expeditions", result);
        props.set("reset", false);
      })
      .catch(function (error) {});
  };
  // FILTER
  const filterSearch = [
    {
      id: 1,
      name: "CODE",
      value: (
        <TextField
          fullWidth
          variant="standard"
          value={code}
          onChange={(e) => handleCode(e.target.value)}
        />
      ),
    },
    {
      id: 2,
      name: "STATUS",
      value: (
        <Autocomplete
          getOptionLabel={(dataStatus) => dataStatus.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={status}
          options={dataStatus}
          onChange={(_, value) => handleStatus(value)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      id: 3,
      name: "DATE FROM",
      value: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateFrom}
            onChange={(value) => handleDate("datefrom", value)}
          />
        </LocalizationProvider>
      ),
    },
    {
      id: 4,
      name: "DATE TO",
      value: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateTo}
            onChange={(value) => handleDate("dateto", value)}
          />
        </LocalizationProvider>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (reset) {
      search(code, status.value, dateFrom, dateTo, user.id);
    } else {
      search(code, status.value, dateFrom, dateTo, user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Grid
      container
      padding={2}
      spacing={2}
      alignItems="center"
      borderBottom={2}
    >
      {menu === 1 &&
        filterSearch.map((row) => (
          <Grid item xs={12} md={6} key={row.id}>
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Stack direction="row" alignItems="center">
                <Typography flex={1}>{row.name}</Typography>
                <Typography flex={1} textAlign="center">
                  :
                </Typography>
                <Box flex={1}>{row.value}</Box>
              </Stack>
            )}
          </Grid>
        ))}
    </Grid>
  );
}
