import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";

export default function Form(props) {
  // MENU
  const menu = props.data.menu;
  // DELIVERY
  const delivery = props.data.do;
  // PICKLIST
  const pl = props.data.pl;
  // RESET
  const reset = props.data.reset;
  // DATE
  const date = new Date();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const today = `${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;
  // CODE
  const [code, setCode] = useState(null);
  const getCode = () => {
    axios
      .get(url + "/delivery-order/code.php")
      .then(function (response) {
        let result = response.data;
        handleCode(result);
      })
      .catch(function (error) {});
  };
  const handleCode = (code) => {
    let date = new Date();
    let dyear = date.getFullYear();
    let dmonth = date.getMonth() + 1;
    let countsplit = code.substring(9, 13);
    let monthsplit = code.substring(7, 9);
    let count = Number(("000" + countsplit).slice(-4));
    let month = Number(("0" + monthsplit).slice(-2));
    if (dmonth === month) {
      count++;
      count =
        "DO-" +
        String(dyear) +
        ("0" + dmonth).slice(-2) +
        ("000" + count).slice(-4);
    } else {
      count =
        "DO-" + String(dyear) + ("0" + dmonth).slice(-2) + "0001".slice(-4);
    }
    setCode(count);
    props.set("code", count);
  };
  // EXPEDITION
  const [expedition, setExpedition] = useState(null);
  const getExpedition = (expeditionid) => {
    axios
      .get(url + "/delivery-order/getExpedition.php", {
        params: { expeditionid: expeditionid },
      })
      .then(function (response) {
        let result = response.data;
        setExpedition(result);
        props.set("expedition", result);
      })
      .catch(function (error) {});
  };
  // LOADING
  const [loading, setLoading] = useState(true);
  // FORM
  const formCreate = [
    {
      id: 1,
      name: "CODE",
      value:
        menu === 1 ? (
          <Stack direction="row" alignItems="center" justifyContent="right">
            <Typography>{delivery ? delivery.code : "-"}</Typography>
            <CopyToClipboard text={delivery ? delivery.code : "-"}>
              <IconButton color="error">
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        ) : (
          <Typography textAlign="right">{code}</Typography>
        ),
    },
    {
      id: 2,
      name: "DATE",
      value: (
        <Typography textAlign="right">
          {menu === 1 ? (delivery ? delivery.created_date : "-") : today}
        </Typography>
      ),
    },
    {
      id: 3,
      name: "CUSTOMER",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.customer
              : "-"
            : pl
            ? pl.customer
              ? pl.customer
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 4,
      name: "CITY",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.city
              : "-"
            : pl
            ? pl.city
              ? pl.city
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 5,
      name: "PRICELIST",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.pricelist
              : "-"
            : pl
            ? pl.pricelist
              ? pl.pricelist
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 6,
      name: "CUSTOMER TYPE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.customer_type
              : "-"
            : pl
            ? pl.customer_type
              ? pl.customer_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 7,
      name: "PHONE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.phone !== ""
                ? delivery.phone
                : "-"
              : "-"
            : pl
            ? pl.phone !== ""
              ? pl.phone
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 8,
      name: "SHIPMENT",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.shipment
              : "-"
            : pl
            ? pl.shipment
              ? pl.shipment
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 9,
      name: "STATUS",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.status === "0"
                ? "OPEN"
                : delivery.status === "1"
                ? "COMPLETE"
                : "CANCEL"
              : "-"
            : "OPEN"}
        </Typography>
      ),
    },
    {
      id: 10,
      name: "ITEM TYPE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.item_type
              : "-"
            : pl
            ? pl.item_type
              ? pl.item_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 11,
      name: "EXPEDITION CODE",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.code ? expedition.code : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 13,
      name: "EXPEDITION TYPE",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.expedition_type
              ? expedition.expedition_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 14,
      name: "EXPEDITION VOLUME",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.expedition_type_volume
              ? `${Number(expedition.expedition_type_volume).toFixed(3)} m³`
              : "- m³"
            : "- m³"}
        </Typography>
      ),
    },
    {
      id: 15,
      name: "EXPEDITION",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.name ? expedition.name : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 16,
      name: "PLATE NUMBER",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.plate_number
              ? expedition.plate_number
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 17,
      name: "DRIVER",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.driver ? expedition.driver : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 18,
      name: "DRIVER PHONE",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.driver_phone
              ? expedition.driver_phone
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 19,
      name: "PL CODE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? delivery
              ? delivery.picklist_code
              : "-"
            : pl
            ? pl.code
            : "-"}
        </Typography>
      ),
    },
    {
      id: 20,
      name: "SALES",
      value: (
        <Typography textAlign="right">
          {delivery ? delivery.sales : pl ? pl.sales : "-"}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (menu === 2) {
      getCode();
      props.set("do", null);
      if (reset) {
        setExpedition(null);
      } else {
        if (pl) {
          getExpedition(pl.expedition_id);
        } else {
          setExpedition(null);
        }
      }
    } else {
      if (delivery) {
        getExpedition(delivery.expedition_id);
      } else {
        setExpedition(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, delivery, pl, reset]);

  return (
    (menu === 2 || delivery) && (
      <>
        {/* FORM */}
        <Grid container padding={2} alignItems="center" spacing={2}>
          {formCreate.map((row) => (
            <Grid item xs={12} md={6} key={row.id}>
              {loading ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack direction="row" alignItems="center">
                  <Typography flex={1}>{row.name}</Typography>
                  <Typography flex={1} textAlign="center">
                    :
                  </Typography>
                  <Box flex={1}>{row.value}</Box>
                </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
}
