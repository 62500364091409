import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import url from "../url";
import { useEffect, useState } from "react";

export default function Submit(props) {
  // MENU
  const menu = props.data.menu;
  // USER
  const user = props.data.user;
  // ITEM
  const item = props.data.item;
  // CODE
  const code = props.data.code;
  // INVOICE
  const invoice = props.data.invoice;
  // DELIVERY
  const delivery = props.data.do;
  // INVOICE TYPE
  const type = props.data.type;
  // REASON
  const [dataReason, setDataReason] = useState([]);
  const [reason, setReason] = useState(null);
  const getReason = () => {
    axios
      .get(url + "/invoice/getReason.php")
      .then(function (response) {
        let result = response.data;
        setDataReason(result);
      })
      .catch(function (error) {});
  };
  // CREATE
  const postCreate = (form) => {
    axios
      .post(url + "/invoice/create.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          props.set("reset", true);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const create = () => {
    let form = new FormData();
    form.append("user", user.id);
    form.append("doid", delivery.id);
    form.append("docode", delivery.code);
    form.append("code", code);
    form.append("custid", delivery.customer_id);
    form.append("custcode", delivery.customer_code);
    form.append("customer", delivery.customer);
    form.append("cityid", delivery.city_id);
    form.append("city", delivery.city);
    form.append("pricelistid", delivery.pricelist_id);
    form.append("pricelist", delivery.pricelist);
    form.append("custtypeid", delivery.customer_type_id);
    form.append("custtype", delivery.customer_type);
    form.append("itmtypeid", delivery.item_type_id);
    form.append("itmtype", delivery.item_type);
    form.append("phone", delivery.phone);
    form.append("shipment", delivery.shipment);
    form.append("expid", delivery.expedition_id);
    form.append("expcode", delivery.expedition_code);
    form.append("salesid", delivery.sales_id);
    form.append("sales", delivery.sales);
    form.append("type", type.value);
    item.map((row) => {
      form.append("itmid[]", row.id);
      form.append("itmname[]", row.name);
      form.append("itmcat[]", row.category);
      form.append("itmvol[]", row.volume);
      form.append("itmqty[]", row.qty);
      return {};
    });
    postCreate(form);
  };
  // CANCEL
  const postCancel = (form) => {
    axios
      .post(url + "/invoice/cancel.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          setReason(null);
          props.set("reset", true);
          props.set("invoice", null);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const cancel = () => {
    let form = new FormData();
    form.append("user", user.id);
    form.append("invoiceid", invoice.id);
    form.append("reason", reason.name);
    postCancel(form);
  };
  // SEARCH BACK
  const backSearch = () => {
    props.set("back");
    props.set("reset", true);
  };

  useEffect(() => {
    if (menu === 1) {
      getReason();
    } else {
      setReason(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, invoice, delivery, type]);

  return (
    (menu === 2 || invoice) && (
      <>
        <Stack
          direction="row"
          padding={2}
          spacing={1}
          justifyContent={menu === 2 ? "right" : "space-between"}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="error"
            disabled={
              menu === 1
                ? false
                : delivery && type && item && item.length > 0
                ? false
                : true
            }
            onClick={() => (menu === 1 ? backSearch() : create())}
          >
            {menu === 1 ? "BACK" : "CREATE"}
          </Button>
          {menu === 1 && invoice.status === "0" && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Autocomplete
                sx={{ width: 200 }}
                getOptionLabel={(dataReason) => dataReason.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={reason}
                options={dataReason}
                onChange={(_, value) => setReason(value)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              <Button
                variant="contained"
                color="error"
                disabled={reason ? false : true}
                onClick={() => cancel()}
              >
                CANCEL
              </Button>
            </Stack>
          )}
        </Stack>
      </>
    )
  );
}
