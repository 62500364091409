import {
	Button,
	Skeleton,
	Stack,
	Typography,
	Alert,
	Snackbar,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Filter from './filter'
import Tables from './tables'
import Form from './form'
import Item from './item'
import Submit from './submit'
import CreateExpedition from './createExpedition'

export default function Picklist() {
	// NAVIGATION
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user
	const soState = location.state.so
	// MENU
	const [menu, setMenu] = useState(2)
	// LOADING
	const [loading, setLoading] = useState(true)
	// PICKLIST
	const [dataPL, setDataPL] = useState([])
	const [pl, setPL] = useState(null)
	// CODE
	const [code, setCode] = useState(null)
	// SO
	const [so, setSO] = useState(null)
	// ITEM
	const [item, setItem] = useState([])
	// EXPEDITION
	const [expedition, setExpedition] = useState(null)
	// ERROR
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('-')
	const [errorType, setErrorType] = useState('error')
	const errorClose = () => {
		setError(false)
	}
	const handleError = (data) => {
		setErrorType(data.type)
		setErrorMsg(data.message)
		setError(data.error)
	}
	// RESET
	const [reset, setReset] = useState(false)
	// DIALOG
	const [dialog, setDialog] = useState(false)
	const [dialogCreate, setDialogCreate] = useState(false)
	const [dialogUpdate, setDialogUpdate] = useState(false)
	const [dialogCancel, setDialogCancel] = useState(false)
	const [option, setOption] = useState(false)
	const [openCreateExpedition, setOpenCreateExpedition] = useState(false)
	// HANDLE SET
	const handleSet = (type, data) => {
		if (type === 'datapl') {
			if (data) {
				setDataPL(data)
			} else {
				setDataPL([])
			}
		}
		if (type === 'pl') {
			setPL(data)
		}
		if (type === 'so') {
			setSO(data)
		}
		if (type === 'alert') {
			handleError(data)
		}
		if (type === 'item') {
			if (data) {
				setItem(data)
			} else {
				setItem([])
			}
		}
		if (type === 'createExpedition') {
			setOpenCreateExpedition(data)
		}
		if (type === 'expedition') {
			setExpedition(data)
		}
		if (type === 'code') {
			setCode(data)
		}
		if (type === 'reset') {
			setReset(data)
		}
		if (type === 'back') {
			setPL(null)
		}
		if (type === 'confirm') {
			setDialog(data)
		}
		if (type === 'create') {
			setDialogCreate(data)
		}
		if (type === 'update') {
			setDialogUpdate(data)
		}
		if (type === 'cancel') {
			setDialogCancel(data)
		}
		if (type === 'option') {
			setOption(data)
		}
	}

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		} else {
			if (soState) {
				handleSet('so', soState)
			}
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{/* TITLE */}
			<Typography paddingY={2} variant='h5'>
				<Stack justifyContent='center' alignItems='center'>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'PICKLIST'}
				</Stack>
			</Typography>
			{/* MENU */}
			<Stack direction='row' alignItems='center'>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 1 ? 'error' : 'inherit'}
					onClick={() => setMenu(1)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'SEARCH'}
				</Button>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 2 ? 'error' : 'inherit'}
					onClick={() => setMenu(2)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'CREATE'}
				</Button>
			</Stack>
			{/* FILTER */}
			<Filter
				set={handleSet}
				data={{ user: user, menu: menu, reset: reset, so: soState }}
			/>
			{/* TABLE */}
			<Tables set={handleSet} data={{ menu: menu, datapl: dataPL, pl: pl }} />
			{/* FORM */}
			<Form
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					so: so,
					reset: reset,
					pl: pl,
				}}
			/>
			{/* ITEM */}
			<Item
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					so: so,
					reset: reset,
					pl: pl,
					expedition: expedition,
				}}
			/>
			{/* SUBMIT */}
			<Submit
				set={handleSet}
				data={{
					user: user,
					menu: menu,
					code: code,
					so: so,
					item: item,
					expedition: expedition,
					reset: reset,
					pl: pl,
					create: dialogCreate,
					update: dialogUpdate,
					cancel: dialogCancel,
					option: option,
				}}
			/>
			{/* ERROR */}
			<Snackbar
				open={error}
				autoHideDuration={5000}
				onClose={errorClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={errorClose} severity={errorType}>
					{errorMsg}
				</Alert>
			</Snackbar>
			{/* CONFIRM */}
			<Dialog
				open={dialog}
				onClose={() => setDialog(false)}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle>CONFIRMATION</DialogTitle>
				<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
					<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color='error' onClick={() => setDialog(false)}>
						NO
					</Button>
					<Button
						variant='contained'
						color='error'
						onClick={() => setOption(true)}
					>
						YES
					</Button>
				</DialogActions>
			</Dialog>
			{/* Create Pick List */}
			<CreateExpedition
				open={openCreateExpedition}
				onClose={() => setOpenCreateExpedition(false)}
			/>
		</>
	)
}
