import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Skeleton,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import url from '../url'
import Item from './item'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'

export default function Form(props) {
	const navigate = useNavigate()
	// DATA PROPS
	const user = props.data.user
	const menu = props.data.menu
	const [so, setSO] = useState(props.data.so)
	// DATE
	const date = new Date()
	const month = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const today = `${date.getDate()} ${
		month[date.getMonth()]
	} ${date.getFullYear()}`
	// CODE
	const [soCode, setSOCode] = useState('')
	const getCode = () => {
		axios
			.get(url + '/sales-order/so-code.php')
			.then(function (response) {
				let result = response.data
				setCode(result)
			})
			.catch(function (error) {})
	}
	const setCode = (code) => {
		let date = new Date()
		let dyear = date.getFullYear()
		let dmonth = date.getMonth() + 1
		let countsplit = code.substring(9, 13)
		let monthsplit = code.substring(7, 9)
		let count = Number(('000' + countsplit).slice(-4))
		let month = Number(('0' + monthsplit).slice(-2))
		if (dmonth === month) {
			count++
			count =
				'SO-' +
				String(dyear) +
				('0' + dmonth).slice(-2) +
				('000' + count).slice(-4)
		} else {
			count =
				'SO-' + String(dyear) + ('0' + dmonth).slice(-2) + '0001'.slice(-4)
		}
		setSOCode(count)
	}
	// ITEM TYPE
	const [dataItmType, setDataItmType] = useState([])
	const [itmType, setItmType] = useState(null)
	const getItmType = () => {
		axios
			.get(url + '/sales-order/item-type.php')
			.then(function (response) {
				let result = response.data
				setDataItmType(result)
			})
			.catch(function (error) {})
	}
	const getItmTypeDetail = (itmtype) => {
		axios
			.get(url + '/sales-order/item-type-detail.php', {
				params: {
					itmtype: itmtype,
				},
			})
			.then(function (response) {
				let result = response.data
				setItmType(result)
			})
			.catch(function (error) {})
	}
	// CUSTOMER
	const [dataCust, setDataCust] = useState([])
	const [customer, setCustomer] = useState(null)
	const getCustomer = () => {
		axios
			.get(url + '/sales-order/customer.php', {
				params: {
					user: user.id,
				},
			})
			.then(function (response) {
				let result = response.data
				setDataCust(result)
			})
			.catch(function (error) {})
	}
	const getCustDetail = (customer) => {
		axios
			.get(url + '/sales-order/customer-detail.php', {
				params: {
					customer: customer,
				},
			})
			.then(function (response) {
				let result = response.data
				setCustomer(result)
			})
			.catch(function (error) {})
	}
	// SALES
	const [dataSales, setDataSales] = useState([])
	const [sales, setSales] = useState(null)
	const getSales = () => {
		axios
			.get(url + '/sales-order/getSales.php', {
				params: {
					user: user.id,
				},
			})
			.then(function (response) {
				let result = response.data
				setDataSales(result)
			})
			.catch(function (error) {})
	}
	// SHIPMENT
	const [shipment, setShipment] = useState('')
	const handleShipment = () => {
		if (customer === null) {
			handleError('error', 'PLEASE SELECT CUSTOMER', true)
		} else {
			setShipment(customer.address)
		}
	}
	// ITEM
	const [item, setItem] = useState([])
	const [checkPicklist, setCheckPicklist] = useState(null)
	const handleItm = (type, item) => {
		if (type === 'item') {
			setItem(item)
		}
		if (type === 'picklist') {
			setCheckPicklist(item)
		}
	}
	// SUCCESS
	const [success, setSuccess] = useState(null)
	// ERROR
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('-')
	const [errorType, setErrorType] = useState('error')
	const errorClose = () => {
		setError(false)
	}
	const handleError = (type, msg, error) => {
		setErrorType(type)
		setErrorMsg(msg)
		setError(error)
	}
	// LOADING
	const [loading, setLoading] = useState(true)
	// CREATE
	const postCreateSO = (form) => {
		axios
			.post(url + '/sales-order/create.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					setSales(null)
					setCustomer(null)
					setShipment('')
					setItem([])
					setItmType(null)
					setSuccess(result)
					getCode()
					handleError('success', result, true)
				} else {
					handleError('error', 'FAILED', true)
				}
			})
			.catch(function (error) {})
	}
	const createSO = () => {
		setDialog(false)
		setDialogCreate(false)
		let count = 0
		for (let i = 0; i < item.length; i++) {
			if (item[i].qty === 0 || item[i].expired === '') {
				count++
			}
		}
		if (count > 0) {
			handleError('error', 'QTY OR EXPIRED MUST BE FILLED', true)
		} else {
			if (
				customer === null ||
				shipment === '' ||
				item.length === 0 ||
				itmType === null ||
				sales === null
			) {
				handleError('error', 'ALL FIELD MUST BE INPUT', true)
			} else {
				let form = new FormData()
				form.append('user', user.id)
				form.append('code', soCode)
				form.append('custid', customer.id)
				form.append('custcode', customer.code)
				form.append('custname', customer.name)
				form.append('salesid', sales.id)
				form.append('sales', sales.name)
				form.append('cityid', customer.city_id)
				form.append('city', customer.city)
				form.append('pricelistid', customer.pricelist_id)
				form.append('pricelist', customer.pricelist)
				form.append('custtypeid', customer.customer_type_id)
				form.append('custtype', customer.customer_type)
				form.append('itmtypeid', itmType.id)
				form.append('itmtype', itmType.name)
				form.append('phone', customer.phone)
				form.append('shipment', shipment)
				form.append('status', 0)
				item.map((row) => {
					form.append('itmid[]', row.id)
					form.append('itmname[]', row.name)
					form.append('itmcat[]', row.category)
					form.append('itmvol[]', row.volume)
					form.append('itmqty[]', row.qty)
					form.append('itmexp[]', row.expired)
					return {}
				})
				postCreateSO(form)
			}
		}
	}
	const backSO = () => {
		props.set('back')
	}
	// CANCEL REASON
	const [dataCancelReason, setDataCancelReason] = useState([])
	const [cancelReason, setCancelReason] = useState(null)
	const getCancelReason = () => {
		axios
			.get(url + '/sales-order/getCancelReason.php')
			.then(function (response) {
				let result = response.data
				setDataCancelReason(result)
			})
			.catch(function (error) {})
	}
	// CANCEL
	const postCancel = (form) => {
		axios
			.post(url + '/sales-order/cancel.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					getSO(so.id)
					handleError('success', result, true)
				} else {
					handleError('error', result, true)
				}
			})
			.catch(function (error) {})
	}
	const handleCancel = () => {
		setDialog(false)
		if (so !== null && cancelReason !== null) {
			let form = new FormData()
			form.append('user', user.id)
			form.append('so', so.id)
			form.append('cancelreasonid', cancelReason.id)
			form.append('cancelreason', cancelReason.name)
			postCancel(form)
		} else {
			handleError('error', 'PLEASE SELECT CANCEL REASON', true)
		}
	}
	function createPicklist() {
		navigate('/picklist', {
			state: {
				user: user,
				so: so,
			},
		})
	}
	// UPDATE
	const handleUpdate = () => {
		setDialog(false)
		setDialogUpdate(false)
		if (so !== null) {
			let count = 0
			for (let i = 0; i < item.length; i++) {
				if (item[i].qty === 0 || item[i].expired === '') {
					count++
				}
			}
			if (count > 0) {
				handleError('error', 'QTY OR EXPIRED MUST BE FILLED', true)
			} else {
				if (
					customer === null ||
					shipment === '' ||
					item.length === 0 ||
					itmType === null ||
					sales === null
				) {
					handleError('error', 'ALL FIELD MUST BE INPUT', true)
				} else {
					let form = new FormData()
					form.append('user', user.id)
					form.append('soid', so.id)
					form.append('code', soCode)
					form.append('custid', customer.id)
					form.append('custcode', customer.code)
					form.append('custname', customer.name)
					form.append('salesid', sales.id)
					form.append('sales', sales.name)
					form.append('cityid', customer.city_id)
					form.append('city', customer.city)
					form.append('pricelistid', customer.pricelist_id)
					form.append('pricelist', customer.pricelist)
					form.append('custtypeid', customer.customer_type_id)
					form.append('custtype', customer.customer_type)
					form.append('phone', customer.phone)
					form.append('shipment', shipment)
					form.append('itmtypeid', itmType.id)
					form.append('itmtype', itmType.name)
					item.map((row) => {
						form.append('itmid[]', row.id)
						form.append('itmname[]', row.name)
						form.append('itmcat[]', row.category)
						form.append('itmvol[]', row.volume)
						form.append('itmqty[]', row.qty)
						form.append('itmexp[]', row.expired)
						return {}
					})
					postUpdate(form)
				}
			}
		}
	}
	const postUpdate = (form) => {
		axios
			.post(url + '/sales-order/update.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					handleError('success', result, true)
				} else {
					handleError('error', result, true)
				}
			})
			.catch(function (error) {})
	}
	// REFRESH
	const getSO = (so) => {
		axios
			.get(url + '/sales-order/so-row.php', { params: { so: so } })
			.then(function (response) {
				let result = response.data
				setSO(result)
			})
			.catch(function (error) {})
	}
	// DIALOG
	const [dialog, setDialog] = useState(false)
	const [dialogCreate, setDialogCreate] = useState(false)
	const [dialogUpdate, setDialogUpdate] = useState(false)
	const handleDialog = (type) => {
		setDialog(true)
		if (type === 'create') {
			setDialogCreate(true)
		}
		if (type === 'update') {
			setDialogUpdate(true)
		}
	}
	// FORM
	const formCreate = [
		{
			id: 1,
			name: 'CODE',
			value: (
				<>
					{menu === 2 ? (
						<Typography textAlign='right'>{soCode}</Typography>
					) : (
						<Stack direction='row' alignItems='center' justifyContent='right'>
							<Typography>{so ? so.code : '-'}</Typography>
							<CopyToClipboard text={so ? so.code : '-'}>
								<IconButton color='error'>
									<ContentCopyIcon />
								</IconButton>
							</CopyToClipboard>
						</Stack>
					)}
				</>
			),
		},
		{
			id: 2,
			name: 'DATE',
			value: (
				<Typography textAlign='right'>
					{menu === 1 ? (so ? so.created_date : '-') : today}
				</Typography>
			),
		},
		{
			id: 3,
			name: 'CUSTOMER',
			value: (
				<Autocomplete
					getOptionLabel={(dataCust) => dataCust.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={customer}
					options={dataCust}
					onChange={(_, value) => setCustomer(value)}
					renderInput={(params) => <TextField {...params} variant='standard' />}
				/>
			),
		},
		{
			id: 4,
			name: 'SALES',
			value: (
				<Autocomplete
					getOptionLabel={(dataSales) => dataSales.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={sales}
					options={dataSales}
					onChange={(_, value) => setSales(value)}
					renderInput={(params) => <TextField {...params} variant='standard' />}
				/>
			),
		},
		{
			id: 5,
			name: 'CITY',
			value: (
				<Typography textAlign='right'>
					{customer ? (customer.city === '' ? '-' : customer.city) : '-'}
				</Typography>
			),
		},
		{
			id: 6,
			name: 'PRICELIST',
			value: (
				<Typography textAlign='right'>
					{customer
						? customer.pricelist === ''
							? '-'
							: customer.pricelist
						: '-'}
				</Typography>
			),
		},
		{
			id: 7,
			name: 'CUSTOMER TYPE',
			value: (
				<Typography textAlign='right'>
					{customer
						? customer.customer_type === ''
							? '-'
							: customer.customer_type
						: '-'}
				</Typography>
			),
		},
		{
			id: 8,
			name: 'PHONE',
			value: (
				<Typography textAlign='right'>
					{customer ? (customer.phone === '' ? '-' : customer.phone) : '-'}
				</Typography>
			),
		},
		{
			id: 9,
			name: 'SHIPMENT',
			value: (
				<Stack direction='row' alignItems='center' spacing={1}>
					<TextField
						variant='standard'
						value={shipment}
						onChange={(value) => setShipment(value.target.value)}
					/>
					<IconButton color='error' onClick={() => handleShipment()}>
						<ContentCopyIcon />
					</IconButton>
				</Stack>
			),
		},
		{
			id: 10,
			name: 'STATUS',
			value: (
				<Typography textAlign='right'>
					{so
						? so.status === '0'
							? 'OPEN'
							: so.status === '1'
							? 'COMPLETE'
							: 'CANCEL'
						: 'OPEN'}
				</Typography>
			),
		},
		{
			id: 11,
			name: 'ITEM TYPE',
			value: (
				<>
					{menu === 2 ? (
						<Autocomplete
							getOptionLabel={(dataItmType) => dataItmType.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={itmType}
							options={dataItmType}
							onChange={(_, value) => setItmType(value)}
							renderInput={(params) => (
								<TextField {...params} variant='standard' />
							)}
						/>
					) : (
						<Typography textAlign='right'>{so ? so.item_type : '-'}</Typography>
					)}
				</>
			),
		},
	]
	const formSearch = [
		{
			id: 1,
			name: 'CODE',
			value: (
				<Stack direction='row' alignItems='center' justifyContent='right'>
					<Typography>{so ? so.code : '-'}</Typography>
					<CopyToClipboard text={so ? so.code : '-'}>
						<IconButton color='error'>
							<ContentCopyIcon />
						</IconButton>
					</CopyToClipboard>
				</Stack>
			),
		},
		{
			id: 2,
			name: 'DATE',
			value: (
				<Typography textAlign='right'>{so ? so.created_date : '-'}</Typography>
			),
		},
		{
			id: 3,
			name: 'CUSTOMER',
			value: (
				<Typography textAlign='right'>{so ? so.customer : '-'}</Typography>
			),
		},
		{
			id: 4,
			name: 'SALES',
			value: (
				<Typography textAlign='right'>
					{so ? (so.sales !== '' ? so.sales : '-') : '-'}
				</Typography>
			),
		},
		{
			id: 5,
			name: 'CITY',
			value: <Typography textAlign='right'>{so ? so.city : '-'}</Typography>,
		},
		{
			id: 6,
			name: 'PRICELIST',
			value: (
				<Typography textAlign='right'>{so ? so.pricelist : '-'}</Typography>
			),
		},
		{
			id: 7,
			name: 'CUSTOMER TYPE',
			value: (
				<Typography textAlign='right'>{so ? so.customer_type : '-'}</Typography>
			),
		},
		{
			id: 8,
			name: 'PHONE',
			value: (
				<Typography textAlign='right'>
					{so ? (so.phone === '' ? '-' : so.phone) : '-'}
				</Typography>
			),
		},
		{
			id: 9,
			name: 'SHIPMENT',
			value: (
				<Typography textAlign='right'>{so ? so.shipment : '-'}</Typography>
			),
		},
		{
			id: 10,
			name: 'STATUS',
			value: (
				<Typography textAlign='right'>
					{so
						? so.status === '0'
							? 'OPEN'
							: so.status === '1'
							? 'COMPLETE'
							: 'CANCEL'
						: '-'}
				</Typography>
			),
		},
		{
			id: 11,
			name: 'ITEM TYPE',
			value: (
				<Typography textAlign='right'>{so ? so.item_type : '-'}</Typography>
			),
		},
	]

	useEffect(() => {
		getCode()
		getCustomer()
		getSales()
		getItmType()
		getCancelReason()
		if (menu === 1 && so) {
			if (so.sales_id !== '0') {
				setSales({ id: so.sales_id, name: so.sales })
			}
			setShipment(so.shipment)
			getCustDetail(so.customer_id)
			getItmTypeDetail(so.item_type_id)
		}
		setTimeout(() => {
			setLoading(false)
		}, 1000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{/* FORM */}
			<Grid container padding={2} alignItems='center' spacing={2}>
				{menu === 1 &&
					checkPicklist === 0 &&
					so.status === '0' &&
					formCreate.map((row) => (
						<Grid item xs={12} md={6} key={row.id}>
							{loading ? (
								<Skeleton animation='wave' />
							) : (
								<Stack direction='row' alignItems='center'>
									<Typography flex={1}>{row.name}</Typography>
									<Typography flex={1} textAlign='center'>
										:
									</Typography>
									<Box flex={1}>{row.value}</Box>
								</Stack>
							)}
						</Grid>
					))}
				{menu === 1 &&
					(so.status !== '0' || checkPicklist !== 0) &&
					formSearch.map((row) => (
						<Grid item xs={12} md={6} key={row.id}>
							{loading ? (
								<Skeleton animation='wave' />
							) : (
								<Stack direction='row' alignItems='center'>
									<Typography flex={1}>{row.name}</Typography>
									<Typography flex={1} textAlign='center'>
										:
									</Typography>
									<Box flex={1}>{row.value}</Box>
								</Stack>
							)}
						</Grid>
					))}
				{menu === 2 &&
					formCreate.map((row) => (
						<Grid item xs={12} md={6} key={row.id}>
							{loading ? (
								<Skeleton animation='wave' />
							) : (
								<Stack direction='row' alignItems='center'>
									<Typography flex={1}>{row.name}</Typography>
									<Typography flex={1} textAlign='center'>
										:
									</Typography>
									<Box flex={1}>{row.value}</Box>
								</Stack>
							)}
						</Grid>
					))}
			</Grid>
			{/* ITEM */}
			<Item
				data={{ type: itmType, success: success, menu: menu, so: so }}
				error={handleError}
				set={handleItm}
			/>
			{/* SUBMIT */}
			<Stack
				padding={2}
				direction='row'
				justifyContent={menu === 1 ? 'space-between' : 'right'}
				alignItems='center'
			>
				{loading ? (
					<Skeleton animation='wave' width={120} height={60} />
				) : (
					<Stack direction='row' spacing={1}>
						<Button
							variant='contained'
							color='error'
							onClick={menu === 1 ? backSO : () => handleDialog('create')}
						>
							{menu === 1 ? 'BACK' : 'CREATE'}
						</Button>
						<Button
							variant='contained'
							color='error'
							onClick={() => createPicklist()}
						>
							CREATE PICKLIST
						</Button>
					</Stack>
				)}
				{menu === 1 && so.status !== '1' && so.status !== '2' && (
					<Stack direction='row' alignItems='center' spacing={1}>
						{loading ? (
							<Skeleton animation='wave' width={120} height={60} />
						) : (
							<Autocomplete
								sx={{ width: 200 }}
								getOptionLabel={(dataCancelReason) => dataCancelReason.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								value={cancelReason}
								options={dataCancelReason}
								onChange={(_, value) => setCancelReason(value)}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder='CANCEL REASON'
										variant='standard'
									/>
								)}
							/>
						)}
						{loading ? (
							<Skeleton animation='wave' width={120} height={60} />
						) : (
							<Button
								variant='contained'
								color='error'
								onClick={() => handleDialog('cancel')}
							>
								CANCEL
							</Button>
						)}
						{checkPicklist === 0 &&
							(loading ? (
								<Skeleton animation='wave' width={120} height={60} />
							) : (
								<Button
									variant='contained'
									color='error'
									onClick={() => handleDialog('update')}
								>
									UPDATE
								</Button>
							))}
					</Stack>
				)}
			</Stack>
			{/* ERROR */}
			<Snackbar
				open={error}
				autoHideDuration={5000}
				onClose={errorClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={errorClose} severity={errorType} sx={{ width: '100%' }}>
					{errorMsg}
				</Alert>
			</Snackbar>
			{/* CONIFRM */}
			<Dialog
				open={dialog}
				onClose={() => setDialog(false)}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle>CONFIRMATION</DialogTitle>
				<DialogContent sx={{ borderTop: 2, borderTopColor: 'red' }}>
					<DialogContentText paddingTop={2}>ARE YOU SURE?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color='error' onClick={() => setDialog(false)}>
						NO
					</Button>
					<Button
						variant='contained'
						color='error'
						onClick={
							dialogCreate
								? createSO
								: dialogUpdate
								? handleUpdate
								: handleCancel
						}
					>
						YES
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
