import {
	Alert,
	Button,
	Checkbox,
	Grid,
	Paper,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import url from '../url'
import { useLocation, useNavigate } from 'react-router-dom'

const Layout = () => {
	const location = useLocation()
	const user = location.state?.user
	const navigate = useNavigate()

	const [error, setError] = useState(false)
	const [errMsg, setErrmsg] = useState('-')
	const [errType, setErrtype] = useState('error')

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [dataBonusCat, setDataBonusCat] = useState([])
	const [tempBonusCat, setTempBonusCat] = useState([])
	const [bonusCat, setBonusCat] = useState('')

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		} else {
			getBonusCat()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getBonusCat = () => {
		axios
			.get(url + '/bonus-category/getBonusCat.php')
			.then(function (response) {
				let result = response.data
				if (result.length > 0) {
					result = result.map((row) => {
						return {
							id: row.id,
							name: row.name,
							status: row.status,
							update: false,
						}
					})
				}
				setDataBonusCat(result)
				setTempBonusCat(result)
			})
			.catch(function (error) {
				setErrtype('error')
				setErrmsg(error.message)
				setError(true)
			})
	}

	const postAddBonusCat = (form) => {
		axios
			.post(url + '/bonus-category/addBonusCat.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					setErrtype('success')
					setErrmsg(result)
					setError(true)
					getBonusCat()
					setBonusCat('')
				} else {
					setErrtype('error')
					setErrmsg(result)
					setError(true)
				}
			})
			.catch(function (error) {
				setErrtype('error')
				setErrmsg(error.message)
				setError(true)
			})
	}

	const postUpdateBonusCat = (form) => {
		axios
			.post(url + '/bonus-category/updateBonusCat.php', form, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(function (response) {
				let result = response.data
				if (result === 'SUCCESS') {
					setErrtype('success')
					setErrmsg(result)
					setError(true)
					getBonusCat()
				} else {
					setErrtype('error')
					setErrmsg(result)
					setError(true)
				}
			})
			.catch(function (error) {
				setErrtype('error')
				setErrmsg(error.message)
				setError(true)
			})
	}

	const cols = [
		{ id: 'name', label: 'BONUS CATEGORY', width: 200 },
		{ id: 'status', label: 'STATUS', width: 200, align: 'center' },
		{ id: 'edit', label: 'EDIT', width: 200, align: 'center' },
	]

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const updateButton = (bonusId) => {
		let temp = dataBonusCat.map((row) => {
			if (row.id === bonusId) {
				if (!row.update) {
					return {
						id: row.id,
						name: row.name,
						status: row.status,
						update: true,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						status: row.status,
						update: false,
					}
				}
			} else {
				return {
					id: row.id,
					name: row.name,
					status: row.status,
					update: row.update,
				}
			}
		})
		setDataBonusCat(temp)
	}

	const updateStatus = (bonusId) => {
		let temp = dataBonusCat.map((row) => {
			if (row.id === bonusId) {
				if (row.status === '0') {
					return {
						id: row.id,
						name: row.name,
						status: '1',
						update: row.update,
					}
				} else {
					return {
						id: row.id,
						name: row.name,
						status: '0',
						update: row.update,
					}
				}
			} else {
				return {
					id: row.id,
					name: row.name,
					status: row.status,
					update: row.update,
				}
			}
		})
		setDataBonusCat(temp)
	}

	const updateName = (bonusId, bonusCat) => {
		let temp = dataBonusCat.map((row) => {
			if (row.id === bonusId) {
				return {
					id: row.id,
					name: bonusCat,
					status: row.status,
					update: row.update,
				}
			} else {
				return {
					id: row.id,
					name: row.name,
					status: row.status,
					update: row.update,
				}
			}
		})
		setDataBonusCat(temp)
	}

	const addBonusCat = () => {
		if (bonusCat === '') {
			setErrtype('error')
			setErrmsg('PLEASE INPUT BONUS CATEGORY')
			setError(true)
		} else {
			let count = 0
			for (let i = 0; i < dataBonusCat.length; i++) {
				if (dataBonusCat[i].name === bonusCat.toUpperCase()) {
					count++
				}
			}
			if (count >= 1) {
				setErrtype('error')
				setErrmsg('BONUS CATEGORY ALREADY IN DATABASE')
				setError(true)
			} else {
				let form = new FormData()
				form.append('user', user.id)
				form.append('bonus', bonusCat)
				postAddBonusCat(form)
			}
		}
	}

	const updateBonusCat = (bonusId, bonusName, bonusStatus) => {
		let count = 0
		for (let i = 0; i < tempBonusCat.length; i++) {
			if (
				tempBonusCat[i].name === bonusName.toUpperCase() &&
				tempBonusCat[i].id !== bonusId
			) {
				count++
			}
		}
		if (count >= 1) {
			setErrtype('error')
			setErrmsg('BONUS CATEGORY ALREADY IN DATABASE')
			setError(true)
		} else {
			let form = new FormData()
			form.append('user', user.id)
			form.append('bonusid', bonusId)
			form.append('bonusname', bonusName)
			form.append('bonusstatus', bonusStatus)
			postUpdateBonusCat(form)
			updateButton(bonusId)
		}
	}

	const errorClose = () => {
		setError(false)
	}

	return (
		<Grid container paddingX={2}>
			<Grid item xs={12} marginY={2}>
				<Stack justifyContent='center' alignItems='center'>
					<Typography variant='h5'>BONUS CATEGORY :</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Stack justifyContent='center' alignItems='center'>
					<Paper
						sx={{
							width: '100%',
							overflow: 'hidden',
						}}
					>
						<TableContainer sx={{ height: 400, backgroundColor: 'whitesmoke' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										{cols.map((col) => (
											<TableCell
												key={col.id}
												sx={{ width: '100%' }}
												align={col.align}
											>
												{col.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{dataBonusCat.length > 0 &&
										dataBonusCat
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((row) => (
												<TableRow
													key={row.id}
													hover
													sx={{ backgroundColor: 'white' }}
												>
													<TableCell>
														{row.update ? (
															<TextField
																variant='standard'
																value={row.name}
																onChange={(e) =>
																	updateName(row.id, e.target.value)
																}
															/>
														) : (
															row.name
														)}
													</TableCell>
													<TableCell>
														<Checkbox
															disabled={row.update ? false : true}
															checked={row.status === '1' ? true : false}
															onChange={() => updateStatus(row.id)}
														/>
													</TableCell>
													<TableCell>
														<Button
															variant='contained'
															onClick={
																row.update
																	? () =>
																			updateBonusCat(
																				row.id,
																				row.name,
																				row.status
																			)
																	: () => updateButton(row.id)
															}
															color='error'
														>
															{row.update ? 'SET' : 'UPDATE'}
														</Button>
													</TableCell>
												</TableRow>
											))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component='div'
							count={dataBonusCat.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</Stack>
			</Grid>
			<Grid item xs={12} paddingY={2}>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					spacing={1}
				>
					<Typography>CATEGORY NAME :</Typography>
					<TextField
						variant='standard'
						value={bonusCat}
						onChange={(e) => setBonusCat(e.target.value)}
					/>
					<Button variant='contained' color='error' onClick={addBonusCat}>
						ADD
					</Button>
				</Stack>
			</Grid>
			<Snackbar
				open={error}
				autoHideDuration={5000}
				onClose={errorClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={errorClose} severity={errType} sx={{ width: '100%' }}>
					{errMsg}
				</Alert>
			</Snackbar>
		</Grid>
	)
}

export default Layout
