import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";

export default function Form(props) {
  // MENU
  const menu = props.data.menu;
  // DELIVERY
  const delivery = props.data.do;
  // INVOICE
  const invoice = props.data.invoice;
  // RESET
  const reset = props.data.reset;
  // DATE
  const date = new Date();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const today = `${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;
  // CODE
  const [code, setCode] = useState(null);
  const getCode = (type) => {
    axios
      .get(url + "/invoice/code.php")
      .then(function (response) {
        let result = response.data;
        handleCode(result, type);
      })
      .catch(function (error) {});
  };
  const handleCode = (code, type) => {
    let date = new Date();
    let dyear = date.getFullYear();
    let dmonth = date.getMonth() + 1;
    let countsplit = code.substring(9, 13);
    let monthsplit = code.substring(7, 9);
    let count = Number(("000" + countsplit).slice(-4));
    let month = Number(("0" + monthsplit).slice(-2));
    if (dmonth === month) {
      count++;
      if (type.value === 0) {
        count =
          "MFI-" +
          String(dyear) +
          ("0" + dmonth).slice(-2) +
          ("000" + count).slice(-4);
      } else {
        count =
          "MSG-" +
          String(dyear) +
          ("0" + dmonth).slice(-2) +
          ("000" + count).slice(-4);
      }
    } else {
      if (type.value === 0) {
        count =
          "MFI-" + String(dyear) + ("0" + dmonth).slice(-2) + "0001".slice(-4);
      } else {
        count =
          "MSG-" + String(dyear) + ("0" + dmonth).slice(-2) + "0001".slice(-4);
      }
    }
    setCode(count);
    props.set("code", count);
  };
  // LOADING
  const [loading, setLoading] = useState(true);
  // INVOICE TYPE
  const [type, setType] = useState(null);
  const dataType = [
    { id: 1, name: "NON-PPN", value: 0 },
    { id: 2, name: "PPN", value: 1 },
  ];
  const handleType = (data) => {
    getCode(data);
    setType(data);
    props.set("type", data);
  };
  // EXPEDITION
  const [expedition, setExpedition] = useState(null);
  const getExpedition = (expeditionid) => {
    axios
      .get(url + "/delivery-order/getExpedition.php", {
        params: { expeditionid: expeditionid },
      })
      .then(function (response) {
        let result = response.data;
        setExpedition(result);
        props.set("expedition", result);
      })
      .catch(function (error) {});
  };
  // FORM
  const formCreate = [
    {
      id: 1,
      name: "CODE",
      value: (
        <Typography textAlign="right">
          {menu === 1 ? (invoice ? invoice.code : "-") : code ? code : "-"}
        </Typography>
      ),
    },
    {
      id: 2,
      name: "DATE",
      value: (
        <Typography textAlign="right">
          {menu === 1 ? (invoice ? invoice.created_date : "-") : today}
        </Typography>
      ),
    },
    {
      id: 3,
      name: "CUSTOMER",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.customer
              : "-"
            : delivery
            ? delivery.customer
              ? delivery.customer
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 4,
      name: "CITY",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.city
              : "-"
            : delivery
            ? delivery.city
              ? delivery.city
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 5,
      name: "PRICELIST",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.pricelist
              : "-"
            : delivery
            ? delivery.pricelist
              ? delivery.pricelist
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 6,
      name: "CUSTOMER TYPE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.customer_type
              : "-"
            : delivery
            ? delivery.customer_type
              ? delivery.customer_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 7,
      name: "PHONE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.phone
                ? invoice.phone
                : "-"
              : "-"
            : delivery
            ? delivery.phone
              ? delivery.phone
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 8,
      name: "SHIPMENT",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.shipment
              : "-"
            : delivery
            ? delivery.shipment
              ? delivery.shipment
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 9,
      name: "STATUS",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.status === "0"
                ? "OPEN"
                : invoice.status === "1"
                ? "COMPLETE"
                : "CANCEL"
              : "-"
            : "OPEN"}
        </Typography>
      ),
    },
    {
      id: 10,
      name: "ITEM TYPE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.item_type
              : "-"
            : delivery
            ? delivery.item_type
              ? delivery.item_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 11,
      name: "EXPEDITION CODE",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.code ? expedition.code : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 12,
      name: "EXPEDITION TYPE",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.expedition_type
              ? expedition.expedition_type
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 13,
      name: "EXPEDITION VOLUME",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.expedition_type_volume
              ? `${Number(expedition.expedition_type_volume).toFixed(3)} m³`
              : "- m³"
            : "- m³"}
        </Typography>
      ),
    },
    {
      id: 14,
      name: "EXPEDITION",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.name ? expedition.name : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 15,
      name: "PLATE NUMBER",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.plate_number
              ? expedition.plate_number
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 16,
      name: "DRIVER",
      value: (
        <Typography textAlign="right">
          {expedition ? (expedition.driver ? expedition.driver : "-") : "-"}
        </Typography>
      ),
    },
    {
      id: 17,
      name: "DRIVER PHONE",
      value: (
        <Typography textAlign="right">
          {expedition
            ? expedition.driver_phone
              ? expedition.driver_phone
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 18,
      name: "DO CODE",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.delivery_order_code
              : "-"
            : delivery
            ? delivery.code
              ? delivery.code
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 19,
      name: "SALES",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? invoice
              ? invoice.sales
              : "-"
            : delivery
            ? delivery.sales
              ? delivery.sales
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 20,
      name: "TYPE",
      value:
        menu === 1 ? (
          <Typography textAlign="right">
            {invoice ? (invoice.type === "0" ? "NON-PPN" : "PPN") : "-"}
          </Typography>
        ) : (
          <Autocomplete
            getOptionLabel={(dataType) => dataType.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={type}
            options={dataType}
            onChange={(_, value) => handleType(value)}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (menu === 2) {
      props.set("invoice", null);
      if (reset) {
        setExpedition(null);
        handleType(null);
      } else {
        if (delivery) {
          getExpedition(delivery.expedition_id);
        } else {
          setExpedition(null);
        }
      }
    } else {
      handleType(null);
      if (invoice) {
        getExpedition(invoice.expedition_id);
      } else {
        setExpedition(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, delivery, invoice, reset]);

  return (
    (menu === 2 || invoice) && (
      <>
        {/* FORM */}
        <Grid container padding={2} alignItems="center" spacing={2}>
          {formCreate.map((row) => (
            <Grid item xs={12} md={6} key={row.id}>
              {loading ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack direction="row" alignItems="center">
                  <Typography flex={1}>{row.name}</Typography>
                  <Typography flex={1} textAlign="center">
                    :
                  </Typography>
                  <Box flex={1}>{row.value}</Box>
                </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
}
