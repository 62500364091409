import { Button, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Filter from './filter'
import TableData from './table'
import Form from './form'

export default function SO() {
	// NAVIGATION
	const location = useLocation()
	const navigate = useNavigate()
	const user = location.state?.user
	// MENU
	const [menu, setMenu] = useState(2)
	const handleMenu = (menu) => {
		if (menu === 2) {
			setDataSO([])
		} else {
			setRefresh(true)
		}
		setSO(null)
		setMenu(menu)
	}
	// LOADING
	const [loading, setLoading] = useState(true)
	// SO
	const [dataSO, setDataSO] = useState([])
	const [so, setSO] = useState(null)
	// HANDLE SO
	const handleSO = (type, data) => {
		if (type === 'filter') {
			setDataSO(data)
		}
		if (type === 'data') {
			setSO(data)
		}
		if (type === 'back') {
			setSO(null)
			setRefresh(true)
		}
		if (type === 'refresh') {
			setRefresh(false)
		}
	}
	// REFRESH
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		} else {
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{/* TITLE */}
			<Typography paddingY={2} variant='h5'>
				<Stack justifyContent='center' alignItems='center'>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'SALES ORDER'}
				</Stack>
			</Typography>
			{/* MENU */}
			<Stack direction='row' alignItems='center'>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 1 ? 'error' : 'inherit'}
					onClick={() => handleMenu(1)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'SEARCH'}
				</Button>
				<Button
					fullWidth
					sx={{ borderBottom: 2, borderRadius: 0 }}
					color={loading ? 'inherit' : menu === 2 ? 'error' : 'inherit'}
					onClick={() => handleMenu(2)}
				>
					{loading ? <Skeleton animation='wave' width='20%' /> : 'CREATE'}
				</Button>
			</Stack>
			{/* FILTER */}
			{menu === 1 && (
				<Filter
					set={handleSO}
					data={{ user: user, menu: menu, refresh: refresh }}
				/>
			)}
			{/* TABLE */}
			{so === null && menu === 1 && (
				<TableData set={handleSO} data={{ table: dataSO, menu: menu }} />
			)}
			{/* FORM */}
			{so !== null && (
				<Form set={handleSO} data={{ user: user, menu: menu, so: so }} />
			)}
			{menu === 2 && <Form set={handleSO} data={{ user: user, menu: menu }} />}
		</>
	)
}
