import { Box, Chip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddItem from './addItem'
import SearchItem from './searchItem'
import LogItem from './logItem'

export default function Layout() {
	const location = useLocation()
	const user = location.state?.user
	const navigate = useNavigate()
	const [menu, setMenu] = useState('searchItem')
	const [searchItem, setSearchItem] = useState({ name: '', status: false })
	const [dataItem, setDataItem] = useState([])
	const [formItem, setFormItem] = useState({
		code: '',
		name: '',
		type: null,
		category: null,
		length: 0,
		width: 0,
		height: 0,
		volume: 0,
		amount: 0,
		sheetPiece: 0,
		piecePack: 0,
		packBox: 0,
		sheetBox: 0,
		status: null,
	})
	const [logItem, setLogItem] = useState(null)

	function onSetHandling(type, data) {
		if (type === 'searchItem') {
			if (data.type === 'Name') {
				setSearchItem({ ...searchItem, name: data.value })
			} else {
				setSearchItem({ ...searchItem, status: data.value })
			}
		}

		if (type === 'dataItem') {
			setDataItem(data)
		}

		if (type === 'logItem') {
			setMenu('logItem')
			setLogItem(data)
		}

		if (type === 'formItem') {
			if (data.type === 'Code') {
				setFormItem({ ...formItem, code: data.value })
			}
			if (data.type === 'Name') {
				setFormItem({ ...formItem, name: data.value })
			}
			if (data.type === 'Type') {
				setFormItem({ ...formItem, type: data.value })
			}
			if (data.type === 'Category') {
				setFormItem({ ...formItem, category: data.value })
			}
			if (data.type === 'Length') {
				setFormItem({ ...formItem, length: data.value })
			}
			if (data.type === 'Width') {
				setFormItem({ ...formItem, width: data.value })
			}
			if (data.type === 'Height') {
				setFormItem({ ...formItem, height: data.value })
			}
			if (data.type === 'Volume') {
				setFormItem({ ...formItem, volume: data.value })
			}
			if (data.type === 'Amount') {
				setFormItem({ ...formItem, amount: data.value })
			}
			if (data.type === 'SheetPiece') {
				setFormItem({ ...formItem, sheetPiece: data.value })
			}
			if (data.type === 'PiecePack') {
				setFormItem({ ...formItem, piecePack: data.value })
			}
			if (data.type === 'PackBox') {
				setFormItem({ ...formItem, packBox: data.value })
			}
			if (data.type === 'SheetBox') {
				setFormItem({ ...formItem, sheetBox: data.value })
			}
			if (data.type === 'Status') {
				setFormItem({ ...formItem, status: data.value })
			}
		}
	}

	useEffect(() => {
		if (user === undefined) {
			navigate('/')
		}

		// eslint-disable-next-line
	}, [])

	return (
		<Box sx={{ margin: 2 }}>
			<Box display={'flex'} flexDirection={'row'} gap={2}>
				{/* //*Search Item */}
				<Chip
					label={menu === 'logItem' ? 'Back' : 'Search Item'}
					variant='outlined'
					icon={menu === 'logItem' ? <ArrowBackIcon /> : <SearchIcon />}
					onClick={() => setMenu('searchItem')}
				/>

				{/* //*Add Item */}
				{menu !== 'logItem' && (
					<Chip
						label='Add Item'
						variant='outlined'
						icon={<AddIcon />}
						onClick={() => {
							setMenu('addItem')
						}}
					/>
				)}
			</Box>

			<Typography
				marginTop={2}
				textAlign={'center'}
				fontWeight={'bold'}
				variant='h4'
			>
				{menu === 'addItem'
					? 'Create Item'
					: menu === 'searchItem'
					? 'Search Item'
					: 'History Update Item'}
			</Typography>

			{menu === 'searchItem' && (
				<SearchItem
					onSetHandling={onSetHandling}
					data={{ user: user, searchItem: searchItem, dataItem: dataItem }}
				/>
			)}

			{menu === 'addItem' && (
				<AddItem
					onSetHandling={onSetHandling}
					data={{ user: user, formItem: formItem }}
				/>
			)}

			{menu === 'logItem' && <LogItem data={{ logItem: logItem }} />}
		</Box>
	)
}
