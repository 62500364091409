import Login from './login/login'
import Home from './home/home'
import SalesOrder from './sales-order/layout'
import DeliveryOrder from './delivery-order/layout'
import ReportOS from './report/os'
import Customer from './customer/customer'
import Error from './error/error'
import BonusCategory from './bonus-category/layout'
import Picklist from './picklist/layout'
import Navbar from './navbar/navbar'
import Invoice from './invoice/layout'
import Expedition from './expedition/layout'
import { Route, Routes, useLocation } from 'react-router-dom'
import Production from './production/index'
import Item from './item/layout'
import SalesActivity from './report/salesActivity'

function App() {
	// Route
	const location = useLocation()
	const user = location?.state?.user
	// Render
	return (
		<>
			{user && <Navbar data={{ user: user }} />}
			<Routes>
				<Route exact path='/' element={<Login />} />
				<Route path='home' element={<Home />} />
				<Route path='sales-order' element={<SalesOrder />} />
				<Route path='delivery-order' element={<DeliveryOrder />} />
				<Route path='invoice' element={<Invoice />} />
				<Route path='report-os' element={<ReportOS />} />
				<Route path='customer' element={<Customer />} />
				<Route path='bonus-category' element={<BonusCategory />} />
				<Route path='item' element={<Item />} />
				<Route path='picklist' element={<Picklist />} />
				<Route path='expedition' element={<Expedition />} />
				<Route path='production' element={<Production />} />
				<Route path='report-sales-activity' element={<SalesActivity />} />
				<Route path='*' element={<Error />} />
			</Routes>
		</>
	)
}

export default App
