import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import url from "../url";
import { useEffect, useState } from "react";

export default function Submit(props) {
  // MENU
  const menu = props.data.menu;
  // USER
  const user = props.data.user;
  // ITEM
  const item = props.data.item;
  // CODE
  const code = props.data.code;
  // PL
  const pl = props.data.pl;
  // DELIVERY
  const delivery = props.data.do;
  // CONFIRM
  const dialogCreate = props.data.create;
  const dialogCancel = props.data.cancel;
  const dialogOption = props.data.option;
  const handleConfirm = (type) => {
    props.set("confirm", true);
    if (type === "create") {
      props.set("create", true);
    }
    if (type === "cancel") {
      props.set("cancel", true);
    }
  };
  // EXPEDITION
  const expedition = props.data.expedition;
  // REASON
  const [dataReason, setDataReason] = useState([]);
  const [reason, setReason] = useState(null);
  const getReason = () => {
    axios
      .get(url + "/delivery-order/getReason.php")
      .then(function (response) {
        let result = response.data;
        setDataReason(result);
      })
      .catch(function (error) {});
  };
  // CREATE
  const postCreate = (form) => {
    axios
      .post(url + "/delivery-order/create.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          props.set("reset", true);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const create = () => {
    props.set("confirm", false);
    props.set("create", false);
    props.set("option", false);
    if (expedition && expedition.status === "0") {
      let form = new FormData();
      form.append("user", user.id);
      form.append("code", code);
      form.append("plid", pl.id);
      form.append("plcode", pl.code);
      form.append("custid", pl.customer_id);
      form.append("custcode", pl.customer_code);
      form.append("customer", pl.customer);
      form.append("cityid", pl.city_id);
      form.append("city", pl.city);
      form.append("pricelistid", pl.pricelist_id);
      form.append("pricelist", pl.pricelist);
      form.append("custtypeid", pl.customer_type_id);
      form.append("custtype", pl.customer_type);
      form.append("itmtypeid", pl.item_type_id);
      form.append("itmtype", pl.item_type);
      form.append("phone", pl.phone);
      form.append("shipment", pl.shipment);
      form.append("expid", pl.expedition_id);
      form.append("expcode", pl.expedition_code);
      form.append("salesid", pl.sales_id);
      form.append("sales", pl.sales);
      item.map((row) => {
        form.append("itmid[]", row.id);
        form.append("itmname[]", row.name);
        form.append("itmcat[]", row.category);
        form.append("itmvol[]", row.volume);
        form.append("itmqty[]", row.qty);
        return {};
      });
      postCreate(form);
    } else {
      props.set("alert", {
        type: "error",
        message: "EXPEDITION CANCELED",
        error: true,
      });
    }
  };
  // CANCEL
  const postCancel = (form) => {
    axios
      .post(url + "/delivery-order/cancel.php", form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        let result = response.data;
        if (result === "SUCCESS") {
          props.set("alert", {
            type: "success",
            message: "SUCCESS",
            error: true,
          });
          setReason(null);
          props.set("reset", true);
          props.set("do", null);
        } else {
          props.set("alert", {
            type: "error",
            message: "FAILED",
            error: true,
          });
        }
      })
      .catch(function (error) {});
  };
  const cancel = () => {
    props.set("confirm", false);
    props.set("cancel", false);
    props.set("option", false);
    let form = new FormData();
    form.append("user", user.id);
    form.append("doid", delivery.id);
    form.append("reasonid", reason.id);
    form.append("reason", reason.name);
    postCancel(form);
  };
  // GO BACK
  const backSearch = () => {
    props.set("back");
    props.set("reset", true);
  };

  useEffect(() => {
    if (menu === 1) {
      getReason();
      if (dialogOption && dialogCancel) {
        cancel();
      }
    } else {
      setReason(null);
      if (dialogOption && dialogCreate) {
        create();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, pl, delivery, dialogOption]);

  return (
    (menu === 2 || delivery) && (
      <>
        <Stack
          direction="row"
          padding={2}
          spacing={1}
          justifyContent={menu === 2 ? "right" : "space-between"}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="error"
            disabled={
              menu === 1 ? false : pl && item && item.length > 0 ? false : true
            }
            onClick={() =>
              menu === 1 ? backSearch() : handleConfirm("create")
            }
          >
            {menu === 1 ? "BACK" : "CREATE"}
          </Button>
          {menu === 1 && delivery.status === "0" && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Autocomplete
                sx={{ width: 200 }}
                getOptionLabel={(dataReason) => dataReason.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={reason}
                options={dataReason}
                onChange={(_, value) => setReason(value)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              <Button
                variant="contained"
                color="error"
                disabled={reason ? false : true}
                onClick={() => handleConfirm("cancel")}
              >
                CANCEL
              </Button>
            </Stack>
          )}
        </Stack>
      </>
    )
  );
}
