import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Typography,
	circularProgressClasses,
} from '@mui/material'
import { BarChart } from '@mui/x-charts'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import PropType from 'prop-types'
import { useEffect, useState } from 'react'
import { productionData } from './production-data'

function CircularProgressCharts(props) {
	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#fff5f5',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 2,
			}}
		>
			<Typography
				variant='h4'
				textAlign={'center'}
				fontWeight={'bold'}
				marginBottom={2}
			>
				{props?.type}
			</Typography>
			<Box sx={{ position: 'relative' }}>
				<CircularProgress
					variant='determinate'
					value={100}
					size={250}
					sx={{ color: '#d4d4d4' }}
				/>
				<CircularProgress
					variant='determinate'
					{...props}
					size={250}
					color={
						props?.value < 33
							? 'error'
							: props?.value < 66
							? 'warning'
							: 'success'
					}
					sx={{
						position: 'absolute',
						left: 0,
						[`& .${circularProgressClasses.circle}`]: {
							strokeLinecap: 'round',
						},
					}}
				/>
				<Box
					sx={{
						position: 'absolute',
						top: 66,
						left: 36,
						width: 178,
						textAlign: 'center',
					}}
				>
					<Typography
						variant='caption'
						fontSize={70}
						color={
							props?.value < 33 ? 'red' : props?.value < 66 ? 'orange' : 'green'
						}
					>
						{`${Math.round(props?.value)}%`}
					</Typography>
				</Box>
			</Box>
		</Paper>
	)
}

CircularProgressCharts.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropType.number.isRequired,
}

function BarCharts(props) {
	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#fff5f5',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 2,
			}}
		>
			<Typography variant='h4' fontWeight={'bold'}>
				{props?.type}
			</Typography>
			<BarChart
				width={600}
				height={300}
				series={[{ data: [100, props?.value] }]}
				layout='horizontal'
				yAxis={[
					{
						data: ['Target MH', 'Actual MH'],
						scaleType: 'band',
						categoryGapRatio: 0.2,
						barGapRatio: 0.2,
					},
				]}
				colors={['#757cff']}
			/>
		</Paper>
	)
}

function Filter(props) {
	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#fff5f5',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant='caption' fontWeight={'bold'}>
						Start date
					</Typography>
					<DateCalendar
						defaultValue={props?.value?.startDate}
						onChange={(value) => props?.onChange('startDate', value)}
					/>
				</Box>
				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant='caption' fontWeight={'bold'}>
						End date
					</Typography>
					<DateCalendar
						defaultValue={props?.value?.endDate}
						onChange={(value) => props?.onChange('endDate', value)}
					/>
				</Box>
			</LocalizationProvider>
		</Paper>
	)
}

export default function Dashboard() {
	const [progressPerformance, setProgressPerformance] = useState(0)
	const [progressQuality, setProgressQuality] = useState(0)

	const date = new Date()
	const year = date?.getFullYear()
	const month = date?.getMonth() + 1
	const day = date?.getDate()
	const today = year + '-' + month + '-' + day

	const [startDate, setStartDate] = useState(dayjs(today))
	const [endDate, setEndDate] = useState(dayjs(today))

	async function getProduction() {
		const result = await productionData(
			startDate?.format('YYYY-MM-DD'),
			endDate?.format('YYYY-MM-DD')
		)
		setProgressPerformance(result.mh)
		setProgressQuality(result.output_box)
	}

	function changeDate(type, value) {
		if (type === 'startDate') {
			setStartDate(value)
			getProduction()
		} else {
			setEndDate(value)
			getProduction()
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			getProduction()
			// setProgressPerformance(Math.floor(Math.random() * 100))
			// setProgressQuality(Math.floor(Math.random() * 100))
		}, 3000)
		return () => clearInterval(interval)
	})

	return (
		<Grid container padding={5} gap={2} justifyContent={'center'}>
			<Box display={'flex'} flexDirection={'column'} gap={2}>
				<Typography variant='h4' fontWeight={'bold'}>
					Dashboard
				</Typography>
				<Filter
					value={{ startDate: startDate, endDate: endDate }}
					onChange={changeDate}
				/>
			</Box>
			<CircularProgressCharts
				type={'Performance'}
				value={progressPerformance}
			/>
			<CircularProgressCharts type={'Quality'} value={progressQuality} />
			<BarCharts type={'Performance'} value={progressPerformance} />
			<BarCharts type={'Quality'} value={progressQuality} />
		</Grid>
	)
}
