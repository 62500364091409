import {
    Autocomplete,
    Button,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Item(props) {
    // PROPS
    const itmType = props.data.type;
    const success = props.data.success;
    const menu = props.data.menu;
    const so = props.data.so;
    const [itmTempType, setItmTempType] = useState(null);
    // LOADING
    const [loading, setLoading] = useState(true);
    // ITEM
    const [dataItm, setDataItm] = useState([]);
    const getItem = (itmtype) => {
        axios
            .get(url + "/sales-order/item.php", {
                params: {
                    itmtype: itmtype,
                },
            })
            .then(function (response) {
                let result = response.data;
                setDataItm(result);
            })
            .catch(function (error) {});
    };
    // ITEM CART
    const [selectItm, setSelectItm] = useState([]);
    const [item, setItem] = useState(null);
    const addItm = () => {
        if (item !== null) {
            let count = 0;
            for (let i = 0; i < selectItm.length; i++) {
                if (item.id === selectItm[i].id) {
                    count++;
                }
            }
            if (count >= 1) {
                props.error("error", "ITEM ALREADY ON THE LIST", true);
            } else {
                setSelectItm([
                    ...selectItm,
                    {
                        id: item.id,
                        category: item.item_category,
                        name: item.name,
                        volume: item.volume,
                        qty: 0,
                    },
                ]);
                props.set("item", [
                    ...selectItm,
                    {
                        id: item.id,
                        category: item.item_category,
                        name: item.name,
                        volume: item.volume,
                        qty: 0,
                    },
                ]);
            }
        } else {
            props.error("error", "PLEASE SELECT ITEM", true);
        }
    };
    const removeItm = (id) => {
        setSelectItm(selectItm.filter((a) => a.id !== id));
        itmSum(selectItm.filter((a) => a.id !== id));
        props.set(
            "item",
            selectItm.filter((a) => a.id !== id)
        );
    };
    // ITEM TOTAL
    const [itmTotal, setItmTotal] = useState(null);
    const itmSum = (item) => {
        let volume = 0;
        let qty = 0;
        if (item.length > 0) {
            for (let i = 0; i < item.length; i++) {
                volume = volume + Number(item[i].volume * item[i].qty);
                qty = qty + Number(item[i].qty);
            }
            setItmTotal({ volume: volume, qty: qty });
        } else {
            setItmTotal(null);
        }
    };
    // ITEM QTY
    const handleInputQty = (id, qty) => {
        let format = "0123456789";
        let number = "";
        for (let i = 0; i < qty.length; i++) {
            if (format.indexOf(qty[i]) > -1) {
                number = number + qty[i];
            } else {
                props.error("error", "PLEASE INPUT ONLY NUMBER", true);
            }
        }
        let sum = selectItm.map((row) => {
            if (row.id === id) {
                return {
                    id: row.id,
                    category: row.category,
                    name: row.name,
                    volume: row.volume,
                    qty: Number(number),
                };
            } else {
                return {
                    id: row.id,
                    category: row.category,
                    name: row.name,
                    volume: row.volume,
                    qty: row.qty,
                };
            }
        });
        setSelectItm(sum);
        itmSum(sum);
        props.set("item", sum);
    };
    const handleMinQty = (id) => {
        let sum = selectItm.map((row) => {
            if (row.id === id) {
                if (row.qty > 0) {
                    return {
                        id: row.id,
                        category: row.category,
                        name: row.name,
                        volume: row.volume,
                        qty: Number(row.qty - 1),
                    };
                } else {
                    return {
                        id: row.id,
                        category: row.category,
                        name: row.name,
                        volume: row.volume,
                        qty: row.qty,
                    };
                }
            } else {
                return {
                    id: row.id,
                    category: row.category,
                    name: row.name,
                    volume: row.volume,
                    qty: row.qty,
                };
            }
        });
        setSelectItm(sum);
        itmSum(sum);
        props.set("item", sum);
    };
    const handleMaxQty = (id) => {
        let sum = selectItm.map((row) => {
            if (row.id === id) {
                return {
                    id: row.id,
                    category: row.category,
                    name: row.name,
                    volume: row.volume,
                    qty: Number(row.qty + 1),
                };
            } else {
                return {
                    id: row.id,
                    category: row.category,
                    name: row.name,
                    volume: row.volume,
                    qty: row.qty,
                };
            }
        });
        setSelectItm(sum);
        itmSum(sum);
        props.set("item", sum);
    };
    // SO DETAIL
    const [soDetail, setSODetail] = useState(null);
    const getSODetail = (so) => {
        axios
            .get(url + "/sales-order/so-detail.php", {
                params: {
                    so: so,
                },
            })
            .then(function (response) {
                let result = response.data;
                result = result.map((row) => {
                    return {
                        id: row.item_id,
                        category: row.item_category,
                        name: row.item,
                        volume: row.item_volume,
                        qty: Number(row.qty),
                    };
                });
                setSelectItm(result);
                itmSum(result);
                props.set("item", result);
                setSODetail(1);
            })
            .catch(function (error) {});
    };
    // CHECK PICKLIST
    const [picklist, setPicklist] = useState([]);
    const checkPicklist = (so) => {
        axios
            .get(url + "/sales-order/check-picklist.php", {
                params: {
                    so: so,
                },
            })
            .then(function (response) {
                let result = response.data;
                props.set("picklist", result.length);
                setPicklist(result);
            })
            .catch(function (error) {});
    };
    // CHANGE ITEM TYPE
    const changeItmType = (itmtype) => {
        if (itmType !== null) {
            if (itmtype !== itmType.id && selectItm.length > 0) {
                setDataItm([]);
                props.error(
                    "error",
                    "PLEASE REMOVE ALL ITEM OR DON'T CHANGE ITEM TYPE",
                    true
                );
            } else {
                setItmTempType(itmType);
                getItem(itmType.id);
            }
        } else {
            setDataItm([]);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        if (success !== null) {
            setSelectItm([]);
        }
        if (menu === 1) {
            if (so !== null) {
                if (soDetail === null) {
                    setTimeout(() => {
                        getSODetail(so.id);
                    }, 1000);
                    checkPicklist(so.id);
                }
                changeItmType(so.item_type_id);
            }
        } else {
            if (itmTempType !== null) {
                changeItmType(itmTempType.id);
            } else {
                changeItmType(null);
            }
        }
        setItem(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itmType, success, so]);

    return (
        <Stack padding={2} spacing={2}>
            {/* LIST ITEM */}
            {selectItm.length > 0 &&
                selectItm.map((row) => (
                    <Paper key={row.id} sx={{ padding: 1 }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                        >
                            <Typography flex={3} variant="body2">
                                {loading ? (
                                    <Skeleton animation="wave" width="50%" />
                                ) : (
                                    row.category
                                )}
                            </Typography>
                            <Typography
                                flex={0.5}
                                textAlign="center"
                                variant="body2"
                            >
                                {loading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    "VOLUME"
                                )}
                            </Typography>
                            <Typography
                                flex={0.5}
                                textAlign="center"
                                variant="body2"
                            >
                                {loading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    "QTY"
                                )}
                            </Typography>
                            {(menu === 2 ||
                                (menu === 1 &&
                                    picklist.length === 0 &&
                                    so.status === "0")) && (
                                <Typography
                                    flex={0.5}
                                    textAlign="center"
                                    variant="body2"
                                >
                                    {loading ? (
                                        <Skeleton animation="wave" />
                                    ) : (
                                        "DELETE"
                                    )}
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography flex={3}>
                                {loading ? (
                                    <Skeleton animation="wave" width="50%" />
                                ) : (
                                    row.name
                                )}
                            </Typography>
                            <Typography flex={0.5} textAlign="center">
                                {loading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    `${Number(row.volume * row.qty).toFixed(
                                        3
                                    )} m³`
                                )}
                            </Typography>
                            {(menu === 1 &&
                                picklist.length === 0 &&
                                so.status === "0") ||
                            menu === 2 ? (
                                <Stack
                                    flex={0.5}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {loading ? (
                                        <Skeleton
                                            animation="wave"
                                            variant="circular"
                                            width={25}
                                            height={25}
                                        />
                                    ) : (
                                        <IconButton
                                            onClick={() => handleMinQty(row.id)}
                                        >
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    )}
                                    {loading ? (
                                        <Skeleton
                                            animation="wave"
                                            width="30%"
                                        />
                                    ) : (
                                        <TextField
                                            sx={{ textAlignLast: "center" }}
                                            variant="standard"
                                            value={row.qty}
                                            onChange={(e) =>
                                                handleInputQty(
                                                    row.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )}
                                    {loading ? (
                                        <Skeleton
                                            animation="wave"
                                            variant="circular"
                                            width={25}
                                            height={25}
                                        />
                                    ) : (
                                        <IconButton
                                            onClick={() => handleMaxQty(row.id)}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            ) : (
                                <Typography flex={0.5} textAlign="center">
                                    {loading ? (
                                        <Skeleton animation="wave" />
                                    ) : (
                                        row.qty
                                    )}
                                </Typography>
                            )}
                            {(menu === 2 ||
                                (menu === 1 &&
                                    picklist.length === 0 &&
                                    so.status === "0")) && (
                                <Stack
                                    flex={0.5}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {loading ? (
                                        <Skeleton
                                            animation="wave"
                                            variant="circular"
                                            width={25}
                                            height={25}
                                        />
                                    ) : (
                                        <IconButton
                                            onClick={() => removeItm(row.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Paper>
                ))}
            {/* TOTAL */}
            {selectItm.length > 0 && (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    padding={1}
                    borderTop={1}
                >
                    <Typography flex={3} fontWeight="bold">
                        TOTAL
                    </Typography>
                    <Typography flex={0.5} textAlign="center" fontWeight="bold">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : itmTotal ? (
                            `${itmTotal.volume.toFixed(3)} m³`
                        ) : (
                            "- m³"
                        )}
                    </Typography>
                    <Typography flex={0.5} textAlign="center" fontWeight="bold">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : itmTotal ? (
                            itmTotal.qty
                        ) : (
                            "-"
                        )}
                    </Typography>
                    {((menu === 1 &&
                        picklist.length === 0 &&
                        so.status === "0") ||
                        menu === 2) && <Typography flex={0.5}></Typography>}
                </Stack>
            )}
            {/* ADD ITEM */}
            {(menu === 2 ||
                (menu === 1 && picklist.length === 0 && so.status === "0")) && (
                <Paper sx={{ padding: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {loading ? (
                            <Skeleton sx={{ flex: 1 }} animation="wave" />
                        ) : (
                            <Autocomplete
                                sx={{ flex: 1 }}
                                getOptionLabel={(dataItm) =>
                                    `${dataItm.item_category} - ${dataItm.name}`
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={item}
                                options={dataItm}
                                onChange={(_, value) => setItem(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="PRODUCT ITEM"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                        {loading ? (
                            <Skeleton
                                sx={{ flex: 1 }}
                                animation="wave"
                                height={60}
                            />
                        ) : (
                            <Button
                                sx={{ flex: 1 }}
                                variant="contained"
                                color="error"
                                onClick={addItm}
                            >
                                ADD ITEM
                            </Button>
                        )}
                    </Stack>
                </Paper>
            )}
        </Stack>
    );
}
