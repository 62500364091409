import { Button, Form, FloatingLabel, Image } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from '../logo1.png'
import axios from 'axios'
import url from '../url'
import './style.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const App = () => {
	const [valid, setValid] = useState(false)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [error, setError] = useState('')

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const login = (e) => {
		const form = e.currentTarget
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
			setValid(true)
		} else {
			setLoading(true)
			e.preventDefault()
			setTimeout(() => {
				axios
					.get(url + '/login.php', {
						params: {
							username: username,
							password: password,
						},
					})
					.then(function (response) {
						let result = response.data
						if (result.id) {
							navigate('/home', { state: { user: result } })
						} else {
							setError(result)
						}
						setLoading(false)
					})
					.catch(function (error) {
						setError(error.message)
						setLoading(false)
					})
			}, 1000)
		}
	}

	return (
		<div className='d-flex justify-content-center align-items-center container'>
			<div className='contain-inner'>
				<div className='mb-3'>
					<Image src={logo} className='img' />
					{/* <Typography variant="h1" textAlign="center" color="red">JM</Typography> */}
				</div>
				<Form noValidate validated={valid} onSubmit={login}>
					<FloatingLabel
						controlId='floatingInput'
						label='Username'
						className='mb-3'
					>
						<Form.Control
							placeholder=' '
							value={username}
							onChange={(e) => (setUsername(e.target.value), setError(''))}
							required
						/>
						<Form.Control.Feedback type='invalid'>
							Please insert a username.
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel
						controlId='floatingPassword'
						label='Password'
						className='mb-3'
					>
						<Form.Control
							type='password'
							placeholder=' '
							value={password}
							onChange={(e) => (setPassword(e.target.value), setError(''))}
							required
						/>
						<Form.Control.Feedback type='invalid'>
							Please insert a password.
						</Form.Control.Feedback>
					</FloatingLabel>
					<span className='d-flex justify-content-center text-danger mb-3'>
						{error}
					</span>
					<div className='d-grid'>
						{loading ? (
							<Button variant='danger' type='submit' disabled>
								<span
									className='spinner-border spinner-border-sm'
									role='status'
									aria-hidden='true'
								></span>
							</Button>
						) : (
							<Button variant='danger' type='submit'>
								Log In
							</Button>
						)}
					</div>
				</Form>
			</div>
		</div>
	)
}

export default App
