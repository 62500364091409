import {
	Autocomplete,
	Box,
	Grid,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect, useState } from 'react'
import axios from 'axios'
import url from '../url'
import dayjs from 'dayjs'

export default function Filter(props) {
	// USER
	const user = props.data.user
	// MENU
	const menu = props.data.menu
	// RESET
	const reset = props.data.reset
	// LOADING
	const [loading, setLoading] = useState(true)
	// CODE
	const [code, setCode] = useState('')
	const handleCode = (code) => {
		setCode(code)
		search(code, customerName, status.value, dateFrom, dateTo, user.id)
	}
	// CUSTOMER
	const [customer, setCustomer] = useState(null)
	const [customerName, setCustomerName] = useState('')
	const [dataCustomer, setDataCustomer] = useState([])
	const getCustomer = () => {
		axios
			.get(url + '/picklist/getCustomer.php', {
				params: {
					user: user.id,
				},
			})
			.then(function (response) {
				let result = response.data
				setDataCustomer(result)
			})
			.catch(function (error) {})
	}
	const handleCustomer = (customer) => {
		setCustomer(customer)
		if (customer) {
			setCustomerName(customer.name)
			search(code, customer.name, status.value, dateFrom, dateTo, user.id)
		} else {
			setCustomerName('')
			search(code, '', status.value, dateFrom, dateTo, user.id)
		}
	}
	// STATUS
	const dataStatus = [
		{ id: 1, name: 'OPEN', value: '0' },
		{ id: 2, name: 'COMPLETE', value: '1' },
		{ id: 3, name: 'CANCEL', value: '2' },
	]
	const [status, setStatus] = useState({ id: 1, name: 'OPEN', value: '0' })
	const handleStatus = (status) => {
		if (status) {
			setStatus(status)
			search(code, customerName, status.value, dateFrom, dateTo, user.id)
		}
	}
	// DATE
	const date = new Date()
	const year = String(date.getFullYear())
	const month = date.getMonth() + 1
	const day = date.getDate()
	const today = year + '-' + month + '-' + day
	const [dateFrom, setDateFrom] = useState('')
	const [dateTo, setDateTo] = useState(dayjs(today))
	const handleDate = (type, date) => {
		let result
		if (date) {
			result = date.format('YYYY-MM-DD')
		} else {
			result = ''
		}
		if (type === 'datefrom') {
			setDateFrom(result)
			search(code, customerName, status.value, result, dateTo, user.id)
		}
		if (type === 'dateto') {
			setDateTo(result)
			search(code, customerName, status.value, dateFrom, result, user.id)
		}
	}
	// SEARCH
	const search = (code, customer, status, datefrom, dateto, user) => {
		axios
			.get(url + '/picklist/search.php', {
				params: {
					code: code,
					customer: customer,
					status: status,
					datefrom: datefrom,
					dateto: dateto,
					user: user,
				},
			})
			.then(function (response) {
				let result = response.data
				props.set('datapl', result)
				props.set('reset', false)
			})
			.catch(function (error) {})
	}
	// SO
	const [dataSO, setDataSO] = useState([])
	const [so, setSO] = useState(null)
	const getSO = () => {
		axios
			.get(url + '/picklist/getSO.php', {
				params: {
					user: user.id,
				},
			})
			.then(function (response) {
				let result = response.data
				if (result) {
					setDataSO(result)
				} else {
					setDataSO([])
				}
			})
			.catch(function (error) {})
	}
	const selectSO = (so) => {
		setSO(so)
		props.set('so', so)
	}
	// FILTER
	const filterSearch = [
		{
			id: 1,
			name: 'CODE',
			value: (
				<TextField
					fullWidth
					variant='standard'
					value={code}
					onChange={(e) => handleCode(e.target.value)}
				/>
			),
		},
		{
			id: 2,
			name: 'CUSTOMER',
			value: (
				<Autocomplete
					getOptionLabel={(dataCustomer) => dataCustomer.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={customer}
					options={dataCustomer}
					onChange={(_, value) => handleCustomer(value)}
					renderInput={(params) => <TextField {...params} variant='standard' />}
				/>
			),
		},
		{
			id: 3,
			name: 'STATUS',
			value: (
				<Autocomplete
					getOptionLabel={(dataStatus) => dataStatus.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={status}
					options={dataStatus}
					onChange={(_, value) => handleStatus(value)}
					renderInput={(params) => <TextField {...params} variant='standard' />}
				/>
			),
		},
		{
			id: 4,
			name: 'DATE FROM',
			value: (
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={dateFrom}
						onChange={(value) => handleDate('datefrom', value)}
					/>
				</LocalizationProvider>
			),
		},
		{
			id: 5,
			name: 'DATE TO',
			value: (
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={dateTo}
						onChange={(value) => handleDate('dateto', value)}
					/>
				</LocalizationProvider>
			),
		},
	]
	const filterCreate = [
		{
			id: 1,
			name: 'SO CODE',
			value: (
				<Autocomplete
					getOptionLabel={(dataSO) => dataSO.code}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={so}
					options={dataSO}
					onChange={(_, value) => selectSO(value)}
					renderInput={(params) => <TextField {...params} variant='standard' />}
				/>
			),
		},
	]

	useEffect(() => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 1000)
		if (menu === 1) {
			selectSO(null)
			getCustomer()
			setTimeout(() => {
				search(code, customerName, status.value, dateFrom, dateTo, user.id)
			}, 1000)
			if (reset) {
				search(code, customerName, status.value, dateFrom, dateTo, user.id)
			}
		} else {
			if (props.data.so) {
				setSO(props.data.so)
			}
			getSO()
			setCode('')
			setCustomer(null)
			setCustomerName('')
			setStatus({ id: 1, name: 'OPEN', value: '0' })
			setDateFrom('')
			setDateTo(dayjs(today))
			props.set('datapl')
			if (reset) {
				getSO()
				selectSO(null)
				props.set('reset', false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu, reset])

	return (
		<Grid
			container
			padding={2}
			spacing={2}
			alignItems='center'
			borderBottom={2}
		>
			{menu === 1 &&
				filterSearch.map((row) => (
					<Grid item xs={12} md={6} key={row.id}>
						{loading ? (
							<Skeleton animation='wave' />
						) : (
							<Stack direction='row' alignItems='center'>
								<Typography flex={1}>{row.name}</Typography>
								<Typography flex={1} textAlign='center'>
									:
								</Typography>
								<Box flex={1}>{row.value}</Box>
							</Stack>
						)}
					</Grid>
				))}
			{menu === 2 &&
				filterCreate.map((row) => (
					<Grid item xs={12} md={12} key={row.id}>
						{loading ? (
							<Skeleton animation='wave' />
						) : (
							<Stack direction='row' alignItems='center'>
								<Typography flex={1}>{row.name}</Typography>
								<Typography flex={1} textAlign='center'>
									:
								</Typography>
								<Box flex={1}>{row.value}</Box>
							</Stack>
						)}
					</Grid>
				))}
		</Grid>
	)
}
