import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../url";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function Form(props) {
  // MENU
  const menu = props.data.menu;
  // EXPEDITION
  const expedition = props.data.expedition;
  // RESET
  const reset = props.data.reset;
  // DATE
  const date = new Date();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const today = `${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;
  // CODE
  const [code, setCode] = useState(null);
  const getCode = () => {
    axios
      .get(url + "/expedition/getCode.php")
      .then(function (response) {
        let result = response.data;
        handleCode(result);
      })
      .catch(function (error) {});
  };
  const handleCode = (code) => {
    let date = new Date();
    let dyear = date.getFullYear();
    let dmonth = date.getMonth() + 1;
    let countsplit = code.substring(9, 13);
    let monthsplit = code.substring(7, 9);
    let count = Number(("000" + countsplit).slice(-4));
    let month = Number(("0" + monthsplit).slice(-2));
    if (dmonth === month) {
      count++;
      count =
        "EX-" +
        String(dyear) +
        ("0" + dmonth).slice(-2) +
        ("000" + count).slice(-4);
    } else {
      count =
        "EX-" + String(dyear) + ("0" + dmonth).slice(-2) + "0001".slice(-4);
    }
    setCode(count);
    props.set("code", count);
  };
  // LOADING
  const [loading, setLoading] = useState(true);
  // PICKLIST
  const [picklist, setPicklist] = useState([]);
  const getPicklist = (expeditionid) => {
    axios
      .get(url + "/expedition/getPicklist.php", {
        params: { id: expeditionid },
      })
      .then(function (response) {
        let result = response.data;
        setPicklist(result);
        props.set("picklist", result);
      })
      .catch(function (error) {});
  };
  // EXPEDITION
  const [expeditionTypes, setExpeditionTypes] = useState([]);
  const [expeditionType, setExpeditionType] = useState(null);
  const [name, setName] = useState(null);
  const [plateNumber, setPlateNumber] = useState(null);
  const [driver, setDriver] = useState(null);
  const [driverPhone, setDriverPhone] = useState(null);
  const getExpeditionTypes = () => {
    axios
      .get(url + "/expedition/getExpeditionTypes.php")
      .then(function (response) {
        let result = response.data;
        setExpeditionTypes(result);
      })
      .catch(function (error) {});
  };
  const getExpeditionType = (id, name, platenumber, driver, driverphone) => {
    axios
      .get(url + "/expedition/getExpeditionType.php", {
        params: { id: id },
      })
      .then(function (response) {
        let result = response.data;
        setExpeditionType(result);
        setName(name);
        setPlateNumber(platenumber);
        setDriver(driver);
        setDriverPhone(driverphone);
        props.set("expeditiontype", {
          type: result,
          name: name,
          platenumber: platenumber,
          driver: driver,
          driverphone: driverphone,
        });
      })
      .catch(function (error) {});
  };
  const handleExpedition = (type, data) => {
    if (type === "expeditiontype") {
      setExpeditionType(data);
      props.set("expeditiontype", {
        type: data,
        name: name,
        platenumber: plateNumber,
        driver: driver,
        driverphone: driverPhone,
      });
    }
    if (type === "name") {
      let temp;
      if (data === "") {
        temp = null;
      } else {
        temp = data;
      }
      setName(temp);
      props.set("expeditiontype", {
        type: expeditionType,
        name: temp,
        platenumber: plateNumber,
        driver: driver,
        driverphone: driverPhone,
      });
    }
    if (type === "platenumber") {
      let temp;
      if (data === "") {
        temp = null;
      } else {
        temp = data;
      }
      setPlateNumber(temp);
      props.set("expeditiontype", {
        type: expeditionType,
        name: name,
        platenumber: temp,
        driver: driver,
        driverphone: driverPhone,
      });
    }
    if (type === "driver") {
      let temp;
      if (data === "") {
        temp = null;
      } else {
        temp = data;
      }
      setDriver(temp);
      props.set("expeditiontype", {
        type: expeditionType,
        name: name,
        platenumber: plateNumber,
        driver: temp,
        driverphone: driverPhone,
      });
    }
    if (type === "driverphone") {
      let format = "0123456789";
      let number = "";
      for (let i = 0; i < data.length; i++) {
        if (format.indexOf(data[i]) > -1) {
          number = number + data[i];
        } else {
          props.set("alert", {
            type: "error",
            message: "PLEASE INPUT ONLY NUMBER",
            error: true,
          });
        }
      }
      let temp;
      if (data === "") {
        temp = null;
      } else {
        temp = number;
      }
      setDriverPhone(temp);
      props.set("expeditiontype", {
        type: expeditionType,
        name: name,
        platenumber: plateNumber,
        driver: driver,
        driverphone: temp,
      });
    }
  };
  // RESET FORM
  const resetForm = () => {
    setExpeditionType(null);
    setName(null);
    setPlateNumber(null);
    setDriver(null);
    setDriverPhone(null);
    setPicklist([]);
    props.set("expeditiontype", {
      type: null,
      name: null,
      platenumber: null,
      driver: null,
      driverphone: null,
    });
    props.set("picklist");
  };
  // FORM
  const formCreate = [
    {
      id: 1,
      name: "CODE",
      value:
        menu === 1 ? (
          <Stack direction="row" alignItems="center" justifyContent="right">
            <Typography>{expedition ? expedition.code : "-"}</Typography>
            <CopyToClipboard text={expedition ? expedition.code : "-"}>
              <IconButton color="error">
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        ) : (
          <Typography textAlign="right">{code}</Typography>
        ),
    },
    {
      id: 2,
      name: "DATE",
      value: (
        <Typography textAlign="right">
          {menu === 1 ? (expedition ? expedition.created_date : "-") : today}
        </Typography>
      ),
    },
    {
      id: 3,
      name: "TYPE",
      value:
        expedition && (expedition.status !== "0" || picklist.length > 0) ? (
          <Typography textAlign="right">
            {expedition.expedition_type}
          </Typography>
        ) : (
          <Autocomplete
            getOptionLabel={(expeditionTypes) =>
              `${expeditionTypes.name} (${Number(
                expeditionTypes.volume_tolerance
              ).toFixed(3)} m³)`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={expeditionType}
            options={expeditionTypes}
            onChange={(_, value) => handleExpedition("expeditiontype", value)}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ),
    },
    {
      id: 4,
      name: "VOLUME",
      value: (
        <Typography textAlign="right">
          {expeditionType
            ? expeditionType.volume_tolerance
              ? `${Number(expeditionType.volume_tolerance).toFixed(3)} m³`
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      id: 5,
      name: "EXPEDITION",
      value:
        expedition && (expedition.status !== "0" || picklist.length > 0) ? (
          <Typography textAlign="right">{expedition.name}</Typography>
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={name ? name : ""}
            onChange={(e) => handleExpedition("name", e.target.value)}
          />
        ),
    },
    {
      id: 6,
      name: "PLATE NUMBER",
      value:
        expedition && (expedition.status !== "0" || picklist.length > 0) ? (
          <Typography textAlign="right">{expedition.plate_number}</Typography>
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={plateNumber ? plateNumber : ""}
            onChange={(e) => handleExpedition("platenumber", e.target.value)}
          />
        ),
    },
    {
      id: 7,
      name: "DRIVER",
      value:
        expedition && (expedition.status !== "0" || picklist.length > 0) ? (
          <Typography textAlign="right">{expedition.driver}</Typography>
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={driver ? driver : ""}
            onChange={(e) => handleExpedition("driver", e.target.value)}
          />
        ),
    },
    {
      id: 8,
      name: "DRIVER PHONE",
      value:
        expedition && (expedition.status !== "0" || picklist.length > 0) ? (
          <Typography textAlign="right">{expedition.driver_phone}</Typography>
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={driverPhone ? driverPhone : ""}
            onChange={(e) => handleExpedition("driverphone", e.target.value)}
          />
        ),
    },
    {
      id: 9,
      name: "STATUS",
      value: (
        <Typography textAlign="right">
          {menu === 1
            ? expedition
              ? expedition.status === "0"
                ? "OPEN"
                : expedition.status === "1"
                ? "COMPLETE"
                : "CANCEL"
              : "-"
            : "OPEN"}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (menu === 1) {
      if (expedition) {
        getExpeditionType(
          expedition.expedition_type_id,
          expedition.name,
          expedition.plate_number,
          expedition.driver,
          expedition.driver_phone
        );
        getPicklist(expedition.id);
      } else {
        resetForm();
      }
    } else {
      resetForm();
      getCode();
      getExpeditionTypes();
      props.set("expedition", null);
      if (reset) {
        resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, reset, expedition]);

  return (
    (menu === 2 || expedition) && (
      <>
        {/* FORM */}
        <Grid container padding={2} alignItems="center" spacing={2}>
          {formCreate.map((row) => (
            <Grid item xs={12} md={6} key={row.id}>
              {loading ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack direction="row" alignItems="center">
                  <Typography flex={1}>{row.name}</Typography>
                  <Typography flex={1} textAlign="center">
                    :
                  </Typography>
                  <Box flex={1}>{row.value}</Box>
                </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
}
